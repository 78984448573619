import { useEffect, useState } from 'react';

export const useWidgetSocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [widgetsToUpdate, setWidgetsToUpdate] = useState<string[]>([]);

  useEffect(() => {
    const socket = new WebSocket(import.meta.env.V_WS_ENDPOINT);

    socket.addEventListener('open', (event) => {
      setSocket(socket);
    });

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (error) {
          console.log(error);
        }

        if (data && data.eventName === 'updateWidget') {
          setWidgetsToUpdate(data.body.widgets);
        }
      });
    }
  }, [socket]);

  return { widgetsToUpdate, setWidgetsToUpdate };
};
