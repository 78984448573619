import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EditIcon from '@mui/icons-material/Edit';

import { CustomField } from '~/components/molecules/CustomField';
import { updateUserAttributes, userDataSelector } from '~/store/common';
import { useAppDispatch } from '~/store';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { AvatarWithCircle } from '~/components/molecules/AvatarWithCircle';
import { ColorPickerDialog } from '~/components/organisms/ColorPickerDialog';
import { EmailConfirmationDialog } from '~/components/organisms/EmailConfirmationDialog';

import { useStyles } from './styles';
import { TUserForm } from './types';

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  oldPassword: Yup.string().min(8, 'Password must be at least 8 characters'),
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .test('match', 'Old password is required', function (value) {
      return value ? !!this.parent.oldPassword : true;
    }),
  avatar: Yup.mixed()
    .notRequired()
    .test('fileSize', 'File size should be less than 1 MB', function (value) {
      if (!value) return true;

      // 4 MB
      return (value as File).size <= 4 * 1024 * 1024;
    }),
});

const avatarSize = 100;

export const Settings = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { data } = useSelector(userDataSelector);

  const [isUpdating, setIsUpdating] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [userColor, setUserColor] = useState(data?.color || '#000000');
  const [openColorPicker, setOpenColorPicker] = useState(false);

  const formik = useFormik<TUserForm>({
    initialValues: {
      firstName: data?.name || '',
      lastName: data?.secondName || '',
      email: data?.email || '',
      oldPassword: '',
      newPassword: '',
      avatar: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsUpdating(true);

      dispatch(
        updateUserAttributes({
          name: values.firstName,
          secondName: values.lastName,
          email: values.email,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
          avatar: values.avatar,
          color: userColor,
        })
      ).then((r) => {
        if (r.type.includes('rejected')) {
          setErrorMsg((r as unknown as { error: { message: string } })?.error?.message || 'Unknown error');
          setIsUpdating(false);
          formik.resetForm({ values });
        } else {
          setIsUpdating(false);
          formik.resetForm({ values: { ...values, oldPassword: '', newPassword: '', avatar: null } });
        }
      });
    },
  });

  return (
    <Box className={classes.settingsRoot}>
      <Box className={classes.rootWrapper}>
        <Typography className={classes.title}>User Profile</Typography>
        <Box component="form" className={classes.fieldsBox} onSubmit={formik.handleSubmit}>
          <Box className={classes.formHeader} sx={{ backgroundColor: userColor }}>
            <Button
              sx={{ position: 'absolute', bottom: 0, right: 24, color: '#fff' }}
              endIcon={<EditIcon />}
              onClick={() => setOpenColorPicker(true)}>
              Edit Color
            </Button>
          </Box>
          <Box
            className={classes.avatarBox}
            sx={{
              width: `${avatarSize + 14}px`,
              height: `${avatarSize + 14}px`,
            }}>
            <AvatarWithCircle color={userColor} src={data?.avatarImg} size={avatarSize} />
          </Box>
          <Box className={classes.updateBtnBox}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button component="label" sx={{ minWidth: '110px' }}>
                Update image
                <input
                  type="file"
                  hidden
                  name="avatar"
                  onChange={(event) => formik.setFieldValue('avatar', event.currentTarget.files?.[0])}
                />
              </Button>
              <Typography>{formik.values.avatar?.name}</Typography>
            </Box>
            <Typography sx={{ marginLeft: '8px' }} color="error">
              {formik.errors.avatar}
            </Typography>
            {formik.values.avatar && (
              <Typography>Picture successfully uploaded please save profile to view change</Typography>
            )}
          </Box>
          <CustomField
            className={classes.input}
            label="First Name"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            autoComplete={'off'}
          />
          <CustomField
            className={classes.input}
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            autoComplete={'off'}
          />
          <CustomField
            className={classes.input}
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            autoComplete={'off'}
          />
          <CustomField
            className={classes.input}
            label="Password"
            name="oldPassword"
            type="password"
            value={formik.values.oldPassword}
            onChange={formik.handleChange}
            error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
            helperText={formik.touched.oldPassword && formik.errors.oldPassword}
            autoComplete={'new-password'}
          />
          <CustomField
            className={classes.input}
            label="New password"
            name="newPassword"
            type="password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            autoComplete={'new-password'}
          />
        </Box>
        <ButtonWithLoading
          loading={isUpdating}
          disabled={!formik.dirty && userColor === data?.color}
          className={classes.saveBtn}
          variant="contained"
          onClick={() => formik.submitForm()}>
          Save changes
        </ButtonWithLoading>
      </Box>
      <ColorPickerDialog
        color={userColor}
        onColorChange={(color) => setUserColor(color)}
        open={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={!!errorMsg}
        autoHideDuration={errorMsg.length * 60 > 1500 ? errorMsg.length * 60 : 1500}
        onClose={() => setErrorMsg('')}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </Box>
  );
};
