import { CSSObject } from 'tss-react';
import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const drawerWidth = 240;
export const drawerWidthCollapsed = 72;

export const useStyles = makeStyles<{ open: boolean; isMobile: boolean }>()((theme, { open, isMobile }) => ({
  root: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
  },
  header: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
    backgroundColor: theme.palette.background.default,
    marginLeft: drawerWidthCollapsed,
    width: `calc(100% - ${isMobile ? 0 : drawerWidthCollapsed}px)`,
    zIndex: isMobile ? 1 : theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open &&
      !isMobile && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'relative',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  },
  main: {
    height: '100%',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    position: 'relative',
    overflow: 'auto',
    paddingTop: '64px',
    background: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${drawerWidthCollapsed}px)`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  },
  logoIcon: {
    marginLeft: '20px',
    marginRight: '24px',
  },
  logoBox: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    width: `${drawerWidth}px`,
    textDecoration: 'none',
  },
  navMenu: {
    overflow: 'hidden',
    marginTop: '100px',
    width: '100%',
    '& a': {
      flex: 1,
      textDecoration: 'none',
    },
  },
  navItemBox: {
    width: `${drawerWidth}px`,
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    boxSizing: 'border-box',
    paddingLeft: '24px',
  },
  navItemBoxActive: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: '20px',
    color: theme.palette.primary.main,
    '& svg,path': {
      fill: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
  },
  navItemBoxActiveWithOutFill: {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    paddingLeft: '20px',
    color: theme.palette.primary.main,
    '& svg,path': {
      stroke: theme.palette.primary.main,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(255, 255, 255, 0.06)',
    },
  },
  navItemIcon: {
    width: '24px',
    height: '24px',
    marginRight: '24px',
  },
  headerActionBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${drawerWidthCollapsed}px`,
});
