import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';

interface ITableWidgetProps {
  value: string | number | null;
  title: string;
  icon: JSX.Element;
  iconBgColor?: string;
}

export const TableWidget: FC<ITableWidgetProps> = (props) => {
  const { value, title, icon, iconBgColor } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        minWidth: '260px',
        borderRadius: '8px',
        boxSizing: 'border-box',
        padding: '24px',
        display: 'flex',
        alignItems: 'center',
      }}>
      {/*Temporary disabled*/}
      {/*<Box*/}
      {/*  sx={{*/}
      {/*    backgroundColor: iconBgColor ? iconBgColor : '#ffd900',*/}
      {/*    borderRadius: '8px',*/}
      {/*    marginRight: '16px',*/}
      {/*    height: '50px',*/}
      {/*    width: '50px',*/}
      {/*    display: 'flex',*/}
      {/*    alignItems: 'center',*/}
      {/*    justifyContent: 'center',*/}
      {/*  }}>*/}
      {/*  {icon}*/}
      {/*</Box>*/}
      <Box>
        <Typography>{title}</Typography>
        <Typography sx={{ fontSize: '24px', fontWeight: 700, lineHeight: '24px' }}>
          {value ? value : '-'}
        </Typography>
      </Box>
    </Box>
  );
};
