import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonIcon from '@mui/icons-material/Person';
import dayjs from 'dayjs';

import { useAppDispatch } from '~/store';
import {
  clearWhiteboard,
  exportWhiteboard,
  filtersSelector,
  getWhiteboard,
  getWhiteboardFilters,
  whiteboardSelector,
} from '~/store/whiteboard';
import { CommonTable, IQueryParams } from '~/components/organisms/CommonTable';
import { TableWidget } from '~/components/organisms/TableWidget';

import { useStyles } from './styles';
import { columns, columnsWithRules } from './columns';

export const Whiteboard = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { data, loading, total } = useSelector(whiteboardSelector);
  const { data: filtersData } = useSelector(filtersSelector);

  const [uniqueCandidates, setUniqueCandidates] = useState<number | null>(null);
  const [uniqueJobs, setUniqueJobs] = useState<number | null>(null);
  const [uniqueCompanies, setUniqueCompanies] = useState<number | null>(null);
  const [pipelineValue, setPipelineValue] = useState<number | null>(null);
  const [wightedValue, setWightedValue] = useState<number | null>(null);
  const [todaysInterviews, setTodaysInterviews] = useState<number | null>(null);

  useEffect(() => {
    if (data) {
      const uniqueCandidates = new Set();
      data.forEach((item) => uniqueCandidates.add(item.candidateName));
      setUniqueCandidates(uniqueCandidates.size);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const uniqueJobs = new Set();
      data.forEach((item) => uniqueJobs.add((item.jobTitle || '') + (item.company?.name || '')));
      setUniqueJobs(uniqueJobs.size);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const uniqueCompanies = new Set();
      data.forEach((item) => uniqueCompanies.add(item.company?.name || ''));
      setUniqueCompanies(uniqueCompanies.size);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const pipelineValue = data.reduce((acc, item) => acc + (item.potential || 0), 0);
      setPipelineValue(pipelineValue);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const weightedValue = data.reduce((acc, item) => {
        if ((item.progress || 0) >= 50) return acc + (item.potential || 0);
        return acc;
      }, 0);
      setWightedValue(weightedValue);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const todaysInterviews = data.reduce((acc, item) => {
        let interviews = acc;

        if (dayjs(item.firstInterviewDate).isSame(dayjs(), 'day')) interviews += 1;
        if (dayjs(item.secondInterviewDate).isSame(dayjs(), 'day')) interviews += 1;
        if (dayjs(item.thirdInterviewDate).isSame(dayjs(), 'day')) interviews += 1;

        return interviews;
      }, 0);
      setTodaysInterviews(todaysInterviews);
    }
  }, [data]);

  const handleFetchData = useCallback(async (queryParams: IQueryParams) => {
    await dispatch(getWhiteboard(queryParams));
  }, []);

  const handleFetchFilters = useCallback(async () => {
    await dispatch(getWhiteboardFilters());
  }, []);

  const handleCleanUp = useCallback(() => {
    dispatch(clearWhiteboard());
  }, []);

  const handleDownloadCsv = useCallback((queryParams: IQueryParams) => {
    return dispatch(exportWhiteboard(queryParams)) as unknown as Promise<void>;
  }, []);

  return (
    <Box className={classes.whiteboardRoot}>
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: '#fff' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography sx={{ margin: '32px 0 8px 32px' }} variant="h4">
            Whiteboard
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableWidget
            title="Candidates"
            value={formatWidgetValue(uniqueCandidates)}
            icon={<PersonIcon fontSize="large" />}
          />
          <TableWidget
            title="Jobs"
            value={formatWidgetValue(uniqueJobs)}
            icon={<PersonIcon fontSize="large" />}
            iconBgColor={'#2d3eff'}
          />
          <TableWidget
            title="Companies"
            value={formatWidgetValue(uniqueCompanies)}
            icon={<PersonIcon fontSize="large" />}
            iconBgColor={'#ff0000'}
          />
          <TableWidget
            title="Pipeline Value"
            value={formatWidgetValue(pipelineValue, true)}
            icon={<PersonIcon fontSize="large" />}
            iconBgColor={'#00ff00'}
          />
          <TableWidget
            title="Weighted Value"
            value={formatWidgetValue(wightedValue, true)}
            icon={<PersonIcon fontSize="large" />}
            iconBgColor={'#ff00ff'}
          />
          <TableWidget
            title="Interviews Today"
            value={formatWidgetValue(todaysInterviews)}
            icon={<PersonIcon fontSize="large" />}
            iconBgColor={'#ff00ff'}
          />
        </AccordionDetails>
      </Accordion>
      <CommonTable
        kind="whiteboard"
        columnsDef={columns}
        data={data}
        loading={loading}
        total={total ? total : 0}
        fetchData={handleFetchData}
        cleanUp={handleCleanUp}
        columnsWithRules={columnsWithRules}
        filtersData={filtersData}
        fetchFiltersData={handleFetchFilters}
        downloadCsv={handleDownloadCsv}
        addNewRecord
      />
    </Box>
  );
};

export const formatWidgetValue = (value: number | null, moneyValue = false) => {
  if (value === null) return value;

  return moneyValue ? `$${value.toLocaleString('en-US')}` : value.toLocaleString('en-US');
};
