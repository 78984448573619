import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { CustomField } from '~/components/molecules/CustomField';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';

interface ICreateViewDialogProps {
  open: boolean;
  closeDialog: () => void;
  createView: (name: string) => void;
}

export const CreateViewDialog: FC<ICreateViewDialogProps> = (props) => {
  const { open, closeDialog, createView } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setName('');
      setLoading(false);
    };
  }, []);

  const handleCreate = async () => {
    setLoading(true);
    await createView(name);
    setLoading(false);
    closeDialog();
  };

  return (
    <Dialog open={open} onClose={closeDialog} sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }}>
      <DialogTitle>Create View</DialogTitle>
      <DialogContent sx={{ width: isMobile ? '70vw' : '350px' }}>
        <DialogContentText sx={{ marginBottom: '25px' }}>Enter view name</DialogContentText>
        <CustomField onChange={(e) => setName(e.target.value)} autoFocus fullWidth variant="standard" />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '24px',
          }}>
          <Button sx={{ marginRight: '4px' }} onClick={() => closeDialog()}>
            Cancel
          </Button>
          <ButtonWithLoading
            disabled={!name || loading}
            loading={loading}
            onClick={handleCreate}
            variant="contained">
            Confirm
          </ButtonWithLoading>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
