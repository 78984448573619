import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API, Storage } from 'aws-amplify';
import { debounce } from 'lodash';

import { apiName, Endpoints } from '~/constants';

import {
  loginActionType,
  updateUserAttributesActionType,
  updateAvatarActionType,
  verifyEmailActionType,
  getUserViewsActionType,
  deleteUserViewActionType,
  createUserViewActionType,
  updateUserViewActionType,
  markViewAsFavoriteActionType,
  getUserInfoActionType,
  newPasswordRequiredActionType,
  updateLastLoginActionType,
} from './actionTypes';
import {
  IConfirmNewPasswordPayload,
  ILoginPayload,
  IUserAttributesPayload,
  IUserData,
  IVerifyEmailPayload,
  IView,
} from './types';
import { RootState, store } from '../store';

export const login = createAsyncThunk(loginActionType, async ({ email, password }: ILoginPayload) => {
  return Auth.signIn(email, password);
});

export const getUserInfo = createAsyncThunk(getUserInfoActionType, async () => {
  const currentUser = await Auth.currentAuthenticatedUser();

  const user = await API.get(apiName, Endpoints.userBySub.replace(':sub', currentUser.attributes.sub), {});

  return {
    id: user.id,
    sub: user.sub,
    email: user.email,
    username: user.username,
    name: user.firstName,
    secondName: user.lastName,
    avatarFileKey: user.avatarFileKey,
    avatarImg: user.avatarImg,
    role: currentUser?.signInUserSession?.accessToken.payload['cognito:groups']?.[0],
    mailingLists: user.mailingLists,
    status: user.status,
    color: user.color,
  };
});

export const newPasswordRequired = createAsyncThunk(
  newPasswordRequiredActionType,
  async ({ email, password, newPassword }: IConfirmNewPasswordPayload) => {
    await Auth.signOut();
    const currentUser = await Auth.signIn(email, password);

    return Auth.completeNewPassword(currentUser, newPassword, {});
  }
);

export const updateUserAttributes = createAsyncThunk<IUserData, IUserAttributesPayload, { state: RootState }>(
  updateUserAttributesActionType,
  async (payload, { getState }) => {
    const user = await Auth.currentAuthenticatedUser();
    const attributes = {
      firstName: payload.name,
      lastName: payload.secondName,
    } as Record<string, string>;
    const resultUserData = {
      name: payload.name,
      secondName: payload.secondName,
    } as IUserData;

    if (payload.oldPassword && payload.newPassword) {
      await Auth.changePassword(user, payload.oldPassword, payload.newPassword);
    }

    if (payload.email) {
      await Auth.updateUserAttributes(user, {
        email: payload.email,
      });

      attributes.email = payload.email;
      resultUserData.email = payload.email;
    }

    if (payload.avatar) {
      const oldAvatarFileKey = getState().common.userData.data?.avatarFileKey || '';

      const file = await updateAvatar(oldAvatarFileKey, payload.avatar, user.attributes.sub);

      await API.patch(apiName, Endpoints.userBySub.replace(':sub', user.attributes.sub), {
        body: {
          avatarFileKey: file.key,
        },
      });

      resultUserData.avatarImg = await Storage.get(file.key);

      attributes.avatarFileKey = file.key;
      resultUserData.avatarFileKey = file.key;
    }

    if (payload.color) {
      attributes.color = payload.color;
      resultUserData.color = payload.color;
    }

    await API.patch(apiName, Endpoints.userBySub.replace(':sub', user.attributes.sub), {
      body: {
        ...attributes,
      },
    });

    return resultUserData;
  }
);

export const updateAvatar = async (oldAvatarFileKey: string | null, avatar: File, userSub: string) => {
  const file = await Storage.put(userSub + '_' + avatar.name, avatar, {
    level: 'public',
  });

  oldAvatarFileKey && (await Storage.remove(oldAvatarFileKey));

  return file;
};

export const verifyEmail = createAsyncThunk<string, IVerifyEmailPayload>(
  verifyEmailActionType,
  async ({ email, code }) => {
    await Auth.verifyCurrentUserAttributeSubmit('email', code);

    return email;
  }
);

export const getUserViews = createAsyncThunk(getUserViewsActionType, async (userId: string) => {
  return API.get(apiName, Endpoints.userViews.replace(':id', userId), {});
});

export const createUserView = createAsyncThunk<
  void,
  Omit<IView, 'id'> & { userId: string },
  { dispatch: typeof store.dispatch }
>(createUserViewActionType, async (view, { dispatch }) => {
  await API.post(apiName, Endpoints.view, {
    body: view,
  });

  dispatch(getUserViews(view.userId));
});

export const deleteUserView = createAsyncThunk<void, string>(deleteUserViewActionType, async (viewId) => {
  await API.del(apiName, Endpoints.view + '/' + viewId, {});
});

export const updateUserView = createAsyncThunk<
  void,
  Partial<IView>,
  { dispatch: typeof store.dispatch; state: RootState }
>(
  updateUserViewActionType,
  debounce(
    async (view, { dispatch, getState }) => {
      await API.patch(apiName, Endpoints.view + '/' + view.id, {
        body: view,
      });

      const { data } = getState().common.userData;
      data && dispatch(getUserViews(data.id));
    },
    350,
    { leading: false, trailing: true }
  )
);

export const markViewAsFavorite = createAsyncThunk<
  void,
  { viewId: string; views: IView[] },
  { dispatch: typeof store.dispatch; state: RootState }
>(markViewAsFavoriteActionType, async (payload, { dispatch, getState }) => {
  const { viewId, views } = payload;
  const view = views.find((view) => view.id === viewId);

  await API.patch(apiName, Endpoints.view + '/' + viewId, {
    body: { isFavorite: !view?.isFavorite },
  });
  if (view && !view.isFavorite) {
    const view = views.find((view) => view.isFavorite);

    if (view) {
      await API.patch(apiName, Endpoints.view + '/' + view?.id, {
        body: { isFavorite: false },
      });
    }
  }
  const { data } = getState().common.userData;
  data && dispatch(getUserViews(data.id));
});

export const updateLastLogin = createAsyncThunk(updateLastLoginActionType, async () => {
  const userSub = (await Auth.currentAuthenticatedUser())?.attributes?.sub;
  return API.patch(apiName, Endpoints.userBySub.replace(':sub', userSub), {
    pathParameters: {
      sub: userSub,
    },
    body: {
      lastLogin: new Date().toISOString(),
    },
  });
});
