import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  childrenBox: {
    width: '100%',
    height: '100%',
    flex: 1,
    overflow: 'auto',
  },
  toolbar: {
    justifyContent: 'space-between',
    borderBottom: '1px solid rgba(255, 255, 255, 0.06)',
    [theme.breakpoints.up('sm')]: {
      padding: '0 110px',
    },
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    '& svg': {
      marginRight: '10px',
    },
    '& p': {
      fontSize: '20px',
    },
  },
  loginBtn: {
    color: '#fff',
    textTransform: 'capitalize',
  },
  footer: {
    justifyContent: 'center',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
  },
}));
