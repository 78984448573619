import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  showInput: {
    width: '250px',
    opacity: 1,
  },
}));
