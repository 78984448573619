import { FC } from 'react';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slider,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { GridColDef } from '@mui/x-data-grid';

import { theme } from '~/theme';
import { TColumnVisibilityModel } from '~/components/organisms/TableToolbar';

import { useStyles } from './styles';

interface IVisibilityDrawerProps {
  columns: GridColDef[];
  columnVisibility: TColumnVisibilityModel;
  onColumnVisibilityChange: (newColumnVisibilityModel: TColumnVisibilityModel) => void;
  onColumnWidthChange?: (field: string, width: number) => void;
  open: boolean;
  onClose: () => void;
}

export const VisibilityDrawer: FC<IVisibilityDrawerProps> = (props) => {
  const { columns, open, onClose, columnVisibility, onColumnVisibilityChange, onColumnWidthChange } = props;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { classes } = useStyles({ open });

  const handleColumnVisibilityChange = (field: string) => {
    onColumnVisibilityChange({ ...columnVisibility, [field]: !columnVisibility[field] });
  };

  return (
    <Drawer variant="permanent" className={classes.drawer} anchor={'right'} open={open} onClose={onClose}>
      <Box className={classes.menuBox}>
        <Typography className={classes.menuTitle}>View Options</Typography>
        <Divider />
        {isMobile ? null : (
          <IconButton className={classes.closeBtn} onClick={onClose}>
            <CloseIcon sx={{ fill: '#BDBDBD' }} />
          </IconButton>
        )}
        <Typography className={classes.menuSubtitle}>Columns</Typography>
        <List>
          {columns.map((column) =>
            dontShowColumns.includes(column.field) ? null : (
              <ListItem sx={{ paddingRight: 0 }} key={column.field}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <DragIndicatorIcon sx={{ fill: '#BDBDBD' }} />
                </ListItemIcon>
                <ListItemText
                  sx={{ '& .MuiListItemText-primary': { marginRight: 0 } }}
                  primary={column.headerName}
                />
                {onColumnWidthChange && (
                  <Box
                    sx={{
                      minWidth: '170px',
                      padding: '0 20px',
                    }}>
                    <Slider
                      min={50}
                      max={400}
                      value={column.width}
                      onChange={(e, value) => onColumnWidthChange(column.field, value as number)}
                    />
                  </Box>
                )}
                <ListItemIcon
                  sx={{ minWidth: 40 }}
                  onClick={() => handleColumnVisibilityChange(column.field)}>
                  {columnVisibility[column.field] ? (
                    <VisibilityOutlinedIcon sx={{ fill: '#BDBDBD' }} />
                  ) : (
                    <VisibilityOffOutlinedIcon sx={{ fill: '#BDBDBD' }} />
                  )}
                </ListItemIcon>
              </ListItem>
            )
          )}
        </List>
      </Box>
    </Drawer>
  );
};

const dontShowColumns = ['bullhornJobOrderId', 'rowNumber', 'edit'];
