import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { saveAs } from 'file-saver';

import { apiName, Endpoints } from '~/constants';
import { TRecordForm } from '~/components/organisms/TableToolbar';
import { interviews } from '~/constants/whiteboard';
import { Dayjs } from 'dayjs';

import {
  addWhiteboardRecordActionType,
  deleteWhiteboardRecordActionType,
  exportWhiteboardActionType,
  getWhiteboardActionType,
  getWhiteboardFiltersActionType,
  updateWhiteboardRecordActionType,
} from './actionTypes';
import { IWhiteboardQueries } from './types';

export const getWhiteboard = createAsyncThunk(
  getWhiteboardActionType,
  async (queries?: IWhiteboardQueries) => {
    for (const key in queries) {
      if (!queries[key]) {
        delete queries[key];
      }
    }

    return API.get(apiName, Endpoints.whiteboard, { queryStringParameters: queries });
  }
);

export const getWhiteboardFilters = createAsyncThunk(getWhiteboardFiltersActionType, async () => {
  const filters = await API.get(apiName, Endpoints.whiteboardFilters, {});
  filters.progress = null;
  filters.potential = null;
  filters.byWhenDate = null;
  filters.id = null;

  return filters;
});

export const addWhiteboardRecord = createAsyncThunk(
  addWhiteboardRecordActionType,
  async (data: TRecordForm) => {
    const body: Record<string, string | number | null | undefined | Dayjs> = {
      ...data,
      potential: data.potential && +data.potential,
      progress: data.progress && +data.progress / 100,
      interviews: undefined,
    };

    data.interviews.forEach((interview, index) => {
      body[interviews[index][0]] = interview.type;
      body[interviews[index][1]] = interview.date;
    });

    return API.post(apiName, Endpoints.whiteboardAddRecord, { body });
  }
);

export const updateWhiteboardRecord = createAsyncThunk(
  updateWhiteboardRecordActionType,
  async (data: TRecordForm & { id: string }) => {
    const body: Record<string, string | number | null | undefined | Dayjs> = {
      ...data,
      potential: data.potential && +data.potential,
      progress: data.progress && +data.progress / 100,
      interviews: undefined,
    };

    interviews.forEach((interview, index) => {
      body[interviews[index][0]] = data.interviews[index]?.type || null;
      body[interviews[index][1]] = data.interviews[index]?.date || null;
    });

    return API.patch(apiName, Endpoints.whiteboard + '/' + data.id, { body });
  }
);

export const deleteWhiteboardRecord = createAsyncThunk(
  deleteWhiteboardRecordActionType,
  async (id: string) => {
    return API.del(apiName, Endpoints.whiteboard + '/' + id, {});
  }
);

export const exportWhiteboard = createAsyncThunk(
  exportWhiteboardActionType,
  async (queries?: IWhiteboardQueries) => {
    for (const key in queries) {
      if (!queries[key]) {
        delete queries[key];
      }
    }

    const response = await API.get(apiName, Endpoints.whiteboardExport, {
      responseType: 'blob',
      queryStringParameters: queries,
    });

    const blob = new Blob([response], { type: 'text/csv' });

    saveAs(blob, 'file.csv');
  }
);
