import { ReactElement } from 'react';
import { Box, CircularProgress, Select, SelectProps, Typography } from '@mui/material';

interface ICustomSelectProps<T> extends SelectProps<T> {
  label?: string;
  required?: boolean;
  helperText?: string | false | undefined;
  className?: string;
  options?: ReactElement[];
  loading?: boolean;
}

export const CustomSelect = function <T>(props: ICustomSelectProps<T>) {
  const { className, helperText, required, label, options, sx, loading, ...rest } = props;

  return (
    <Box className={className}>
      {label && (
        <Typography
          sx={{
            fontSize: '13px',
            lineHeight: '15px',
            paddingBottom: '8px',
            color: helperText ? 'error.main' : 'text.primary',
          }}>
          {label}
          <Typography component="span" sx={{ color: 'red', marginLeft: '2px', fontSize: '12px' }}>
            {required ? '*' : ''}
          </Typography>
        </Typography>
      )}
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <Select
          sx={{
            width: '100%',
            '& .MuiOutlinedInput-input': {
              fontSize: '14px',
              padding: '12px ',
            },
            '& .MuiSvgIcon-root': {
              color: '#fff',
            },
            ...sx,
          }}
          {...rest}>
          {options}
        </Select>
      )}
      {helperText && (
        <Typography sx={{ fontSize: '12px', lineHeight: '20px', margin: ' 3px 14px 0 0' }} color="error">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
