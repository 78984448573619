import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '24px',
    maxWidth: '560px',
  },
  dialog: {
    '& .MuiPaper-root': {
      borderRadius: '12px',
      maxHeight: '600px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    marginBottom: '20px',
  },
  input: {
    marginTop: '16px',
  },
  row: {
    display: 'flex',
    marginTop: '16px',
    gap: '16px',
  },
}));
