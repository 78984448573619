import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, Auth } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';

export const initThunk = createAsyncThunk('init', async () => {
  const currentUser = await Auth.currentAuthenticatedUser({ bypassCache: true });

  const user = await API.get(apiName, Endpoints.userBySub.replace(':sub', currentUser.attributes.sub), {});

  if (user?.status?.name === 'inactive') {
    await Auth.signOut();
    throw new Error('User is inactive');
  }

  return {
    id: user.id,
    sub: user.sub,
    email: user.email,
    username: user.username,
    name: user.firstName,
    secondName: user.lastName,
    avatarFileKey: user.avatarFileKey,
    avatarImg: user.avatarImg,
    role: currentUser?.signInUserSession?.accessToken.payload['cognito:groups']?.[0],
    mailingLists: user.mailingLists,
    status: user.status,
    color: user.color,
  };
});
