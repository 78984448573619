import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';
import { ruleColors } from '~/constants/table';

export const useStyles = makeStyles()((theme: Theme) => ({
  metricsRoot: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tableBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    width: '100%',
    border: 'none',
    position: 'relative',
    '& .MuiDataGrid-sortIcon': {
      fill: '#fff',
    },
    '& .MuiDataGrid-withBorderColor': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '& .MuiDataGrid-columnHeader': {
      borderBottom: '1px solid #8D8D8D',
      borderColor: '#8D8D8D',

      '& .MuiDataGrid-columnHeaderTitle': {
        whiteSpace: 'break-spaces',
        lineHeight: '16px',
        fontWeight: 400,
      },
    },
  },
  totals: {
    backgroundColor: theme.palette.background.paper,
  },
  red: {
    color: ruleColors[0].value,
  },
  green: {
    color: ruleColors[1].value,
  },
  yellow: {
    color: ruleColors[2].value,
  },
}));
