import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { DataGrid, GridCellParams, GridSortDirection } from '@mui/x-data-grid';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';

import { useAppDispatch } from '~/store';
import {
  metricSelector,
  getMetrics,
  IMetricQueries,
  getMetricFilters,
  metricFiltersSelector,
  clearMetrics,
  IMetricFilters,
  IMetric,
} from '~/store/metric';
import {
  DateRange,
  TableToolbar,
  TColumnVisibilityModel,
  TFilter,
  TRule,
} from '~/components/organisms/TableToolbar';
import {
  transformFilterArrToStr,
  addQueryParamsToURL,
  getQueryParamsFromURL,
  transformFilterStrToArr,
  rulesToQueryParam,
  queryParamToRules,
} from '~/utils';
import { TotalRow } from '~/components/organisms/TotalRow';
import {
  clearViews,
  createUserView,
  deleteUserView,
  getUserViews,
  IView,
  updateUserView,
  userDataSelector,
  metricsViewsSelector,
  markViewAsFavorite,
} from '~/store/common';
import { useViewTabs } from '~/hooks';
import { CreateViewDialog } from '~/components/organisms/CreateViewDialog';
import { DeleteDialog, initDialogState, IDeleteDialogProps } from '~/components/organisms/DeleteDialog';

import { useStyles } from './styles';
import { columns, columnsWithRules } from './columns';

export const Metrics = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const { data, loading, totals } = useSelector(metricSelector);
  const { data: filtersData } = useSelector(metricFiltersSelector);
  const { data: userData } = useSelector(userDataSelector);
  const { data: views } = useSelector(metricsViewsSelector);

  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const [dateRange, setDateRange] = useState({} as DateRange);
  const [filters, setFilters] = useState<TFilter[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<TColumnVisibilityModel>(
    columns.reduce((acc, col) => {
      acc[col.field] = true;
      return acc;
    }, {} as TColumnVisibilityModel)
  );
  const [sortModel, setSortModel] = useState<Pick<IMetricQueries, 'sort' | 'order'>>({});
  const [viewTabs, setViewTabs] = useViewTabs(views);
  const [viewTabChanged, setViewTabChanged] = useState(false);
  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);
  const [rules, setRules] = useState<TRule[]>([]);
  const [deleteDialogState, setDeleteDialogState] = useState<IDeleteDialogProps>(initDialogState);

  useEffect(() => {
    dispatch(getMetricFilters());

    return () => {
      // without this, the totals will be shown under the table
      dispatch(clearViews());
      dispatch(clearMetrics());
    };
  }, []);

  useEffect(() => {
    if (viewTabs.length && !hasBeenInitialized) initTableState(filtersData);
  }, [filtersData, viewTabs]);

  useEffect(() => {
    if (userData?.id) dispatch(getUserViews(userData.id));
  }, [userData]);

  useEffect(() => {
    if (!hasBeenInitialized || !filtersData) return;

    const queryParams = createQueryParams(filtersData);

    if (!viewTabChanged && queryParams) updateView(queryParams);
    setViewTabChanged(false);

    dispatch(getMetrics(queryParams));
  }, [filters, sortModel, dateRange, hasBeenInitialized, filtersData]);

  useEffect(() => {
    if (!hasBeenInitialized || !filtersData) return;

    const queryParams = createQueryParams(filtersData);

    if (!viewTabChanged && queryParams) updateView(queryParams);
    setViewTabChanged(false);
  }, [columnVisibilityModel, rules]);

  const handleDateChange = (range: Partial<DateRange>) => {
    setDateRange({ ...dateRange, ...range });
  };

  const createQueryParams = (filtersData: IMetricFilters) => {
    if (!filtersData) return;

    const queryParams: IMetricQueries = {};

    if ((dateRange.from && dateRange.to) || (!dateRange.from && !dateRange.to)) {
      queryParams.from = dateRange.from;
      queryParams.to = dateRange.to;
    }

    if (filters.length) {
      queryParams.filter = transformFilterArrToStr(filters);
    } else {
      queryParams.filter = '';
    }

    if (sortModel.sort && sortModel.order) {
      queryParams.sort = sortModel.sort;
      queryParams.order = sortModel.order;
    }

    if (rules.length) {
      queryParams.rules = rulesToQueryParam(rules);
    }

    if (location.href.includes('shared') && viewTabs[0].selected) {
      queryParams.shared = 'true';
    }

    if (!isEqual(getQueryParamsFromURL(), queryParams)) {
      addQueryParamsToURL(queryParams);
    }

    return queryParams;
  };

  const initTableState = (filtersData: IMetricFilters | null) => {
    if (!filtersData) return;

    const queryParams = getQueryParamsFromURL();

    if (queryParams.from !== undefined && queryParams.to !== undefined) {
      setDateRange({ from: queryParams.from, to: queryParams.to });
    } else {
      const thisWeek = {
        from: dayjs().startOf('week').utc(true).format(),
        to: dayjs().endOf('week').utc(true).format(),
      };
      setDateRange(thisWeek);
    }

    if (queryParams.filter) {
      setFilters(transformFilterStrToArr(queryParams.filter, filtersData));
    }

    if (queryParams.sort && queryParams.order) {
      setSortModel({ sort: queryParams.sort, order: queryParams.order });
    }

    if (queryParams.rules) {
      setRules(queryParamToRules(queryParams.rules));
    }

    const favoriteView = views?.find((v) => v.isFavorite);
    if (favoriteView && queryParams.shared === undefined) {
      handleViewClick(favoriteView.id);
    } else {
      const newViewTabs = [...viewTabs];
      newViewTabs[0].selected = true;
      setViewTabs(newViewTabs);
    }

    setHasBeenInitialized(true);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel({ sort: model[0]?.field, order: model[0]?.sort?.toUpperCase() as 'DESC' | 'ASC' });
  };

  const handleViewClick = (viewId: string) => {
    if (filtersData === null) return;

    const view = viewTabs.find((tab) => tab.id === viewId);
    if (!view || view.selected) return;

    const newTabs = viewTabs.map((tab) => ({ ...tab, selected: tab.id === viewId }));
    setViewTabs(newTabs);

    if (view.filter) {
      setFilters(transformFilterStrToArr(view.filter, filtersData));
    } else {
      setFilters([]);
    }

    if (view.sort && view.order) {
      setSortModel({ sort: view.sort, order: view.order });
    } else {
      setSortModel({});
    }

    if (view.rules) {
      setRules(queryParamToRules(view.rules));
    } else {
      setRules([]);
    }

    setDateRange({ from: view.from, to: view.to });

    setColumnVisibilityModel(
      columns.reduce((acc, col) => {
        if (view.columns?.includes(col.field)) {
          acc[col.field] = true;
        } else {
          acc[col.field] = false;
        }
        return acc;
      }, {} as TColumnVisibilityModel)
    );

    if (view.name === 'All') {
      resetTableState();
    }

    setViewTabChanged(true);
  };

  const createView = (name: string) => {
    if (!filtersData) return;

    const newView = { ...createQueryParams(filtersData), userId: userData?.id } as IView & { userId: string };

    newView.name = name;
    newView.type = 'table';
    newView.kind = 'metric';
    newView.columns = Object.keys(columnVisibilityModel);

    return dispatch(createUserView(newView));
  };

  const deleteView = () => {
    setDeleteDialogState((s) => ({ ...s, loading: true }));
    const viewId = viewTabs.find((tab) => tab.selected)?.id;

    viewId &&
      dispatch(deleteUserView(viewId)).then((r) => {
        if (!r.type.includes('rejected')) {
          setViewTabs([
            { ...viewTabs[0], selected: true },
            ...viewTabs.filter((tab) => tab.id !== viewId).slice(1),
          ]);

          setViewTabChanged(true);
          resetTableState();
          setDeleteDialogState(initDialogState);
        }
      });
  };

  const updateView = (queryParams: IMetricQueries) => {
    const currentView = viewTabs.find((tab) => tab.selected);
    const columns = Object.keys(columnVisibilityModel).reduce((acc, key) => {
      if (columnVisibilityModel[key]) acc.push(key);
      return acc;
    }, [] as string[]);

    if (currentView && currentView.name !== 'All') {
      dispatch(updateUserView({ ...currentView, ...queryParams, columns }));
    }
  };

  const resetTableState = () => {
    setFilters([]);
    setSortModel({});
    const thisWeek = {
      from: dayjs().startOf('week').utc(true).format(),
      to: dayjs().endOf('week').utc(true).format(),
    };
    setDateRange(thisWeek);

    setColumnVisibilityModel(
      columns.reduce((acc, col) => {
        acc[col.field] = true;
        return acc;
      }, {} as TColumnVisibilityModel)
    );
  };

  const handleGetCellClassName = (params: GridCellParams<IMetric>) => {
    let className;
    rules.forEach((rule) => {
      if (rule.field === params.field) {
        switch (rule.operator?.value) {
          case '===':
            if (params.value === rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          case '<':
            if (+(params.value as number) < rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          case '>':
            if (+(params.value as number) > rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          default:
            break;
        }
      }
    });

    return className || '';
  };

  const handleFavoriteClick = (viewId: string) => {
    dispatch(markViewAsFavorite({ viewId, views: viewTabs }));
  };

  const handleDeleteViewClick = () => {
    setDeleteDialogState({
      open: true,
      title: 'Delete view',
      message: 'Are you sure you want to delete this view?',
      loading: false,
      onClose: () => setDeleteDialogState(initDialogState),
      onDelete: deleteView,
    });
  };

  return (
    <Box className={classes.metricsRoot}>
      <Typography sx={{ margin: '32px 0 8px 32px' }} variant="h4">
        Team Performance
      </Typography>
      <Box className={classes.tableBox}>
        <TableToolbar
          views={viewTabs}
          onViewClick={handleViewClick}
          onViewDelete={handleDeleteViewClick}
          addNewView={() => setCreateViewDialogOpen(true)}
          columnsWithRules={columnsWithRules}
          dateRange={dateRange}
          filters={filters}
          filtersData={filtersData}
          onFilterChange={setFilters}
          onDateChange={handleDateChange}
          onRuleChange={setRules}
          rules={rules}
          hasEmails
          mailingList={userData?.mailingLists}
          onFavoriteClick={handleFavoriteClick}
        />
        <DataGrid
          sortingOrder={['desc', 'asc', null]}
          columnVisibilityModel={columnVisibilityModel}
          className={classes.table}
          rows={data ? data : []}
          columns={columns}
          slots={{
            columnResizeIcon: () => null,
          }}
          disableRowSelectionOnClick
          disableColumnMenu
          hideFooterPagination
          hideFooter
          loading={loading}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          sortModel={
            sortModel.sort && sortModel.order
              ? [{ field: sortModel.sort, sort: sortModel.order.toLowerCase() as GridSortDirection }]
              : []
          }
          getCellClassName={handleGetCellClassName}
        />
        {totals ? <TotalRow columns={columns} values={totals} /> : null}
      </Box>
      <CreateViewDialog
        open={createViewDialogOpen}
        closeDialog={() => setCreateViewDialogOpen(false)}
        createView={createView}
      />
      <DeleteDialog
        open={deleteDialogState.open}
        loading={deleteDialogState.loading}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        onClose={deleteDialogState.onClose}
        onDelete={deleteDialogState.onDelete}
      />
    </Box>
  );
};
