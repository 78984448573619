import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { IView } from '~/store/common';
import { useIsMount } from '~/hooks/isMount';

export const useViewTabs = (
  views: IView[] | null
): [(IView & { selected: boolean })[], Dispatch<SetStateAction<(IView & { selected: boolean })[]>>] => {
  const isMount = useIsMount();

  const [viewTabs, setViewTabs] = useState<Array<IView & { selected: boolean }>>([]);

  useEffect(() => {
    if (isMount && views) return;

    if (viewTabs.length === 0 && views) {
      const withSelected = views.map((v) => ({ ...v, selected: false }));
      withSelected.unshift({ id: 'all', name: 'All', selected: false } as IView & { selected: boolean });
      setViewTabs(withSelected);
    }

    if (viewTabs.length > 0 && views) {
      const withSelected = [viewTabs[0]];

      views.forEach((v) => {
        const found = viewTabs.find((view) => view.id === v.id);
        if (found) {
          withSelected.push({ ...v, selected: found.selected });
        } else {
          withSelected.push({ ...v, selected: false });
        }
      });

      setViewTabs(withSelected);
    }
  }, [views]);

  return [viewTabs, setViewTabs];
};
