import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { sliceName } from './actionTypes';
import { IWhiteboard, IWhiteboardFilters } from './types';
import { getWhiteboard, getWhiteboardFilters, updateWhiteboardRecord } from './thunks';
import { addRowNumber } from './formatters';

export interface WhiteState {
  whiteboard: {
    data: IWhiteboard[] | null;
    page: number | null;
    limit: number | null;
    total: number | null;
    loading: boolean;
  };
  filters: {
    data: IWhiteboardFilters | null;
    loading: boolean;
  };
}

const initialState: WhiteState = {
  whiteboard: {
    data: null,
    loading: false,
    page: null,
    total: null,
    limit: null,
  },
  filters: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearWhiteboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWhiteboard.pending, (state) => {
      state.whiteboard.loading = true;
    });
    builder.addCase(getWhiteboard.fulfilled, (state, action) => {
      state.whiteboard.loading = false;
      state.whiteboard.data = addRowNumber(
        action.payload.data,
        action.payload.page,
        action.payload.limit
      ).map((r) => {
        r.progress = isNaN(+r.progress) ? r.progress : Math.floor(+r.progress * 100);
        return r;
      });
      state.whiteboard.page = action.payload.page;
      state.whiteboard.total = action.payload.total;
      state.whiteboard.limit = action.payload.limit;
    });
    builder.addCase(getWhiteboard.rejected, (state) => {
      state.whiteboard.loading = false;
    });
    builder.addCase(getWhiteboardFilters.pending, (state) => {
      state.filters.loading = true;
    });
    builder.addCase(getWhiteboardFilters.fulfilled, (state, action) => {
      state.filters.loading = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getWhiteboardFilters.rejected, (state) => {
      state.filters.loading = false;
    });
    builder.addCase(updateWhiteboardRecord.fulfilled, (state, action) => {
      if (state.whiteboard.data) {
        const index = state.whiteboard.data.findIndex((item) => item.id === action.payload.id);
        if (index !== -1) {
          state.whiteboard.data[index] = {
            ...state.whiteboard.data[index],
            ...action.payload,
            progress: Math.floor(+action.payload.progress * 100),
          };
        }
      }
    });
  },
});

export const whiteboardSelector = (state: RootState) => state.whiteboard.whiteboard;
export const filtersSelector = (state: RootState) => state.whiteboard.filters;

export const { clearWhiteboard } = slice.actions;

export const whiteboardReducer = slice.reducer;
