export const sliceName = 'common';

export const loginActionType = `${sliceName}/login`;
export const updateAvatarActionType = `${sliceName}/updateAvatar`;
export const updateUserAttributesActionType = `${sliceName}/updateUserAttributes`;
export const verifyEmailActionType = `${sliceName}/verifyEmail`;
export const getUserViewsActionType = `${sliceName}/getUserViews`;
export const createUserViewActionType = `${sliceName}/createUserView`;
export const deleteUserViewActionType = `${sliceName}/deleteUserView`;
export const updateUserViewActionType = `${sliceName}/updateUserView`;
export const markViewAsFavoriteActionType = `${sliceName}/markViewAsFavorite`;
export const getUserInfoActionType = `${sliceName}/getUserInfo`;
export const newPasswordRequiredActionType = `${sliceName}/newPasswordRequired`;
export const updateLastLoginActionType = `${sliceName}/updateLastLogin`;
