import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const actionBoxHeight = 52;
export const filterBoxHeight = 59;

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {},
  actionBox: {
    height: `${actionBoxHeight}px`,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  viewsList: {
    height: '100%',
    display: 'flex',
    paddingLeft: '10px',
    overflowX: 'hidden',
  },
  filterBox: {
    height: `${filterBoxHeight}px`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid #8D8D8D`,
    borderBottom: `1px solid #8D8D8D`,
    padding: '0 10px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  disabledFilterBox: {
    height: 0,
    borderTop: 'none',
  },
  chipsBox: {
    display: 'flex',
    alignItems: 'center',
  },
  datePicker: {
    width: '170px',
    margin: '0 12px',
    backgroundColor: theme.palette.background.paper,
    borderBottom: '1px solid #8D8D8D',
    '& .MuiOutlinedInput-input': {
      padding: '4px 4px 4px 16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .MuiSvgIcon-root': {
      fill: '#fff',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      top: 'auto',
      bottom: 0,
      height: '85%',
      width: '75%',
    },
  },
  ruleBtn: {
    height: '100%',
    textTransform: 'none',
    color: theme.palette.text.primary,
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));
