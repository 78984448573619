import { FC, useDeferredValue, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridCellParams, GridColDef, GridSortDirection } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid/models/gridRows';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { GridEventListener } from '@mui/x-data-grid/models/events';
import isEqual from 'lodash/isEqual';

import {
  DateRange,
  TableToolbar,
  TColumnVisibilityModel,
  TFilter,
  TFilterData,
  TRecordDrawerState,
  TRule,
} from '~/components/organisms/TableToolbar';
import {
  addQueryParamsToURL,
  getQueryParamsFromURL,
  queryParamToRules,
  rulesToQueryParam,
  transformFilterArrToStr,
  transformFilterStrToArr,
} from '~/utils';
import { useViewTabs } from '~/hooks';
import {
  createUserView,
  deleteUserView,
  getUserViews,
  IColumnsSize,
  IView,
  markViewAsFavorite,
  metricsViewsSelector,
  updateUserView,
  userDataSelector,
  userManagementViewsSelector,
  whiteboardInsightsViewsSelector,
  whiteboardMetricsViewsSelector,
  whiteboardViewsSelector,
} from '~/store/common';
import { useAppDispatch } from '~/store';
import { DeleteDialog, IDeleteDialogProps, initDialogState } from '~/components/organisms/DeleteDialog';
import { CreateViewDialog } from '~/components/organisms/CreateViewDialog';
import { VisibilityDrawer } from '~/components/organisms/CommonTable/components/VisibilityDrawer';
import {
  newFilterInitState,
  IWhiteboardFilterConditionSet,
} from '~/components/organisms/TableToolbar/components/NewFiltersMenu';

import { useStyles } from './styles';
import { IQueryParams, TKind, TSortModel } from './types';
import { IUserWhiteboardMetric } from '~/store/whiteboardMetrics';

interface ICommonTableProps {
  kind: TKind;
  columnsDef: GridColDef[];
  data: GridRowsProp | null;
  loading?: boolean;
  total?: number;
  fetchData: (queryParams: IQueryParams, init?: boolean) => Promise<void>;
  fetchFiltersData: () => Promise<void>;
  cleanUp?: () => void;
  columnsWithRules: GridColDef[];
  filtersData: TFilterData | null;
  downloadCsv?: (queryParams: IQueryParams) => Promise<void>;
  defaultDateRange?: DateRange;
  onRowClick?: GridEventListener<'rowClick'>;
  hideFooterPagination?: boolean;
  hideFooter?: boolean;
  addNewRecord?: boolean;
  hideFilterToolbar?: boolean;
  hideViewTabs?: boolean;
  hideReportBtn?: boolean;
  sortingMode?: 'client' | 'server';
  onCellClick?: (params: GridCellParams) => void;
}

export const CommonTable: FC<ICommonTableProps> = (props) => {
  const {
    kind,
    columnsDef,
    data,
    loading,
    total,
    fetchData,
    cleanUp,
    columnsWithRules,
    filtersData,
    downloadCsv,
    fetchFiltersData,
    hideFooterPagination,
    hideFooter,
    addNewRecord,
    defaultDateRange = {
      from: null,
      to: null,
    },
    onRowClick,
    hideFilterToolbar,
    hideViewTabs,
    hideReportBtn,
    sortingMode = 'server',
    onCellClick,
  } = props;

  const dispatch = useAppDispatch();

  const { data: views } = useSelector(getViewSelector(kind));
  const { data: userData } = useSelector(userDataSelector);

  const [columns, setColumns] = useState<GridColDef[]>(columnsDef.map((col) => ({ ...col })) as GridColDef[]);
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 100,
  });
  const [rules, setRules] = useState<TRule[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<TColumnVisibilityModel>(
    columnsDef.reduce((acc, col) => {
      if (col.field !== 'rowNumber') acc[col.field] = true;
      return acc;
    }, {} as TColumnVisibilityModel)
  );
  const [sortModel, setSortModel] = useState<TSortModel>({});
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [filters, setFilters] = useState<TFilter[]>([]);
  const [newFilters, setNewFilters] = useState<IWhiteboardFilterConditionSet>(
    JSON.parse(JSON.stringify(newFilterInitState))
  );
  const deferredNewFilters = useDeferredValue(newFilters);
  const [recordDrawer, setRecordDrawer] = useState<TRecordDrawerState>({
    open: false,
    type: 'add',
    data: null,
  });
  const [viewTabChanged, setViewTabChanged] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState<IDeleteDialogProps>(initDialogState);
  const [createViewDialogOpen, setCreateViewDialogOpen] = useState(false);
  const [openVisibilityDrawer, setOpenVisibilityDrawer] = useState(false);

  const { classes } = useStyles({ openVisibilityDrawer });

  // custom hooks
  const [viewTabs, setViewTabs] = useViewTabs(views);

  useEffect(() => {
    fetchFiltersData();
    if (userData?.id) dispatch(getUserViews(userData.id));
    return () => {
      cleanUp?.();
    };
  }, []);

  useEffect(() => {
    if (viewTabs.length && !hasBeenInitialized && filtersData) initTableState();
  }, [filtersData, viewTabs]);

  useEffect(() => {
    if (!hasBeenInitialized) return;

    const queryParams = createQueryParams();

    if (!viewTabChanged && queryParams) updateView(queryParams);
    setViewTabChanged(false);

    fetchData(queryParams);
  }, [dateRange, paginationModel, filters, deferredNewFilters, hasBeenInitialized]);

  useEffect(() => {
    if (!hasBeenInitialized) return;

    const queryParams = createQueryParams();

    if (!viewTabChanged && queryParams) updateView(queryParams);
    setViewTabChanged(false);

    if (sortingMode === 'server') fetchData(queryParams);
  }, [sortModel]);

  useEffect(() => {
    if (!hasBeenInitialized) return;

    const queryParams = createQueryParams();

    if (!viewTabChanged && queryParams) updateView(queryParams);
    setViewTabChanged(false);
  }, [columnVisibilityModel, rules]);

  const initTableState = () => {
    const queryParams = getQueryParamsFromURL();

    if (queryParams.from !== undefined && queryParams.to !== undefined) {
      setDateRange({ from: queryParams.from, to: queryParams.to });
    } else {
      setDateRange(defaultDateRange);
    }

    if (kind === 'whiteboard') {
      if (queryParams.filter) {
        let newFilters: IWhiteboardFilterConditionSet = JSON.parse(JSON.stringify(newFilterInitState));
        try {
          newFilters = JSON.parse(queryParams.filter);
        } catch (e) {
          console.log(e);
        }
        setNewFilters(newFilters);
      }
    } else {
      if (queryParams.filter) {
        setFilters(transformFilterStrToArr(queryParams.filter, filtersData));
      }
    }

    if (queryParams.sort && queryParams.order) {
      setSortModel({ sort: queryParams.sort, order: queryParams.order });
    }

    if (queryParams.rules) {
      setRules(queryParamToRules(queryParams.rules));
    }

    if (queryParams.search) {
      setSearch(queryParams.search);
    }

    const favoriteView = views?.find((v) => v.isFavorite);
    if (favoriteView && queryParams.shared === undefined) {
      handleViewClick(favoriteView.id);
    } else {
      const newViewTabs = [...viewTabs];
      newViewTabs[0].selected = true;
      setViewTabs(newViewTabs);
    }

    setHasBeenInitialized(true);
  };

  const createQueryParams = (searchQuery?: string) => {
    const queryParams: IQueryParams = {};

    if (((dateRange.from && dateRange.to) || (!dateRange.from && !dateRange.to)) && !hideFilterToolbar) {
      queryParams.from = dateRange.from;
      queryParams.to = dateRange.to;
    }

    if (kind === 'whiteboard') {
      if (newFilters.filterSet.length) {
        queryParams.filter = JSON.stringify(newFilters);
      } else {
        queryParams.filter = '';
      }
    } else {
      if (filters.length) {
        queryParams.filter = transformFilterArrToStr(filters);
      } else {
        queryParams.filter = '';
      }
    }

    if (sortModel.sort && sortModel.order) {
      queryParams.sort = sortModel.sort;
      queryParams.order = sortModel.order;
    }

    if (rules.length) {
      queryParams.rules = rulesToQueryParam(rules);
    }

    if (location.href.includes('shared') && viewTabs[0]?.selected) {
      queryParams.shared = 'true';
    }

    if (searchQuery !== undefined || search) {
      queryParams.search = searchQuery !== undefined ? searchQuery : search;
    }

    if (!isEqual(getQueryParamsFromURL(), queryParams)) {
      addQueryParamsToURL(queryParams);
    }

    queryParams.page = paginationModel.page + 1;
    queryParams.limit = paginationModel.pageSize;

    return queryParams;
  };

  const createView = (name: string) => {
    const newView = { ...createQueryParams(), userId: userData?.id } as IView & { userId: string };

    newView.name = name;
    newView.type = 'table';
    newView.kind = kind;
    newView.columns = Object.keys(columnVisibilityModel);
    newView.columnsSize = columns.reduce((acc, col) => {
      acc[col.field] = col.width || 200;
      return acc;
    }, {} as IColumnsSize);

    return dispatch(createUserView(newView));
  };

  const updateView = (queryParams: IQueryParams) => {
    const currentView = viewTabs.find((tab) => tab.selected);
    const viewColumns = Object.keys(columnVisibilityModel).reduce((acc, key) => {
      if (columnVisibilityModel[key]) acc.push(key);
      return acc;
    }, [] as string[]);
    const columnsSize = columns.reduce((acc, col) => {
      acc[col.field] = col.width || 200;
      return acc;
    }, {} as IColumnsSize);

    if (currentView && currentView.name !== 'All') {
      dispatch(updateUserView({ ...currentView, ...queryParams, columns: viewColumns, columnsSize }));
    }
  };

  const deleteView = () => {
    setDeleteDialogState((s) => ({ ...s, loading: true }));
    const viewId = viewTabs.find((tab) => tab.selected)?.id;

    viewId &&
      dispatch(deleteUserView(viewId)).then((r) => {
        if (!r.type.includes('rejected')) {
          setViewTabs([
            { ...viewTabs[0], selected: true },
            ...viewTabs.filter((tab) => tab.id !== viewId).slice(1),
          ]);

          setViewTabChanged(true);
          resetTableState();
          setDeleteDialogState(initDialogState);
        }
      });
  };

  const handleViewClick = (viewId: string) => {
    const view = viewTabs.find((tab) => tab.id === viewId);
    if (!view || view.selected) return;

    const newTabs = viewTabs.map((tab) => ({ ...tab, selected: tab.id === viewId }));
    setViewTabs(newTabs);

    if (kind === 'whiteboard') {
      let newFilters: IWhiteboardFilterConditionSet = JSON.parse(JSON.stringify(newFilterInitState));
      if (view.filter) {
        try {
          newFilters = JSON.parse(view.filter);
        } catch (e) {
          console.log(e);
        }
        setNewFilters(newFilters);
      } else {
        setNewFilters(newFilters);
      }
    } else {
      if (view.filter) {
        setFilters(transformFilterStrToArr(view.filter, filtersData));
      } else {
        setFilters([]);
      }
    }

    if (view.sort && view.order) {
      setSortModel({ sort: view.sort, order: view.order });
    } else {
      setSortModel({});
    }

    if (view.rules) {
      setRules(queryParamToRules(view.rules));
    } else {
      setRules([]);
    }

    setSearch('');

    setDateRange({ from: view.from, to: view.to });

    setColumnVisibilityModel(
      columns.reduce((acc, col) => {
        if (view.columns?.includes(col.field) || col.field === 'rowNumber') {
          acc[col.field] = true;
        } else {
          acc[col.field] = false;
        }
        return acc;
      }, {} as TColumnVisibilityModel)
    );

    const newColumns = columns.map((col, index) => {
      if (view.columnsSize?.[col.field]) {
        col.width = view.columnsSize[col.field];
      } else {
        col.width = columnsDef[index].width || 200;
      }
      return col;
    });

    setColumns(newColumns);

    if (view.name === 'All') {
      resetTableState();
    }

    setViewTabChanged(true);
  };

  const handleDeleteViewClick = () => {
    setDeleteDialogState({
      open: true,
      title: 'Delete view',
      message: 'Are you sure you want to delete this view?',
      loading: false,
      onClose: () => setDeleteDialogState(initDialogState),
      onDelete: deleteView,
    });
  };

  const handleGetCellClassName = (params: GridCellParams) => {
    let className;
    rules.forEach((rule) => {
      if (rule.field === params.field) {
        switch (rule.operator?.value) {
          case '===':
            if (params.value === +rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          case '<':
            if (+(params.value as number) < +rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          case '>':
            if (+(params.value as number) > +rule.value) {
              className = classes[rule.color?.label.toLowerCase() as 'green' | 'red' | 'yellow'];
            }
            break;
          default:
            break;
        }
      }
    });

    return className || '';
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel({ sort: model[0]?.field, order: model[0]?.sort?.toUpperCase() as 'DESC' | 'ASC' });
  };

  const resetTableState = () => {
    if (kind === 'whiteboard') {
      setNewFilters(JSON.parse(JSON.stringify(newFilterInitState)));
    } else {
      setFilters([]);
    }
    setSortModel({});
    setDateRange(defaultDateRange);

    setColumnVisibilityModel(
      columns.reduce((acc, col) => {
        if (col.field !== 'rowNumber') acc[col.field] = true;
        return acc;
      }, {} as TColumnVisibilityModel)
    );
  };

  const handleDateChange = (range: Partial<DateRange>) => {
    setDateRange({ ...dateRange, ...range });
  };

  const handleFavoriteClick = (viewId: string) => {
    dispatch(markViewAsFavorite({ viewId, views: viewTabs }));
  };

  const handleDownloadCsv = () => {
    const queryParams = filtersData ? createQueryParams(search) : {};

    return downloadCsv?.(queryParams) as unknown as Promise<void>;
  };

  const handleUpdateTable = (searchQuery?: string) => {
    const queryParams = createQueryParams(searchQuery);

    fetchData(queryParams);
  };

  const handleColumnWidthChange = (field: string, value: number) => {
    setColumns((c) => {
      const col = c.find((c) => c.field === field);
      if (col) {
        col.width = value;
      }
      return [...c];
    });
    const queryParams = createQueryParams();

    updateView(queryParams);
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) =>
    setRecordDrawer({ open: true, type: 'edit', data: params.row });

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
      }}>
      <Box className={classes.tableBox}>
        <TableToolbar
          kind={kind}
          newFilters={newFilters}
          updateNewFilters={setNewFilters}
          rules={rules}
          views={viewTabs}
          onViewClick={handleViewClick}
          onViewDelete={handleDeleteViewClick}
          addNewView={() => setCreateViewDialogOpen(true)}
          columnsWithRules={columnsWithRules}
          dateRange={dateRange}
          onDateChange={handleDateChange}
          filters={filters}
          filtersData={filtersData}
          onFilterChange={setFilters}
          addNewRecord={addNewRecord}
          recordDrawerState={recordDrawer}
          onRecordDrawerChange={setRecordDrawer}
          updateTable={handleUpdateTable}
          onRuleChange={setRules}
          onFavoriteClick={handleFavoriteClick}
          onSearchChange={(newValue) => setSearch(newValue)}
          searchQuery={search}
          downloadCsv={handleDownloadCsv}
          onVisibilityMenuClick={() => setOpenVisibilityDrawer((o) => !o)}
          hideFilterToolbar={hideFilterToolbar}
          hideViewTabs={hideViewTabs}
          hideReportBtn={hideReportBtn}
        />
        <DataGrid
          className={classes.table}
          columns={columns}
          rows={data ? data : []}
          sortingOrder={['desc', 'asc', null]}
          loading={loading || data === null}
          rowCount={total ? total : 0}
          slots={{
            columnResizeIcon: () => null,
          }}
          disableRowSelectionOnClick
          hideFooterPagination={hideFooterPagination}
          hideFooter={hideFooter}
          disableColumnMenu
          sortingMode={sortingMode}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          getCellClassName={handleGetCellClassName}
          columnVisibilityModel={columnVisibilityModel}
          onSortModelChange={handleSortModelChange}
          sortModel={
            sortModel.sort && sortModel.order
              ? [{ field: sortModel.sort, sort: sortModel.order.toLowerCase() as GridSortDirection }]
              : []
          }
          onRowClick={addNewRecord ? handleRowClick : onRowClick}
          onCellClick={onCellClick}
        />
        <CreateViewDialog
          open={createViewDialogOpen}
          closeDialog={() => setCreateViewDialogOpen(false)}
          createView={createView}
        />
        <DeleteDialog
          open={deleteDialogState.open}
          loading={deleteDialogState.loading}
          title={deleteDialogState.title}
          message={deleteDialogState.message}
          onClose={deleteDialogState.onClose}
          onDelete={deleteDialogState.onDelete}
        />
      </Box>
      <VisibilityDrawer
        open={openVisibilityDrawer}
        columns={columns}
        columnVisibility={columnVisibilityModel}
        onColumnVisibilityChange={setColumnVisibilityModel}
        onClose={() => setOpenVisibilityDrawer(false)}
        onColumnWidthChange={handleColumnWidthChange}
      />
    </Box>
  );
};

const getViewSelector = (kind: TKind) => {
  switch (kind) {
    case 'whiteboard':
      return whiteboardViewsSelector;
    case 'metric':
      return metricsViewsSelector;
    case 'whiteboard-metric':
      return whiteboardMetricsViewsSelector;
    case 'user-management':
      return userManagementViewsSelector;
    case 'whiteboard-insights':
      return whiteboardInsightsViewsSelector;
  }
};
