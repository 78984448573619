import React, { Dispatch, FC, useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton } from '@mui/material';
import { Dayjs } from 'dayjs';
import { GridColDef } from '@mui/x-data-grid';
import Add from '@mui/icons-material/Add';
import { isEmpty } from 'lodash';
import RuleIcon from '@mui/icons-material/Rule';
import SettingsIcon from '@mui/icons-material/Settings';
import clsx from 'clsx';

import { TPeriodId } from '~/pages/Dashboard/types';
import { calculateTimePeriods, getTimePeriodFromDateRange } from '~/utils';
import { IView } from '~/store/common';
import { TKind } from '~/components/organisms/CommonTable';

import { TMailingListItem } from '~/store/metric';
import { ChipWithMenu } from './components/ChipWithMenu';
import { Filters } from './components/Filters';
import { DateRange, TFilter, TFilterData, TRecordDrawerState, TRule } from './types';
import { useStyles } from './styles';
import { ViewTab } from './components/ViewTab';
import { RecordDrawer } from './components/RecordDrawer';
import { DatePickerMenu } from './components/DatePicker';
import { RuleMenu } from './components/RuleMenu';
import { RuleChip } from './components/RuleChip';
import { EmailForm } from './components/EmailForm';
import { RecurringEmailBtn } from './components/RecurringEmailBtn';
import { ShareViewBtn } from './components/ShareViewBtn';
import { Search } from './components/Search';
import { DownloadBtn } from './components/DownloadBtn';
import { IWhiteboardFilterConditionSet } from './components/NewFiltersMenu';

interface ITableToolbarProps {
  views: Array<IView & { selected: boolean }>;
  onViewClick: (viewId: string) => void;
  onViewDelete: (viewId: string) => void;
  addNewView: () => void;
  columnsWithRules: GridColDef[];
  dateRange: DateRange;
  filters: TFilter[];
  filtersData: TFilterData | null;
  onFilterChange: (filters: TFilter[]) => void;
  onDateChange: (range: Partial<DateRange>) => void;
  recordDrawerState?: TRecordDrawerState;
  onRecordDrawerChange?: Dispatch<React.SetStateAction<TRecordDrawerState>>;
  actionBox?: boolean;
  addNewRecord?: boolean;
  updateTable?: (searchQuery?: string) => void;
  onRuleChange?: React.Dispatch<React.SetStateAction<TRule[]>>;
  rules?: TRule[];
  hasEmails?: boolean;
  mailingList?: TMailingListItem[];
  onFavoriteClick: (viewId: string) => void;
  onSearchChange?: (value: string) => void;
  searchQuery?: string;
  downloadCsv?: () => Promise<void>;
  onVisibilityMenuClick?: () => void;
  hideFilterToolbar?: boolean;
  hideViewTabs?: boolean;
  hideReportBtn?: boolean;
  kind?: TKind;
  newFilters?: IWhiteboardFilterConditionSet;
  updateNewFilters?: (filterState: IWhiteboardFilterConditionSet) => void;
}

export const TableToolbar: FC<ITableToolbarProps> = (props) => {
  const {
    actionBox = true,
    onDateChange,
    dateRange,
    filters,
    onFilterChange,
    filtersData,
    views,
    onViewClick,
    addNewView,
    onViewDelete,
    addNewRecord,
    onRecordDrawerChange,
    recordDrawerState,
    updateTable,
    onRuleChange,
    rules,
    columnsWithRules,
    hasEmails,
    mailingList,
    onFavoriteClick,
    onSearchChange,
    searchQuery,
    downloadCsv,
    onVisibilityMenuClick,
    hideFilterToolbar,
    hideViewTabs,
    hideReportBtn,
    kind,
    newFilters,
    updateNewFilters,
  } = props;

  const { classes } = useStyles();

  const options = useRef(calculateTimePeriods());

  const [periodId, setPeriodId] = useState<TPeriodId>('' as TPeriodId);
  const [ruleMenuAnchor, setRuleMenuAnchor] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isEmpty(dateRange)) return;

    const period = getTimePeriodFromDateRange(dateRange.from, dateRange.to, options.current);
    setPeriodId(period.id);
  }, [dateRange]);

  const handleFromChange = (date: Dayjs | null) => {
    onDateChange({ from: date?.utc(true).format() });
  };

  const handleToChange = (date: Dayjs | null) => {
    onDateChange({ to: date?.utc(true).format() });
  };

  const handlePeriodChange = (periodId: TPeriodId) => {
    setPeriodId(periodId);
    const option = options.current.find((option) => option.id === periodId);
    if (option) onDateChange({ ...option.period });
  };

  const removeFilter = (filter: TFilter) => {
    const newFiltersArr = filters.slice();
    newFiltersArr.splice(filters.indexOf(filter), 1);
    onFilterChange(newFiltersArr);
  };

  const handleAddFilter = (filter: TFilter) => {
    // if filter with the same field already exists, add the value to the existing filter
    const existingFilter = filters.find((f) => f.field === filter.field);

    if (existingFilter) {
      let newValue = '';

      if ((existingFilter.value + '').includes(filter.value + '')) {
        newValue = (existingFilter.value + '')
          .split(',')
          .filter((v) => v !== filter.value)
          .join(',');

        if (!newValue) {
          removeFilter(existingFilter);
          return;
        }
      } else {
        newValue = existingFilter.value + `,${filter.value}`;
      }

      const newFilter = { ...existingFilter, value: newValue };
      const newFiltersArr = filters.slice();
      newFiltersArr.splice(filters.indexOf(existingFilter), 1, newFilter);
      onFilterChange(newFiltersArr);
    } else {
      onFilterChange([...filters, filter]);
    }
  };

  const handleOpenRuleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRuleMenuAnchor(event.currentTarget);
  };

  const handleCloseRuleMenu = () => {
    setRuleMenuAnchor(null);
  };

  return (
    <Box>
      {actionBox ? (
        <Box className={classes.actionBox}>
          <Box className={classes.viewsList}>
            {views?.length > 0 && !hideViewTabs && (
              <>
                {views.map((view) => (
                  <ViewTab
                    key={view.id}
                    name={view.name}
                    onClick={() => onViewClick(view.id)}
                    onDelete={() => onViewDelete(view.id)}
                    onFavoriteClick={() => onFavoriteClick(view.id)}
                    selected={view.selected}
                    isFavorite={view.isFavorite}
                  />
                ))}
                <Button
                  sx={{
                    height: '100%',
                    textTransform: 'none',
                    color: 'text.disabled',
                    paddingLeft: '12px',
                    paddingRight: '12px',
                  }}
                  startIcon={<Add />}
                  variant="text"
                  onClick={addNewView}>
                  Add view
                </Button>
              </>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {addNewRecord && (
              <Button
                sx={{ height: '32px', marginRight: '8px' }}
                variant="contained"
                onClick={() => onRecordDrawerChange?.({ open: true, type: 'add', data: null })}>
                Add record
              </Button>
            )}
            {onSearchChange && updateTable && (
              <Search onSearchChange={onSearchChange} updateTable={updateTable} searchQuery={searchQuery} />
            )}
            <ShareViewBtn />
            {!hideReportBtn && <DownloadBtn onClick={downloadCsv} />}
            {hasEmails && !mailingList?.[0] && <EmailForm />}
            <IconButton sx={{ color: 'text.primary' }} onClick={onVisibilityMenuClick}>
              <SettingsIcon />
            </IconButton>
          </Box>
        </Box>
      ) : null}
      <Box className={clsx([classes.filterBox, hideFilterToolbar && classes.disabledFilterBox])}>
        {hideFilterToolbar ? null : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              {hasEmails && mailingList && mailingList[0] && (
                <RecurringEmailBtn mailingListItem={mailingList[0]} />
              )}
              <DatePickerMenu
                onFromChange={handleFromChange}
                onToChange={handleToChange}
                dateRange={dateRange}
                currentPeriodId={periodId}
                options={options.current}
                onPeriodChange={handlePeriodChange}
              />
              <Button
                className={classes.ruleBtn}
                startIcon={<RuleIcon />}
                variant="outlined"
                onClick={handleOpenRuleMenu}>
                Rules
              </Button>
              {filtersData ? (
                <Filters
                  filters={filters}
                  filtersData={filtersData}
                  addFilter={handleAddFilter}
                  kind={kind}
                  newFilters={newFilters}
                  updateNewFilters={updateNewFilters}
                />
              ) : (
                <Box />
              )}
            </Box>
            <Box
              sx={{
                width: '1px',
                height: '36px',
                backgroundColor: '#fff',
              }}
            />
            <Box className={classes.chipsBox}>
              {rules?.length ? (
                <RuleChip rules={rules} columns={columnsWithRules} onRuleChange={onRuleChange} />
              ) : null}
              {filtersData &&
                kind !== 'whiteboard' &&
                filters.map((filter) =>
                  !hiddenFilters.includes(filter.field) ? (
                    <ChipWithMenu
                      key={filter.field}
                      filter={filter}
                      filtersData={filtersData}
                      filters={filters}
                      onDelete={removeFilter}
                      handleAddFilter={handleAddFilter}
                    />
                  ) : null
                )}
            </Box>
          </>
        )}
      </Box>
      {recordDrawerState && (
        <RecordDrawer
          updateTable={updateTable}
          recordDrawerState={recordDrawerState}
          onClose={() => onRecordDrawerChange?.((s) => ({ type: 'add', open: false, data: null }))}
        />
      )}
      <RuleMenu
        menuAnchor={ruleMenuAnchor}
        onClose={handleCloseRuleMenu}
        columns={columnsWithRules}
        onRuleChange={onRuleChange}
      />
    </Box>
  );
};

const hiddenFilters = ['id', 'recruiterId'];
