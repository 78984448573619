import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';

import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { CustomDatePicker } from '~/components/molecules/CustomDatePicker';
import { CustomField } from '~/components/molecules/CustomField';

export interface ICloseDateDialogProps {
  open: boolean;
  onClose: () => void;
  onUpdate: (date: dayjs.Dayjs | null, potential: string | number | null) => void;
  currentPotential: string | number | null;
}

export const CloseDateDialog: FC<ICloseDateDialogProps> = (props) => {
  const { open, onClose, onUpdate, currentPotential } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [potential, setPotential] = useState<string | number | null>(currentPotential);

  useEffect(() => {
    if (open) setPotential(currentPotential);
    return () => {
      setDate(dayjs());
    };
  }, [open]);

  const handleUpdate = () => {
    onUpdate(date, potential);
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }}>
      <DialogTitle>Please confirm</DialogTitle>
      <DialogContent sx={{ width: isMobile ? '70vw' : '350px' }}>
        <CustomDatePicker name="closeDate" label="Close date" value={date} onChange={setDate} />
        <CustomField
          sx={{ marginTop: '16px' }}
          fullWidth
          label="Potential"
          name="potential"
          value={potential}
          onChange={(e) => setPotential(e.target.value)}
        />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '24px',
          }}>
          <Button sx={{ marginRight: '4px' }} onClick={() => onClose()}>
            Cancel
          </Button>
          <ButtonWithLoading color="warning" onClick={handleUpdate} variant="contained">
            Update
          </ButtonWithLoading>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
