import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

const gap = 20;
const actionBoxHeight = 92;
const drawerWidth = 560;
export const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      top: 'auto',
      bottom: 0,
      height: 'calc(100% - 64px)', // header height
      width: drawerWidth + 'px',
      [theme.breakpoints.down('sm')]: {
        width: '85%',
        minWidth: 'auto',
      },
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    margin: '24px',
  },
  subTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    marginBottom: '24px',
  },
  formBox: {
    height: '100%',
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: actionBoxHeight + 20 + 'px',
  },
  input: {
    marginBottom: '24px',
    width: '100%',
  },
  byWhenDate: {
    width: `calc(50% - ${gap / 2 + 24}px)`,
    margin: '0 24px 24px',
  },
  row: {
    display: 'flex',
    gap: gap + 'px',
    padding: '0 24px',
  },
  padding: {
    padding: '0 24px',
  },
  actionBox: {
    height: actionBoxHeight + 'px',
    width: drawerWidth + 'px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '24px',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  bullhornLink: {
    color: '#FF7F00',
    textDecoration: 'none',
  },
}));
