import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  createUserRoot: {
    flex: 1,
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
    margin: '30px 0 8px 30px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginBottom: '25px',
    },
  },
  table: {
    width: '100%',
    border: 'none',
    position: 'relative',
    '& .MuiDataGrid-sortIcon': {
      fill: '#fff',
    },
    '& .MuiDataGrid-withBorderColor': {
      borderColor: 'rgba(255, 255, 255, 0.2)',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
    },
    '& .MuiDataGrid-columnHeader': {
      borderBottom: '1px solid #8D8D8D',
      borderColor: '#8D8D8D',

      '& .MuiDataGrid-columnHeaderTitle': {
        whiteSpace: 'break-spaces',
        lineHeight: '16px',
        fontWeight: 400,
      },
    },
  },
}));
