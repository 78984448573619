import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';

import {
  addToMailingListActionType,
  deleteFromMailingListActionType,
  getMetricActionType,
  getMetricFiltersActionType,
} from './actionTypes';
import { IMetricQueries, TMailingListItemBody } from './types';

export const getMetrics = createAsyncThunk(getMetricActionType, async (queries?: IMetricQueries) => {
  for (const key in queries) {
    if (!queries[key]) {
      delete queries[key];
    }
  }

  return API.get(apiName, Endpoints.metric, { queryStringParameters: queries });
});

export const getMetricFilters = createAsyncThunk(getMetricFiltersActionType, async () => {
  return API.get(apiName, Endpoints.metricFilters, {});
});

export const addToMailingList = createAsyncThunk(
  addToMailingListActionType,
  async (body: TMailingListItemBody) => {
    const requestBody: Record<string, unknown> = { ...body };

    for (const key in requestBody) {
      if (!requestBody[key as keyof TMailingListItemBody]) {
        delete requestBody[key as keyof TMailingListItemBody];
      }
    }

    requestBody.recipients = body.recipient.split(',').map((email) => email.trim());

    return API.post(apiName, Endpoints.addToMailingList, { body: requestBody });
  }
);

export const deleteFromMailingList = createAsyncThunk(deleteFromMailingListActionType, async (id: string) => {
  await API.del(apiName, `${Endpoints.addToMailingList}/${id}`, {});
  return id;
});
