import { FC } from 'react';
import { Box, CircularProgress, Dialog, Divider, List, ListItem, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { IHistoryData, whiteboardHistorySelector } from '~/store/dashboard';
import { HistoryItem } from '~/components/molecules/HistoryItem';

import { useStyles } from './styles';

interface IHistoryModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  link?: string;
}

export const HistoryModal: FC<IHistoryModalProps> = (props) => {
  const { open, onClose, title, link } = props;

  const { classes } = useStyles();

  const { data, loading } = useSelector(whiteboardHistorySelector);

  const handleLink = (item: IHistoryData) => {
    const filterSet = JSON.stringify({
      filterSet: [
        {
          name: 'id',
          value: [item.whiteboardId],
        },
      ],
      conjunction: 'and',
    });

    return link + '?filter=' + filterSet + '&shared=true';
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ maxHeight: '70vh', minWidth: '50px', minHeight: '50px' }}>
        {loading ? (
          <CircularProgress className={classes.spinner} size={24} />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxHeight: '70vh',
              minWidth: '50px',
              minHeight: '50px',
            }}>
            <Box className={classes.header}>
              <Typography lineHeight="1.25" variant="h6" noWrap>
                {title}
              </Typography>
            </Box>
            <Divider />
            {data && data.length > 0 ? (
              <Box
                sx={{
                  height: 'calc(100% - 53px)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  overflow: 'auto',
                }}>
                <Box sx={{ height: '100%' }}>
                  {data.slice(0, 10).map((item) => (
                    <Box sx={{ paddingTop: '12px' }} key={item.date}>
                      <Typography sx={{ fontWeight: 700, marginLeft: '24px' }}>{item.date}</Typography>
                      <List className={classes.list}>
                        {item.data.map((historyItem, index) => (
                          <ListItem key={index}>
                            <HistoryItem data={historyItem} link={handleLink(historyItem)} />
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <Box className={classes.nodataBox}>
                <Typography color="text.disabled">No data</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
