import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, LinearProgress } from '@mui/material';

import { router } from '~/navigation';
import { useAppDispatch } from '~/store';
import { initLoadingSelector, initThunk } from '~/store/common';

export const App = () => {
  const dispatch = useAppDispatch();

  const loading = useSelector(initLoadingSelector);

  useEffect(() => {
    if (import.meta.env.V_DEV_ENV === 'true' && !document.title.includes('DEV')) {
      document.title = document.title + ' [DEV]';
    }

    dispatch(initThunk());
  }, []);

  return loading ? (
    <Box sx={{ width: '100vw', height: '100vh' }} bgcolor="background.default">
      <LinearProgress />
    </Box>
  ) : (
    <RouterProvider router={router} />
  );
};
