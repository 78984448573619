import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';
import { saveAs } from 'file-saver';

import { apiName, Endpoints } from '~/constants';
import { IQueryParams } from '~/components/organisms/CommonTable/types';

import {
  downloadWhiteboardMetricsAction,
  getWhiteboardMetricsAction,
  getWhiteboardMetricsFiltersAction,
} from './actionTypes';

export const getWhiteboardMetrics = createAsyncThunk(
  getWhiteboardMetricsAction,
  async (queryParams: IQueryParams) => {
    for (const key in queryParams) {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    }
    return API.get(apiName, Endpoints.whiteboardMetrics, { queryStringParameters: queryParams });
  }
);

export const getWhiteboardMetricsFilters = createAsyncThunk(getWhiteboardMetricsFiltersAction, async () => {
  return API.get(apiName, Endpoints.whiteboardMetricsFilters, {});
});

export const exportWhiteboardMetrics = createAsyncThunk(
  downloadWhiteboardMetricsAction,
  async (queries?: IQueryParams) => {
    for (const key in queries) {
      if (!queries[key]) {
        delete queries[key];
      }
    }

    const response = await API.get(apiName, Endpoints.whiteboardMetricsExport, {
      responseType: 'blob',
      queryStringParameters: queries,
    });

    const blob = new Blob([response], { type: 'text/csv' });

    saveAs(blob, 'file.csv');
  }
);
