import { FC } from 'react';
import { HexColorPicker } from 'react-colorful';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface IColorPickerDialogProps {
  open: boolean;
  color: string;
  onColorChange: (color: string) => void;
  onClose: () => void;
}

export const ColorPickerDialog: FC<IColorPickerDialogProps> = (props) => {
  const { open, onClose, onColorChange, color } = props;

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }}>
      <DialogTitle>Color</DialogTitle>
      <DialogContent sx={{ overflow: 'unset', paddingTop: '18px !important' }}>
        <HexColorPicker color={color} onChange={onColorChange} />
      </DialogContent>
      <IconButton sx={{ position: 'absolute', top: 8, right: 16 }} onClick={onClose}>
        <CloseIcon sx={{ color: '#fff' }} />
      </IconButton>
    </Dialog>
  );
};
