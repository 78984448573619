import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  dashboardRoot: {
    width: '100%',
    height: '100%',
    padding: '32px',
    overflow: 'auto',
  },
  dashboardGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    [theme.breakpoints.down('monitor')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
    },
    gridAutoRows: '200px',
    gap: '24px',
  },
}));
