export enum Endpoints {
  signUp = '/email',
  metric = '/metric',
  metricFilters = '/metric/filters',
  userBySub = '/user/sub/:sub',
  userByUsername = '/user/:username',
  users = '/user',
  userViews = '/view/user/:id',
  view = '/view',
  whiteboard = '/whiteboard',
  whiteboardFilters = '/whiteboard/filters',
  whiteboardAddRecord = '/whiteboard/add',
  whiteboardWidget = '/whiteboard/widget/:kind',
  whiteboardMetrics = '/whiteboard/metrics',
  whiteboardMetricsFilters = '/whiteboard/metrics/filters',
  whiteboardMetricsExport = '/whiteboard/metrics/export',
  addToMailingList = '/email/add-to-mailing-list',
  userRoles = '/user-role',
  userStatuses = '/user-status',
  userRegister = '/user/register',
  whiteboardExport = '/whiteboard/export',
  notes = '/note',
  whiteboardHistory = '/whiteboard-history',
  bullhornUsers = '/bullhorn/users',
  whiteboardInsights = '/whiteboard/insights',
  whiteboardInsightsFilters = '/whiteboard/insights/filters',
  serviceStatus = '/monitoring',
  userRegisterCognito = '/user/register/cognito',
}
