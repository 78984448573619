import { AnyAction, Dispatch } from 'redux';

export const logErrors = () => (next: Dispatch) => (action: AnyAction) => {
  if (action.type.includes('rejected')) {
    console.error(action);
    if (listOf401Errors.includes(action.error.message)) {
      window.location.href = '/sign-in';
    }
  }
  return next(action);
};

const listOf401Errors = [
  'Request failed with status code 401',
  'No current user',
  'Refresh Token has expired',
];
