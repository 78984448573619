import { FC } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';

export const initDialogState = {
  open: false,
  title: '',
  message: '',
  loading: false,
  onClose: () => 1,
  onDelete: () => 1,
};

export interface IDeleteDialogProps {
  open: boolean;
  title: string;
  loading: boolean;
  message: string;
  onClose: () => void;
  onDelete: () => void;
}

export const DeleteDialog: FC<IDeleteDialogProps> = (props) => {
  const { open, onClose, onDelete, loading, title, message } = props;

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const handleDelete = async () => {
    onDelete();
  };

  return (
    <Dialog open={open} onClose={onClose} sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ width: isMobile ? '70vw' : '350px' }}>
        <DialogContentText sx={{ marginBottom: '25px' }}>{message}</DialogContentText>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '24px',
          }}>
          <Button sx={{ marginRight: '4px' }} onClick={() => onClose()}>
            Cancel
          </Button>
          <ButtonWithLoading color="error" loading={loading} onClick={handleDelete} variant="contained">
            Delete
          </ButtonWithLoading>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
