import { FC } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Typography } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker/DatePicker.types';
import { Dayjs } from 'dayjs';

interface ICustomDatePickerProps extends DatePickerProps<Dayjs> {
  label: string;
  name: string;
  error?: boolean;
  helperText?: string | false | undefined;
  required?: boolean;
  className?: string;
}

export const CustomDatePicker: FC<ICustomDatePickerProps> = (props) => {
  const { label, error, helperText, name, className, required, sx, ...rest } = props;
  return (
    <Box className={className}>
      <Typography
        sx={{
          fontSize: '13px',
          lineHeight: '15px',
          paddingBottom: '8px',
          color: error ? 'error.main' : 'text.primary',
        }}>
        {label}
        <Typography component="span" sx={{ color: 'red', marginLeft: '2px', fontSize: '12px' }}>
          {required ? '*' : ''}
        </Typography>
      </Typography>
      <DatePicker
        slotProps={{
          textField: {
            name,
            error,
          },
        }}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-input': {
            fontSize: '14px',
            padding: '12px ',
          },
          '& .MuiSvgIcon-root': {
            color: '#fff',
          },
          ...sx,
        }}
        {...rest}
      />
      <Typography sx={{ fontSize: '12px', lineHeight: '20px', margin: ' 3px 14px 0 0' }} color="error">
        {helperText}
      </Typography>
    </Box>
  );
};
