import dayjs from 'dayjs';

import { ITimePeriod } from '~/pages/Dashboard/types';

export const calculateTimePeriods = (): ITimePeriod[] => {
  const periods: ITimePeriod[] = [];
  const now = dayjs();

  periods.push({
    id: 'date',
    label: 'Date',
    period: {
      from: null,
      to: now.endOf('day').utc(true).format(),
    },
  });

  periods.push({
    id: 'today',
    label: 'Today',
    period: {
      from: now.startOf('day').utc(true).format(),
      to: now.endOf('day').utc(true).format(),
    },
  });

  periods.push({
    id: 'yesterday',
    label: 'Yesterday',
    period: {
      from: now.subtract(1, 'day').startOf('day').utc(true).format(),
      to: now.subtract(1, 'day').endOf('day').utc(true).format(),
    },
  });

  periods.push({
    id: 'this_week',
    label: 'This Week',
    period: {
      from: now.startOf('week').utc(true).format(),
      to: now.endOf('week').utc(true).format(),
    },
  });

  periods.push({
    id: 'last_week',
    label: 'Last Week',
    period: {
      from: now.subtract(1, 'week').startOf('week').utc(true).format(),
      to: now.subtract(1, 'week').endOf('week').utc(true).format(),
    },
  });

  periods.push({
    id: 'this_month',
    label: 'This Month',
    period: {
      from: now.startOf('month').utc(true).format(),
      to: now.endOf('month').utc(true).format(),
    },
  });

  periods.push({
    id: 'last_month',
    label: 'Last Month',
    period: {
      from: now.subtract(1, 'month').startOf('month').utc(true).format(),
      to: now.subtract(1, 'month').endOf('month').utc(true).format(),
    },
  });

  periods.push({
    id: 'all_time',
    label: 'All Time',
    period: {
      from: null,
      to: null,
    },
  });

  return periods;
};

export const getTimePeriodFromDateRange = (
  from: string | null,
  to: string | null,
  periods: ITimePeriod[]
): ITimePeriod => {
  const period = periods.find((period) => {
    if (period.period) {
      return period.period.from === from && period.period.to === to;
    }

    return false;
  });

  if (period) {
    return period;
  }

  return {
    id: 'date',
    label: 'Date',
    period: {
      from: null,
      to: dayjs().endOf('day').utc(true).format(),
    },
  };
};
