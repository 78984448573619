import { GridColDef } from '@mui/x-data-grid';

import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';

export const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    filterable: true,
    renderCell: ({ row }) => {
      return (
        <UsernameWithAvatar
          username={row.username}
          avatar={row.avatar}
          firstName={row.firstName}
          lastName={row.lastName}
          color={row.color}
        />
      );
    },
  },
  {
    field: 'totalActivity',
    headerName: 'Total Activity',
    width: 150,
  },
  {
    field: 'touches',
    headerName: 'Touches',
    width: 150,
  },
  {
    field: 'phoneScreens',
    headerName: 'Phone Screens',
    width: 150,
  },
  {
    field: 'followUps',
    headerName: 'Follow Ups',
    width: 150,
  },
  {
    field: 'sentCandidateToClientOwner',
    headerName: 'Sent Candidate to Client Owner',
    width: 150,
  },
  {
    field: 'subToClient',
    headerName: 'Sub to Client',
    width: 150,
  },
  {
    field: 'firstInterview',
    headerName: 'First Interview',
    width: 150,
  },
  {
    field: 'offerOut',
    headerName: 'Offer Out',
    width: 150,
  },
  {
    field: 'placement',
    headerName: 'Placement',
    width: 150,
  },
  {
    field: 'sentFreeAgreement',
    headerName: 'Sent Free Agreement',
    width: 150,
  },
  {
    field: 'newClient',
    headerName: 'New Client',
    width: 150,
  },
  {
    field: 'jobOrder',
    headerName: 'Job Order',
    width: 150,
  },
  {
    field: 'clientOwnerSubToClient',
    headerName: 'Client Owner Sub to Client',
    width: 150,
  },
  {
    field: 'clientOwnerInterview',
    headerName: 'Client Owner Interview',
    width: 150,
  },
  {
    field: 'clientOwnerOfferOut',
    headerName: 'Client Owner Offer Out',
    width: 150,
  },
  {
    field: 'clientOwnerPlacement',
    headerName: 'Client Owner Placement',
    width: 150,
  },
];

const notAllowed = ['name'];

export const columnsWithRules = columns.filter((column) => !notAllowed.includes(column.field as string));
