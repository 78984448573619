import { useEffect } from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { Routes } from '~/constants';

const SignUpWithoutAuth = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(Routes.dashboard);
  }, []);

  return (
    <Box sx={{ width: '100vw', height: '100vh', backgroundColor: 'grey' }}>
      <Typography>You are already logged in</Typography>
      Linkg to <Link to={Routes.dashboard}>Home</Link>
    </Box>
  );
};

export const SignUp = withAuthenticator(SignUpWithoutAuth);
