import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    paddingRight: '10px',
  },
  addFilterBtn: {
    height: '100%',
    textTransform: 'none',
    color: theme.palette.text.primary,
    paddingLeft: '12px',
    paddingRight: '12px',
  },
}));
