import { useState } from 'react';
import { Alert, Box, Button, Snackbar, Typography } from '@mui/material';
import { API } from 'aws-amplify';

import { NonRootLayout } from '~/components/organisms/NonRootLayout';
import { ReactComponent as ComingSoonLogo } from '~/assets/coming-soon.svg';
import { Endpoints, publicApiName } from '~/constants';
import { CustomField } from '~/components/molecules/CustomField';

import { useStyles } from './styles';

export const ComingSoon = () => {
  const { classes } = useStyles();

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSignUp = () => {
    API.post(publicApiName, Endpoints.signUp, {
      body: {
        email,
      },
    }).catch(() => setOpen(true));
  };

  // TODO: move to constants, and create global error handler
  const errorMsg = 'Sorry, something went wrong. Please try again later.';

  return (
    <NonRootLayout>
      <Box className={classes.root}>
        <ComingSoonLogo className={classes.logo} />
        <Box className={classes.contentBox}>
          <Typography sx={{ fontSize: '32px' }} color="text.primary">
            Coming Soon
          </Typography>
          <Typography color="text.disabled">Sign up today for more information</Typography>
          <CustomField
            className={classes.emailInput}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => setError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))}
            onFocus={() => setError(false)}
            label="Email"
            title="Email"
            fullWidth
            required
            helperText={'Invalid email address'}
            error={error}
          />
          <Button disabled={error || !email} fullWidth variant="contained" onClick={handleSignUp}>
            Sign Up
          </Button>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={errorMsg.length * 60}
          onClose={() => setOpen(false)}>
          <Alert severity="error">{errorMsg}</Alert>
        </Snackbar>
      </Box>
    </NonRootLayout>
  );
};
