import { TOperator } from '~/components/organisms/TableToolbar';

export const ruleOperators: TOperator[] = [
  {
    label: 'equal to',
    value: '===',
  },
  {
    label: 'less than',
    value: '<',
  },
  {
    label: 'greater than',
    value: '>',
  },
];

export const ruleColors: TOperator[] = [
  {
    label: 'Red',
    value: 'red',
  },
  {
    label: 'Green',
    value: 'green',
  },
  {
    label: 'Yellow',
    value: 'yellow',
  },
];
