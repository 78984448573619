import { FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';

import { WidgetPaper } from '~/components/atoms/WidgetPaper';
import { IHistoryData } from '~/store/dashboard';
import { HistoryItem } from '~/components/molecules/HistoryItem';
import { HistoryModal } from '~/pages/Dashboard/components/HistoryModal';

import { useStyles } from './styles';

interface IHistoryWidgetProps {
  title: string;
  data: Array<{ date: string; data: IHistoryData[] }> | null;
  getData: null | ((limit: number) => string | Promise<string>);
  sx?: SxProps<Theme> | undefined;
  link?: string;
  loading?: boolean;
}

export const HistoryWidget: FC<IHistoryWidgetProps> = (props) => {
  const { data, loading, sx, title, getData, link } = props;

  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    getData?.(10);
  }, []);

  useEffect(() => {
    if (open) getData?.(50);
  }, [open]);

  const handleLink = (item: IHistoryData) => {
    const filterSet = JSON.stringify({
      filterSet: [
        {
          name: 'id',
          value: [item.whiteboardId],
        },
      ],
      conjunction: 'and',
    });

    return link + '?filter=' + filterSet + '&shared=true';
  };

  return (
    <WidgetPaper className={classes.root} sx={{ ...sx }}>
      {loading ? (
        <CircularProgress className={classes.spinner} size={24} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
          }}>
          <Box className={classes.header}>
            <Typography lineHeight="1.25" variant="h6" noWrap>
              {title}
            </Typography>
          </Box>
          <Divider />
          {data && data.length > 0 ? (
            <Box
              sx={{
                height: 'calc(100% - 53px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              <Box sx={{ height: '100%', overflow: 'auto' }}>
                {data?.map((item) => (
                  <Box sx={{ paddingTop: '12px' }} key={item.date}>
                    <Typography sx={{ fontWeight: 700, marginLeft: '24px' }}>{item.date}</Typography>
                    <List className={classes.list}>
                      {item.data.map((historyItem, index) => (
                        <ListItem key={index}>
                          <HistoryItem data={historyItem} link={handleLink(historyItem)} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                ))}
              </Box>
              <Button variant="outlined" className={classes.moreBtn} onClick={() => setOpen(true)}>
                See more
              </Button>
            </Box>
          ) : (
            <Box className={classes.nodataBox}>
              <Typography color="text.disabled">No data</Typography>
            </Box>
          )}
        </Box>
      )}
      <HistoryModal open={open} onClose={() => setOpen(false)} title={title} link={link} />
    </WidgetPaper>
  );
};
