import { makeStyles } from '~/theme';

export const useStyles = makeStyles()(() => ({
  label: {
    position: 'relative',
    fontSize: '18px',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      fontSize: '14px',
      padding: '12px ',
    },
  },
  helperText: {
    marginLeft: 0,
  },
}));
