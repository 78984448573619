export const sliceName = 'admin';

export const getUsersActionType = `${sliceName}/getUsersWithUsernames`;
export const getUserRolesActionType = `${sliceName}/getUserRoles`;
export const getUserStatusesActionType = `${sliceName}/getUserStatuses`;
export const registerNewUserActionType = `${sliceName}/registerNewUser`;
export const editUserActionType = `${sliceName}/editUser`;
export const deleteUserActionType = `${sliceName}/deleteUser`;
export const getBullhornUsersActionType = `${sliceName}/getBullhornUsers`;
export const getServiceStatusActionType = `${sliceName}/getServiceStatus`;
export const registerUserCognitoActionType = `${sliceName}/registerUserCognito`;
