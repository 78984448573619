import { FC, useState } from 'react';
import { Box, Checkbox, Menu, MenuItem, Typography } from '@mui/material';

import { fieldNameToFilterTitle } from '~/utils/fieldNameToFilterTitle';

import { TFilter, TFilterData } from '../../types';
import { useStyles } from './styles';

interface IFilterMenuItemProps {
  field: string;
  data: { id: string; title: string }[];
  filters: TFilter[];
  addFilter: (filter: { title: string; field: keyof TFilterData; value: string }) => void;
  onClose: () => void;
}

export const FilterMenuItem: FC<IFilterMenuItemProps> = (props) => {
  const { field, data, addFilter, filters, onClose } = props;

  const { classes } = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
    onClose();
  };

  return (
    <Box>
      <MenuItem sx={{ minWidth: '150px' }} onClick={handleOpenMenu}>
        <Typography>{fieldNameToFilterTitle(field)}</Typography>
      </MenuItem>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {data.map((v) => (
          <MenuItem
            key={v.title + v.id}
            sx={{ minWidth: '150px' }}
            onClick={() =>
              addFilter({
                title: v.title,
                field: field as keyof TFilterData,
                value: v.id,
              })
            }>
            <Checkbox
              className={classes.checkbox}
              checked={!!filters.find((f) => (f.value + '').includes(v.id) && f.field === field)}
            />
            <Typography>{v.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
