import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';
import { IQueryParams } from '~/components/organisms/CommonTable';

import { getWhiteboardInsightsAction, getWhiteboardInsightsFiltersAction } from './actionTypes';

export const getWhiteboardInsights = createAsyncThunk(
  getWhiteboardInsightsAction,
  async (queryParams: IQueryParams) => {
    for (const key in queryParams) {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    }
    return API.get(apiName, Endpoints.whiteboardInsights, { queryStringParameters: queryParams });
  }
);

export const getWhiteboardInsightsFilters = createAsyncThunk(getWhiteboardInsightsFiltersAction, async () => {
  return API.get(apiName, Endpoints.whiteboardInsightsFilters, {});
});
