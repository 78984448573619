import React from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CacheProvider } from '@emotion/react';
import { Provider } from 'react-redux';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

import { amplifyConfig } from '~/constants';
import { store } from '~/store';
import { PendoIntegration } from '~/utils';

import './index.css';
import awsExport from './aws-exports';
import { muiCache, theme } from './theme';
import { App } from './app';

Amplify.configure({ ...awsExport, ...amplifyConfig });
dayjs.extend(utc);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <CacheProvider value={muiCache}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
      <PendoIntegration />
    </Provider>
  </React.StrictMode>
);
