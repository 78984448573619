import { linearProgressClasses, Theme } from '@mui/material';

import { makeStyles } from '~/theme';
import { ruleColors } from '~/constants/table';
import { visibilityDrawerWidth } from '~/components/organisms/CommonTable/components/VisibilityDrawer/styles';

export const useStyles = makeStyles<{ openVisibilityDrawer: boolean }>()(
  (theme: Theme, { openVisibilityDrawer }) => ({
    tableBox: {
      width: openVisibilityDrawer ? `calc(100% - ${visibilityDrawerWidth}px)` : '100%',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
      }),
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    table: {
      width: '100%',
      border: 'none',
      position: 'relative',
      '& .MuiDataGrid-sortIcon': {
        fill: '#fff',
      },
      '& .MuiDataGrid-withBorderColor': {
        borderColor: 'rgba(255, 255, 255, 0.2)',
      },
      '& .MuiDataGrid-columnHeaders': {
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
      },
      '& .MuiDataGrid-columnHeader': {
        borderBottom: '1px solid #8D8D8D',
        borderColor: '#8D8D8D',

        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'break-spaces',
          lineHeight: '16px',
          fontWeight: 400,
        },
      },
    },
    totals: {
      backgroundColor: theme.palette.background.paper,
    },
    red: {
      color: ruleColors[0].value + ' !important',
    },
    green: {
      color: ruleColors[1].value + ' !important',
      [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: ruleColors[1].value,
      },
    },
    yellow: {
      color: ruleColors[2].value + ' !important',
    },
    bullhornRow: {
      color: '#FF7F00',
    },
  })
);
