import { useState } from 'react';
import { Alert, Box, Snackbar, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { NonRootLayout } from '~/components/organisms/NonRootLayout';
import { ReactComponent as LogoIcon } from '~/assets/Logo.svg';
import { CustomField } from '~/components/molecules/CustomField';
import { Routes } from '~/constants';
import { useAppDispatch } from '~/store';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { getUserInfo, login, newPasswordRequired, updateLastLogin, userDataSelector } from '~/store/common';

import { useStyles } from './styles';

export const SignIn = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading } = useSelector(userDataSelector);

  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [newPasswordMode, setNewPasswordMode] = useState(false);

  const handleLogin = async () => {
    const loginRes = await dispatch(login({ email, password }));
    const groups = loginRes.payload.signInUserSession?.accessToken?.payload['cognito:groups'];

    if (loginRes.payload?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setNewPasswordMode(true);
    } else if ((loginRes as unknown as { error: { message: string } }).error) {
      setOpen(true);
      const errMsg =
        (loginRes as unknown as { error: { message: string } })?.error?.message || 'Unknown error';
      setErrorMsg(errMsg);
    } else {
      const userInfoRes = (await dispatch(getUserInfo())) as { payload: { status: { name: string } } };
      if (userInfoRes?.payload?.status?.name === 'inactive') {
        await Auth.signOut();
        console.log(userInfoRes);
        setOpen(true);
        setErrorMsg(' Invalid user please contact your system administrator');
      } else if ((userInfoRes as unknown as { error: { message: string } }).error && groups.includes('god')) {
        navigate(Routes.serviceMonitoring);
      } else {
        dispatch(updateLastLogin());
        navigate(Routes.root);
      }
    }
  };

  const handleNewPassword = async () => {
    const loginRes = await dispatch(newPasswordRequired({ email, password, newPassword }));

    if ((loginRes as unknown as { error: { message: string } }).error) {
      setOpen(true);
      const errMsg =
        (loginRes as unknown as { error: { message: string } })?.error?.message || 'Unknown error';
      setErrorMsg(errMsg);
    } else {
      const userInfoRes = (await dispatch(getUserInfo())) as { payload: { status: { name: string } } };
      if (userInfoRes?.payload?.status?.name === 'inactive') {
        await Auth.signOut();
        throw new Error('User is inactive');
      }

      dispatch(updateLastLogin());
      navigate(Routes.root);
    }
  };

  const handleSnackbarClose = () => {
    setOpen(false);
    setErrorMsg('');
  };

  return (
    <NonRootLayout>
      <Box className={classes.container}>
        <Box className={classes.formBox}>
          <LogoIcon />
          <Typography className={classes.title} color="text.primary">
            Log In
          </Typography>
          <Typography color="text.disabled">Welcome back! Sign into your account below.</Typography>
          <Box className={classes.actionBox}>
            <CustomField
              fullWidth
              title="Email"
              label="Email"
              onBlur={() => setError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))}
              onFocus={() => setError(false)}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={error}
              helperText={'Invalid email address'}
            />
            {newPasswordMode && (
              <Typography color="text.disabled">
                Please enter your new password and confirm it below.
              </Typography>
            )}
            {newPasswordMode ? (
              <CustomField
                fullWidth
                title="New Password"
                label="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="password"
              />
            ) : (
              <CustomField
                fullWidth
                title="Password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            )}
            <ButtonWithLoading
              loading={loading}
              disabled={error}
              fullWidth
              variant="contained"
              onClick={newPasswordMode ? handleNewPassword : handleLogin}>
              {newPasswordMode ? 'Confirm password' : 'Log In'}
            </ButtonWithLoading>
          </Box>
          <Typography align="center" color="text.disabled">
            Not yet a user?{' '}
            <Link className={classes.link} to={Routes.comingSoon}>
              Join our waitlist
            </Link>
          </Typography>
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={open}
          autoHideDuration={errorMsg.length * 60 > 1500 ? errorMsg.length * 60 : 1500}
          onClose={handleSnackbarClose}>
          <Alert severity="error">{errorMsg}</Alert>
        </Snackbar>
      </Box>
    </NonRootLayout>
  );
};
