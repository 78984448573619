import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '32px',
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
  },
  tableRoot: {
    maxWidth: '500px',
  },
  headerRow: {
    '& th': {
      fontWeight: 700,
      fontSize: '16px',
    },
  },
  tableRow: { '&:last-child td, &:last-child th': { border: 0 } },
  link: {
    color: theme.palette.text.primary,
  },
  noData: {
    padding: '16px 0 16px 220px',
  },
}));
