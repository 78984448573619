import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    width: '100%',
    flex: 1,
  },
  formBox: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    padding: ' 64px 45px',
    width: '510px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0 24px',
      padding: '64px 16px',
    },
  },
  title: {
    fontSize: '32px',
    margin: '16px 0 8px',
  },
  actionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    margin: '24px 0',
  },
  link: {
    color: '#4961C2',
    textDecoration: 'none',
  },
}));
