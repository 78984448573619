import React, { FC } from 'react';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import * as yup from 'yup';
import isEqual from 'lodash/isEqual';

import { TRule } from '~/components/organisms/TableToolbar';
import { CustomSelect } from '~/components/molecules/CustomSelect';
import { CustomField } from '~/components/molecules/CustomField';
import { ruleColors, ruleOperators } from '~/constants/table';

import { useStyles } from './styles';

const validationSchema = yup.object({
  column: yup.string().required('Column Name is required'),
  operator: yup.string().required('Conditional Logic is required'),
  referenceValue: yup
    .number()
    .typeError('Reference Value must be a number')
    .required('Reference Value is required')
    .integer('Reference Value must be an integer'),
  color: yup.string().required('Color is required'),
});

interface IRuleMenuProps {
  columns: GridColDef[];
  onRuleChange?: React.Dispatch<React.SetStateAction<TRule[]>>;
  menuAnchor: HTMLElement | null;
  onClose: () => void;
  rule?: TRule;
}

export const RuleMenu: FC<IRuleMenuProps> = (props) => {
  const { columns, onRuleChange, menuAnchor, onClose, rule } = props;

  const { classes } = useStyles();

  const formik = useFormik({
    initialValues: {
      column: rule ? rule.field : '',
      operator: rule ? rule?.operator?.value : '',
      referenceValue: rule ? rule.value : '',
      color: rule ? rule.color?.value : '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (rule) {
        onRuleChange?.((rules) =>
          rules.map((r) => {
            if (isEqual(r, rule)) {
              return {
                columnTitle: columns.find((column) => column.field === values.column)?.headerName,
                field: values.column,
                operator: ruleOperators.find((operator) => operator.value === values.operator),
                value: values.referenceValue,
                color: ruleColors.find((color) => color.value === values.color),
              };
            }
            return r;
          })
        );
      } else {
        onRuleChange?.((rules) => [
          ...rules,
          {
            columnTitle: columns.find((column) => column.field === values.column)?.headerName,
            field: values.column,
            operator: ruleOperators.find((operator) => operator.value === values.operator),
            value: values.referenceValue,
            color: ruleColors.find((color) => color.value === values.color),
          },
        ]);
      }
      handleClose();
    },
  });

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };
  return (
    <Menu
      anchorEl={menuAnchor}
      open={!!menuAnchor}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}>
      <Box className={classes.menuBox}>
        <IconButton sx={{ position: 'absolute', right: 24, top: 24, padding: 0 }} onClick={handleClose}>
          <CloseIcon sx={{ color: '#BDBDBD' }} />
        </IconButton>
        <Typography sx={{ fontSize: '16px', fontWeight: 600, marginBottom: '24px' }}>
          {rule ? 'Edit rule' : 'Add rule'}
        </Typography>
        <Box component="form" onSubmit={formik.handleSubmit}>
          <Box className={classes.row}>
            <Typography>When</Typography>
            <CustomSelect
              sx={{ width: '150px' }}
              displayEmpty
              value={formik.values.column}
              name="column"
              onChange={formik.handleChange}
              error={formik.touched.column && Boolean(formik.errors.column)}
              helperText={formik.touched.column && formik.errors.column}
              options={[
                ...columns.map((c) => (
                  <MenuItem key={c.field} value={c.field}>
                    {c.headerName}
                  </MenuItem>
                )),
                <MenuItem sx={{ display: 'none' }} key="none" value={''}>
                  Column Name
                </MenuItem>,
              ]}
            />
            <Typography>is</Typography>
            <CustomSelect
              sx={{ width: '160px' }}
              displayEmpty
              value={formik.values.operator}
              name="operator"
              onChange={formik.handleChange}
              error={formik.touched.operator && Boolean(formik.errors.operator)}
              helperText={formik.touched.operator && formik.errors.operator}
              options={[
                ...ruleOperators.map((o) => (
                  <MenuItem key={o.label} value={o.value}>
                    {o.label}
                  </MenuItem>
                )),
                <MenuItem sx={{ display: 'none' }} key="none" value={''}>
                  Conditional Logic
                </MenuItem>,
              ]}
            />
            <CustomField
              sx={{ width: '100px' }}
              placeholder={'Enter value'}
              name="referenceValue"
              value={formik.values.referenceValue}
              onChange={formik.handleChange}
              error={formik.touched.referenceValue && Boolean(formik.errors.referenceValue)}
              helperText={formik.touched.referenceValue && formik.errors.referenceValue}
            />
          </Box>
          <Box className={classes.row}>
            <Typography>Then value shall show</Typography>
            <CustomSelect
              displayEmpty
              value={formik.values.color}
              name="color"
              onChange={formik.handleChange}
              error={formik.touched.color && Boolean(formik.errors.color)}
              helperText={formik.touched.color && formik.errors.color}
              options={[
                ...ruleColors.map((c) => (
                  <MenuItem sx={{ color: c.value }} key={c.label} value={c.value}>
                    {c.label}
                  </MenuItem>
                )),
                <MenuItem sx={{ display: 'none' }} key="none" value={''}>
                  Color
                </MenuItem>,
              ]}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ marginRight: '10px' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              {rule ? 'Save' : 'Add'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Menu>
  );
};
