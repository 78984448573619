import { GridColDef, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Box, IconButton, LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import VideocamIcon from '@mui/icons-material/Videocam';
import PersonIcon from '@mui/icons-material/Person';
import EditIcon from '@mui/icons-material/Edit';

import { IWhiteboard, TStatus } from '~/store/whiteboard';
import BullhornLogo from '~/assets/bullhorn-logo.png';
import BullhornLogoWhite from '~/assets/bullhorn-logo-white.png';
import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';
import { ReactComponent as SmallInboardLogo } from '~/assets/small-inboard-logo.svg';
import { ReactComponent as SmallInboardLogoGrey } from '~/assets/small-inboard-logo-grey.svg';

const namesColumnWidth = 200;

export const columns: GridColDef<IWhiteboard>[] = [
  {
    field: 'bullhornJobOrderId',
    headerName: '',
    sortable: false,
    align: 'right',
    width: 45,
    renderCell: (params) => {
      const updatedAt = dayjs(params.row.updatedAt);
      const today = dayjs().startOf('day');

      if (params.row.bullhornJobOrderId) {
        return (
          <img
            src={updatedAt.isSame(today, 'day') ? BullhornLogo : BullhornLogoWhite}
            style={{ height: '20px' }}
          />
        );
      } else {
        return updatedAt.isSame(today, 'day') ? (
          <SmallInboardLogo style={{ width: '20px' }} />
        ) : (
          <SmallInboardLogoGrey style={{ width: '20px' }} />
        );
      }
    },
  },
  {
    field: 'rowNumber',
    headerName: '',
    sortable: false,
    align: 'center',
    width: 55,
  },
  {
    field: 'candidateName',
    headerName: 'Candidate',
    width: namesColumnWidth,
  },
  {
    field: 'recruiter',
    headerName: 'Candidate Owner',
    width: namesColumnWidth,
    renderCell: (params) => {
      return (
        <UsernameWithAvatar
          avatar={params.row.recruiter?.avatar || ''}
          firstName={params.row.recruiter?.firstName || ''}
          lastName={params.row.recruiter?.lastName || ''}
          color={params.row.recruiter?.color || ''}
        />
      );
    },
  },
  {
    field: 'company',
    headerName: 'Company',
    width: namesColumnWidth,
    valueFormatter: (params) => {
      return params.value.name || '';
    },
  },
  {
    field: 'companyOwner',
    headerName: 'Company Owner',
    width: namesColumnWidth,
    renderCell: (params) => {
      return (
        <UsernameWithAvatar
          avatar={params.row.companyOwner?.avatar || ''}
          firstName={params.row.companyOwner?.firstName || ''}
          lastName={params.row.companyOwner?.lastName || ''}
          color={params.row.companyOwner?.color || ''}
        />
      );
    },
  },
  {
    field: 'jobTitle',
    headerName: 'Job',
    width: 250,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 160,
    renderCell: (params) => {
      return params.row.status ? (
        <Typography
          sx={{
            backgroundColor: determineStatusColor(params.row.status?.name as TStatus),
            padding: '2px 8px',
            borderRadius: '4px',
            color: 'black',
          }}>
          {params.row.status?.name}
        </Typography>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'interviewProcess',
    headerName: 'Interview Process',
    sortable: false,
    width: 150,
    renderCell: (params) => {
      const { firstInterviewDate, secondInterviewDate, thirdInterviewDate, candidateName } = params.row;
      const interviews = [firstInterviewDate, secondInterviewDate, thirdInterviewDate];

      return interviews.map((interview, index) => {
        return interview ? (
          <CheckCircleIcon
            key={candidateName ? candidateName + index : index}
            sx={{
              marginRight: index !== 2 ? '11px' : '0px',
              color: hasInterviewTakenPlace(interview) ? '#6A7CE1' : '#8D8D8D',
            }}
          />
        ) : null;
      });
    },
  },
  {
    field: 'firstInterviewDate',
    headerName: 'First Interview',
    width: 150,
    renderCell: (params) => renderInterview(params, 'firstInterview'),
  },
  {
    field: 'secondInterviewDate',
    headerName: 'Second Interview',
    width: 150,
    renderCell: (params) => renderInterview(params, 'secondInterview'),
  },
  {
    field: 'thirdInterviewDate',
    headerName: 'Third Interview',
    width: 150,
    renderCell: (params) => renderInterview(params, 'thirdInterview'),
  },
  {
    field: 'potential',
    headerName: 'Potential',
    width: 150,
    renderCell: (params) => {
      return params.row.potential ? `$${params.row.potential.toLocaleString('en-US')}` : '-';
    },
  },
  {
    field: 'progress',
    headerName: '% to Close',
    width: 150,
    renderCell: (params) => {
      return params.row.progress ? (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ marginRight: '8px', width: '35px', color: 'inherit' }}>
            {params.row.progress}%
          </Typography>
          <LinearProgress
            sx={{
              width: '80px',
              height: '8px',
              borderRadius: '100px',
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: '100px',
              },
            }}
            variant="determinate"
            value={params.row.progress}
          />
        </Box>
      ) : (
        '-'
      );
    },
  },
  {
    field: 'byWhenDate',
    headerName: 'By When',
    width: 100,
    valueFormatter: (params) => {
      return params.value ? dayjs(params.value).format('MM/DD/YYYY') : '-';
    },
  },
  {
    field: 'edit',
    headerName: '',
    sortable: false,
    align: 'center',
    width: 50,
    renderCell: (...params) => {
      return (
        <IconButton>
          <EditIcon color="primary" />
        </IconButton>
      );
    },
  },
];

const determineStatusColor = (status: TStatus): string => {
  switch (status) {
    case 'Offer Rejected':
    case 'Client Rejected':
    case 'Rejected':
      return '#FF4444';
    case 'On Hold':
      return '#FFA500';
    case 'Placed':
      return '#04B400';
    case 'Closed Job':
      return '#FF0000';
    case 'No Longer Interested':
      return '#8D8D8D';
    case 'Backed out':
      return '#FF0000';
    case 'Interviewing':
      return '#00D1FF';
    case 'Offer':
      return '#FFB60A';
    default:
      return '#FFFFFF';
  }
};

const hasInterviewTakenPlace = (interviewDate: string | null): boolean => {
  return interviewDate ? dayjs().isAfter(interviewDate) : false;
};

const renderInterview = (
  params: GridRenderCellParams<IWhiteboard, any, any, GridTreeNodeWithRender>,
  number: 'firstInterview' | 'secondInterview' | 'thirdInterview'
) => {
  const date = params.row[(number + 'Date') as keyof IWhiteboard] as string | null;
  const type = params.row[(number + 'Type') as keyof IWhiteboard] as keyof typeof interviewTypeIcons;
  const hasInterviewOccurred = date && dayjs(date).isBefore(dayjs());
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& *': {
          color: hasInterviewOccurred ? 'primary.main' : '#8D8D8D',
        },
      }}>
      {date && type ? <Box>{interviewTypeIcons[type]}</Box> : <Box />}
      <Typography sx={{ marginLeft: date ? '10px' : '' }}>
        {date ? dayjs(date).format('MM/DD/YYYY') : '-'}
      </Typography>
    </Box>
  );
};

const allowedColumns = ['potential', 'progress'];

export const columnsWithRules = columns.filter((column) => allowedColumns.includes(column.field as string));

const interviewTypeIcons = {
  Phone: <LocalPhoneIcon />,
  Webinar: <VideocamIcon />,
  Inperson: <PersonIcon />,
};
