import React, { FC, useRef, useState } from 'react';
import { IconButton, List, ListItem, ListItemButton, Menu, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { GridColDef } from '@mui/x-data-grid';

import { RuleMenu } from '~/components/organisms/TableToolbar/components/RuleMenu';
import { TRule } from '~/components/organisms/TableToolbar';
import isEqual from 'lodash/isEqual';

interface IRuleItemProps {
  rule: TRule;
  columns: GridColDef[];
  onRuleChange?: React.Dispatch<React.SetStateAction<TRule[]>>;
}

export const RuleItem: FC<IRuleItemProps> = (props) => {
  const { rule, onRuleChange, columns } = props;

  const ruleMenuAnchorRef = useRef<HTMLLIElement>(null);

  const [smallMenuAnchor, setSmallMenuAnchor] = useState<HTMLElement | null>(null);
  const [ruleMenuAnchor, setRuleMenuAnchor] = useState<HTMLElement | null>(null);

  const handleDelete = () => {
    onRuleChange?.((prev) => prev.filter((r) => !isEqual(r, rule)));
  };

  const handleRuleMenuClose = () => {
    setRuleMenuAnchor(null);
    setSmallMenuAnchor(null);
  };
  const handleEdit = () => {
    setRuleMenuAnchor(ruleMenuAnchorRef.current);
    setSmallMenuAnchor(null);
  };

  return (
    <>
      <ListItem
        ref={ruleMenuAnchorRef}
        secondaryAction={
          <IconButton edge="end" onClick={(e) => setSmallMenuAnchor(e.currentTarget)}>
            <MoreVertIcon sx={{ color: 'text.primary' }} />
          </IconButton>
        }>
        <Typography
          noWrap>{`When ${rule.columnTitle} is ${rule.operator?.label} ${rule.value}, then value shall show in ${rule.color?.label}`}</Typography>
      </ListItem>
      <Menu open={!!smallMenuAnchor} anchorEl={smallMenuAnchor} onClose={() => setSmallMenuAnchor(null)}>
        <List dense sx={{ width: '100px' }}>
          <ListItemButton onClick={handleEdit}>Edit</ListItemButton>
          <ListItemButton onClick={handleDelete}>Delete</ListItemButton>
        </List>
      </Menu>
      <RuleMenu
        rule={rule}
        menuAnchor={ruleMenuAnchor}
        onClose={handleRuleMenuClose}
        columns={columns}
        onRuleChange={onRuleChange}
      />
    </>
  );
};
