import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';

import { commonReducer } from './common';
import { metricReducer } from './metric';
import { dashboardReducer } from './dashboard';
import { adminReducer } from './admin';
import { whiteboardReducer } from './whiteboard';
import { noteReducer } from './note';
import { whiteboardMetricsReducer } from './whiteboardMetrics';
import { logErrors } from './middlewares';
import { whiteboardInsightsReducer } from './whiteboardInsights/slice';

export const store = configureStore({
  reducer: {
    common: commonReducer,
    dashboard: dashboardReducer,
    whiteboard: whiteboardReducer,
    whiteboardMetrics: whiteboardMetricsReducer,
    whiteboardInsights: whiteboardInsightsReducer,
    metric: metricReducer,
    admin: adminReducer,
    note: noteReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logErrors),
  devTools: import.meta.env.V_DEV_ENV === 'true',
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
