import { GridColDef } from '@mui/x-data-grid';

import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';
import { IWhiteboardInsightsData } from '~/store/whiteboardInsights';

export const columns: GridColDef<IWhiteboardInsightsData>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: (params) => {
      return (
        <UsernameWithAvatar
          avatar={params.row.avatar || ''}
          name={params.row.name || ''}
          color={params.row.color || ''}
        />
      );
    },
  },
  {
    field: 'submissions',
    headerName: 'Submissions',
    width: 150,
    valueGetter: (params) => {
      return params.row.submissions;
    },
  },
  {
    field: 'offers',
    headerName: 'Offers',
    width: 150,
    valueGetter: (params) => {
      return params.row.offers;
    },
  },
  {
    field: 'placements',
    headerName: 'Placements',
    width: 150,
    valueGetter: (params) => {
      return params.row.placements;
    },
  },
  {
    field: 'jobs',
    headerName: 'Jobs',
    width: 150,
    valueGetter: (params) => {
      return params.row.jobs;
    },
  },
  {
    field: 'billings',
    headerName: 'Billings',
    width: 150,
    valueFormatter: (params) => {
      return `$${params.value.toLocaleString('en-US')}`;
    },
  },
  {
    field: 'averageBillings',
    headerName: 'Average billing size',
    width: 200,
    valueFormatter: (params) => {
      return params.value ? `$${params.value.toLocaleString('en-US')}` : '-';
    },
  },
  {
    field: 'submittalsPerOffer',
    headerName: 'Submittals per Offer',
    width: 200,
    valueFormatter: (params) => {
      return params.value ? params.value : '-';
    },
  },
  {
    field: 'offersPerPlacement',
    headerName: 'Offers per Placement',
    width: 200,
    valueFormatter: (params) => {
      return params.value ? params.value : '-';
    },
  },
  {
    field: 'submittalsPerPlacement',
    headerName: 'Submittals per Placement',
    width: 200,
    valueFormatter: (params) => {
      return params.value ? params.value : '-';
    },
  },
];

const allowedColumns = [
  'billings',
  'placements',
  'offers',
  'submissions',
  'submittalsPerPlacement',
  'submittalsPerOffer',
  'offersPerPlacement',
];

export const columnsWithRules = columns.filter((column) => allowedColumns.includes(column.field as string));
