import { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '~/store';
import {
  clearDashboard,
  getPipelineValue,
  getRevenue,
  getTopActivity,
  getTopCandidatesOut,
  getTopNewClients,
  getTopOffers,
  getTopPhoneScreens,
  getTopPlacements,
  getWhiteboardHistory,
  IHistoryData,
  IRevenueWidgetData,
  IWidgetData,
  pipelineValueSelector,
  revenueSelector,
  topActivitySelector,
  topCandidatesOutSelector,
  topNewClientsSelector,
  topOffersSelector,
  topPhoneScreensSelector,
  topPlacementsSelector,
  TPeriod,
  whiteboardHistoryWidgetSelector,
} from '~/store/dashboard';
import { getQueryStrForWidgetRedirect } from '~/utils';
import { Routes } from '~/constants';
import { filtersSelector, getWhiteboardFilters } from '~/store/whiteboard';
import { TPeriodId } from '~/pages/Dashboard/types';
import { IWhiteboardFilterConditionSet } from '~/components/organisms/TableToolbar/components/NewFiltersMenu';

import { WidgetWithList } from './components/WidgetWithList';
import { useStyles } from './styles';
import { HistoryWidget } from './components/HistoryWidget';

export const Dashboard = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { data: topPhoneScreens, loading: topPhoneScreensLoading } = useSelector(topPhoneScreensSelector);
  const { data: topPlacements, loading: topPlacementsLoading } = useSelector(topPlacementsSelector);
  const { data: topNewClients, loading: topNewClientsLoading } = useSelector(topNewClientsSelector);
  const { data: topActivity, loading: topActivityLoading } = useSelector(topActivitySelector);
  const { data: topOffers, loading: topOffersLoading } = useSelector(topOffersSelector);
  const { data: topCandidatesOut, loading: topCandidatesOutLoading } = useSelector(topCandidatesOutSelector);
  let { data: whiteboardFilters } = useSelector(filtersSelector);
  const { data: revenue, loading: revenueLoading } = useSelector(revenueSelector);
  const { data: pipelineValue, loading: pipelineValueLoading } = useSelector(pipelineValueSelector);
  const { data: history, loading: historyLoading } = useSelector(whiteboardHistoryWidgetSelector);

  useEffect(() => {
    if (whiteboardFilters) whiteboardFilters = null;
    dispatch(getWhiteboardFilters());
    return () => {
      dispatch(clearDashboard());
    };
  }, []);

  const handleTopPhoneScreensPeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    dispatch(getTopPhoneScreens(period));

    return getQueryStrForWidgetRedirect('phoneScreens', period);
  };

  const handleTopPlacementsPeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    dispatch(getTopPlacements(period));

    const filter = 'status:' + whiteboardFilters?.status.find((f) => f.title === 'Placed')?.id || '';

    return getQueryStrForWidgetRedirect('byWhenDate', period, filter);
  };

  const handleTopNewClientsPeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    dispatch(getTopNewClients(period));

    return getQueryStrForWidgetRedirect('newClient', period);
  };

  const handleTopActivityPeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    dispatch(getTopActivity(period));

    return getQueryStrForWidgetRedirect('touches', period);
  };

  const handleTopOffersPeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    dispatch(getTopOffers(period));

    const filter = 'status:' + whiteboardFilters?.status.find((f) => f.title === 'Offer')?.id || '';
    return getQueryStrForWidgetRedirect('byWhenDate', period, filter);
  };

  const handleTopRequitesByCandidateOutForInterview = async () => {
    await dispatch(getTopCandidatesOut());
  };

  const handleRevenuePeriodChange = async (period?: TPeriod) => {
    if (!period) return '';

    const res = (await dispatch(getRevenue(period))) as { payload: IRevenueWidgetData };

    const filterSet: IWhiteboardFilterConditionSet = {
      filterSet: [
        {
          name: 'id',
          value: res.payload?.whiteboardIds,
        },
      ],
      conjunction: 'and',
    };

    return 'filter=' + JSON.stringify(filterSet);
  };

  const handlePipelinePeriodChange = (period?: TPeriod) => {
    if (!period) return '';

    const filter = JSON.stringify({
      filterSet: [
        {
          name: 'status',
          value: whiteboardFilters?.status
            .filter((f) => f.title === 'Interviewing' || f.title === 'Offer')
            .map((s) => s.id),
        },
      ],
      conjunction: 'and',
    });

    dispatch(getPipelineValue(period));

    return getQueryStrForWidgetRedirect('byWhenDate', period, filter);
  };

  const handleHistoryDate = (limit: number) => {
    dispatch(getWhiteboardHistory({ limit }));
  };

  const widgets = [
    {
      title: 'Revenue',
      name: 'revenue',
      data: revenue?.revenue,
      getData: whiteboardFilters ? handleRevenuePeriodChange : null,
      loading: revenueLoading,
      gridRow: 'span 1',
      link: Routes.whiteboard,
    },
    {
      title: 'Pipeline',
      name: 'pipelineValue',
      data: pipelineValue,
      getData: whiteboardFilters ? handlePipelinePeriodChange : null,
      loading: pipelineValueLoading,
      gridRow: 'span 1',
      link: Routes.whiteboard,
      defaultPeriod: 'all_time',
    },
    {
      title: 'Whiteboard Stream',
      name: 'whiteboardStream1',
      getData: handleHistoryDate,
      data: history,
      loading: historyLoading,
      gridRow: 'span 3',
      link: Routes.whiteboard,
    },
    {
      title: 'Interviews',
      name: 'candidatesOut',
      data: topCandidatesOut,
      loading: !whiteboardFilters || topCandidatesOutLoading,
      getData: whiteboardFilters ? handleTopRequitesByCandidateOutForInterview : null,
      gridRow: 'span 1',
      turnOffPeriod: true,
      link: Routes.whiteboard + '?sort=byWhenDate&order=DESC&',
      titleLink: Routes.whiteboardMetrics + '?sort=interviews&order=DESC',
    },
    {
      title: 'Offers',
      name: 'offers',
      data: topOffers,
      loading: topOffersLoading,
      getData: whiteboardFilters ? handleTopOffersPeriodChange : null,
      link: Routes.whiteboard,
      titleLink: Routes.whiteboardMetrics + '?sort=offers&order=DESC',
      gridRow: 'span 1',
      defaultPeriod: 'this_week',
    },
    {
      title: 'Placements',
      name: 'placements',
      data: topPlacements,
      loading: topPlacementsLoading,
      getData: whiteboardFilters ? handleTopPlacementsPeriodChange : null,
      link: Routes.whiteboard,
      titleLink: Routes.whiteboardMetrics + '?sort=placements&order=DESC',
      gridRow: 'span 1',
      defaultPeriod: 'this_week',
    },
    // {
    //   title: 'Phone Screens',
    //   data: topPhoneScreens,
    //   loading: topPhoneScreensLoading,
    //   getData: handleTopPhoneScreensPeriodChange,
    //   gridRow: 'span 1',
    // },
    // {
    //   title: 'Activity',
    //   data: topActivity,
    //   loading: topActivityLoading,
    //   getData: handleTopActivityPeriodChange,
    //   gridRow: 'span 1',
    // },
    // {
    //   title: 'New Clients',
    //   data: topNewClients,
    //   loading: topNewClientsLoading,
    //   getData: handleTopNewClientsPeriodChange,
    //   gridRow: 'span 1',
    // },
  ];

  return (
    <Box className={classes.dashboardRoot}>
      <Typography sx={{ marginBottom: '16px' }} variant="h4">
        Dashboard
      </Typography>
      <Box className={classes.dashboardGrid}>
        {widgets.map((w, index) => {
          if (!w) return null;

          const IsSingleNumberWidget = w.title === 'Revenue' || w.title === 'Pipeline';
          return w.name === 'whiteboardStream1' ? (
            <HistoryWidget
              key={w.title + index}
              data={w.data as Array<{ date: string; data: IHistoryData[] }> | null}
              title={w.title}
              getData={w.getData as (limit: number) => string | Promise<string>}
              loading={w.loading}
              sx={{
                gridRow: w.gridRow,
              }}
              link={w.link}
            />
          ) : (
            <WidgetWithList
              key={w.title + index}
              sx={{
                gridRow: w.gridRow,
              }}
              title={w.title}
              name={w.name}
              data={w.data as IWidgetData[] | number | null}
              getData={
                IsSingleNumberWidget && !whiteboardFilters
                  ? null
                  : (w.getData as (period?: TPeriod) => string | Promise<string>)
              }
              loading={w.loading || (IsSingleNumberWidget && !whiteboardFilters)}
              turnOffPeriod={w.turnOffPeriod}
              link={w.link}
              titleLink={w.titleLink}
              defaultPeriod={w.defaultPeriod as TPeriodId}
            />
          );
        })}
      </Box>
    </Box>
  );
};
