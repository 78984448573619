import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { IUserWhiteboardMetric, IWhiteboardMetricsFilters } from './types';
import { sliceName } from './actionTypes';
import { getWhiteboardMetrics, getWhiteboardMetricsFilters } from './thunks';

export interface WhiteboardMetricsState {
  whiteboardMetrics: {
    data: IUserWhiteboardMetric[] | null;
    page: number | null;
    limit: number | null;
    total: number | null;
    loading: boolean;
  };
  filters: {
    data: IWhiteboardMetricsFilters | null;
    loading: boolean;
  };
}

const initialState: WhiteboardMetricsState = {
  whiteboardMetrics: {
    data: null,
    loading: false,
    page: null,
    total: null,
    limit: null,
  },
  filters: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWhiteboardMetrics.pending, (state) => {
      state.whiteboardMetrics.loading = true;
    });
    builder.addCase(getWhiteboardMetrics.fulfilled, (state, action) => {
      state.whiteboardMetrics.loading = false;
      state.whiteboardMetrics.data = action.payload.data;
      state.whiteboardMetrics.page = action.payload.page;
      state.whiteboardMetrics.total = action.payload.total;
      state.whiteboardMetrics.limit = action.payload.limit;
    });
    builder.addCase(getWhiteboardMetrics.rejected, (state) => {
      state.whiteboardMetrics.loading = false;
    });
    builder.addCase(getWhiteboardMetricsFilters.pending, (state) => {
      state.filters.loading = true;
    });
    builder.addCase(getWhiteboardMetricsFilters.fulfilled, (state, action) => {
      state.filters.loading = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getWhiteboardMetricsFilters.rejected, (state) => {
      state.filters.loading = false;
    });
  },
});

export const whiteboardMetricsSelector = (state: RootState) => state.whiteboardMetrics.whiteboardMetrics;
export const whiteboardMetricsFiltersSelector = (state: RootState) => state.whiteboardMetrics.filters;

export const { clear } = slice.actions;

export const whiteboardMetricsReducer = slice.reducer;
