import { useState } from 'react';
import { Alert, IconButton, Snackbar } from '@mui/material';
import IosShareIcon from '@mui/icons-material/IosShare';

export const ShareViewBtn = () => {
  const [open, setOpen] = useState(false);

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(location.href + '&shared=true');
      setOpen(true);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <IconButton sx={{ color: 'text.primary' }} onClick={handleShare}>
        <IosShareIcon />
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={1500}
        onClose={() => setOpen(false)}>
        <Alert>Link added to clipboard</Alert>
      </Snackbar>
    </>
  );
};
