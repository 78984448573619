import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { sliceName } from './actionTypes';
import {
  getPipelineValue,
  getRevenue,
  getTopActivity,
  getTopCandidatesOut,
  getTopNewClients,
  getTopOffers,
  getTopPhoneScreens,
  getTopPlacements,
  getWhiteboardHistory,
} from './thunks';
import { IHistoryData, IRevenueWidgetData, IWidgetData } from './types';
import dayjs from 'dayjs';

export interface DashboardState {
  topPhoneScreens: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  topPlacements: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  topNewClients: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  topActivity: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  topOffers: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  topCandidatesOut: {
    data: IWidgetData[] | null;
    loading: boolean;
  };
  revenue: {
    data: IRevenueWidgetData | null;
    loading: boolean;
  };
  pipelineValue: {
    data: number | null;
    loading: boolean;
  };
  whiteboardHistory: {
    data: Array<{ date: string; data: IHistoryData[] }> | null;
    loading: boolean;
  };
}

const initialState: DashboardState = {
  topPhoneScreens: {
    data: null,
    loading: false,
  },
  topPlacements: {
    data: null,
    loading: false,
  },
  topNewClients: {
    data: null,
    loading: false,
  },
  topActivity: {
    data: null,
    loading: false,
  },
  topOffers: {
    data: null,
    loading: false,
  },
  topCandidatesOut: {
    data: null,
    loading: false,
  },
  revenue: {
    data: null,
    loading: false,
  },
  pipelineValue: {
    data: null,
    loading: false,
  },
  whiteboardHistory: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getTopPhoneScreens.pending, (state) => {
      state.topPhoneScreens.loading = true;
    });
    builder.addCase(getTopPhoneScreens.fulfilled, (state, action) => {
      state.topPhoneScreens.data = action.payload;
      state.topPhoneScreens.loading = false;
    });
    builder.addCase(getTopPhoneScreens.rejected, (state) => {
      state.topPhoneScreens.loading = false;
    });
    builder.addCase(getTopPlacements.pending, (state) => {
      state.topPlacements.loading = true;
    });
    builder.addCase(getTopPlacements.fulfilled, (state, action) => {
      state.topPlacements.data = action.payload.data;
      state.topPlacements.loading = false;
    });
    builder.addCase(getTopPlacements.rejected, (state) => {
      state.topPlacements.loading = false;
    });
    builder.addCase(getTopNewClients.pending, (state) => {
      state.topNewClients.loading = true;
    });
    builder.addCase(getTopNewClients.fulfilled, (state, action) => {
      state.topNewClients.data = action.payload;
      state.topNewClients.loading = false;
    });
    builder.addCase(getTopNewClients.rejected, (state) => {
      state.topNewClients.loading = false;
    });
    builder.addCase(getTopActivity.pending, (state) => {
      state.topActivity.loading = true;
    });
    builder.addCase(getTopActivity.fulfilled, (state, action) => {
      state.topActivity.data = action.payload;
      state.topActivity.loading = false;
    });
    builder.addCase(getTopActivity.rejected, (state) => {
      state.topActivity.loading = false;
    });
    builder.addCase(getTopOffers.pending, (state) => {
      state.topOffers.loading = true;
    });
    builder.addCase(getTopOffers.fulfilled, (state, action) => {
      state.topOffers.data = action.payload.data;
      state.topOffers.loading = false;
    });
    builder.addCase(getTopOffers.rejected, (state) => {
      state.topOffers.loading = false;
    });
    builder.addCase(getTopCandidatesOut.pending, (state) => {
      state.topCandidatesOut.loading = true;
    });
    builder.addCase(getTopCandidatesOut.fulfilled, (state, action) => {
      state.topCandidatesOut.data = action.payload.data;
      state.topCandidatesOut.loading = false;
    });
    builder.addCase(getTopCandidatesOut.rejected, (state) => {
      state.topCandidatesOut.loading = false;
    });
    builder.addCase(getRevenue.pending, (state) => {
      state.revenue.loading = true;
    });
    builder.addCase(getRevenue.fulfilled, (state, action) => {
      state.revenue.data = action.payload;
      state.revenue.loading = false;
    });
    builder.addCase(getRevenue.rejected, (state) => {
      state.revenue.loading = false;
    });
    builder.addCase(getPipelineValue.pending, (state) => {
      state.pipelineValue.loading = true;
    });
    builder.addCase(getPipelineValue.fulfilled, (state, action) => {
      state.pipelineValue.data = action.payload;
      state.pipelineValue.loading = false;
    });
    builder.addCase(getPipelineValue.rejected, (state) => {
      state.pipelineValue.loading = false;
    });
    builder.addCase(getWhiteboardHistory.pending, (state) => {
      state.whiteboardHistory.loading = true;
    });
    builder.addCase(getWhiteboardHistory.fulfilled, (state, action) => {
      const map = action.payload.data.reduce((acc: Map<string, IHistoryData[]>, item: IHistoryData) => {
        const date = dayjs(item.createdAt).format('MM/DD/YYYY');
        if (acc.has(date)) {
          acc.set(date, [...(acc.get(date) as IHistoryData[]), item]);
        } else {
          acc.set(date, [item]);
        }
        return acc;
      }, new Map<string, IHistoryData[]>());

      const arr = [] as Array<{ date: string; data: IHistoryData[] }>;
      map.forEach((value: IHistoryData[], key: string) => {
        arr.push({ date: key, data: value });
      });
      state.whiteboardHistory.data = arr;
      state.whiteboardHistory.loading = false;
    });
    builder.addCase(getWhiteboardHistory.rejected, (state) => {
      state.whiteboardHistory.loading = false;
    });
  },
});

export const topPhoneScreensSelector = (state: RootState) => state.dashboard.topPhoneScreens;
export const topPlacementsSelector = (state: RootState) => state.dashboard.topPlacements;
export const topNewClientsSelector = (state: RootState) => state.dashboard.topNewClients;
export const topActivitySelector = (state: RootState) => state.dashboard.topActivity;
export const topOffersSelector = (state: RootState) => state.dashboard.topOffers;
export const topCandidatesOutSelector = (state: RootState) => state.dashboard.topCandidatesOut;
export const revenueSelector = (state: RootState) => state.dashboard.revenue;
export const pipelineValueSelector = (state: RootState) => state.dashboard.pipelineValue;
export const whiteboardHistorySelector = (state: RootState) => state.dashboard.whiteboardHistory;
export const whiteboardHistoryWidgetSelector = createSelector(whiteboardHistorySelector, (data) => {
  let limit = 10;
  return {
    ...data,
    data:
      data.data?.reduce((acc, item) => {
        if (limit === 0) return acc;

        if (item.data.length <= limit) {
          acc.push(item);
          limit -= item.data.length;
        } else {
          acc.push({ ...item, data: item.data.slice(0, limit) });
          limit = 0;
        }
        return acc;
      }, [] as Array<{ date: string; data: IHistoryData[] }>) || null,
  };
});

export const { clearDashboard } = slice.actions;

export const dashboardReducer = slice.reducer;
