import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { sliceName } from './actionTypes';
import { IMetric, IMetricFilters, ITotals } from './types';
import { getMetricFilters, getMetrics } from './thunks';
import { formatTotals } from './formaters';

export interface MetricState {
  metrics: {
    data: IMetric[] | null;
    page: number | null;
    limit: number | null;
    loading: boolean;
    totals: ITotals | null;
  };
  filters: {
    data: IMetricFilters | null;
    loading: boolean;
  };
}

const initialState: MetricState = {
  metrics: {
    data: null,
    loading: false,
    page: null,
    limit: null,
    totals: null,
  },
  filters: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearMetrics: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMetrics.fulfilled, (state, action) => {
      state.metrics = action.payload;
      state.metrics.totals = formatTotals(action.payload.data);
      state.metrics.loading = false;
    });
    builder.addCase(getMetrics.pending, (state) => {
      state.metrics.loading = true;
      state.metrics.data = null;
    });
    builder.addCase(getMetrics.rejected, (state) => {
      state.metrics.loading = false;
    });
    builder.addCase(getMetricFilters.pending, (state) => {
      state.filters.loading = true;
      state.filters.data = null;
    });
    builder.addCase(getMetricFilters.fulfilled, (state, action) => {
      state.filters.data = action.payload;
      state.filters.loading = false;
    });
    builder.addCase(getMetricFilters.rejected, (state) => {
      state.filters.loading = false;
    });
  },
});

export const metricSelector = (state: RootState) => state.metric.metrics;
export const metricFiltersSelector = (state: RootState) => state.metric.filters;

export const { clearMetrics } = slice.actions;

export const metricReducer = slice.reducer;
