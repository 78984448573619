import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';
import { TGodUserForm, TNewUserForm } from '~/pages/UserManagement/types';
import { RootState } from '~/store';
import { updateAvatar } from '~/store/common';
import { IQueryParams } from '~/components/organisms/CommonTable';

import { IEditUserDto, IRegisterUserCognitoDto, IRole, IServiceData, IUser } from './types';
import {
  deleteUserActionType,
  editUserActionType,
  getUserRolesActionType,
  getUsersActionType,
  getUserStatusesActionType,
  registerNewUserActionType,
  getBullhornUsersActionType,
  getServiceStatusActionType,
  registerUserCognitoActionType,
} from './actionTypes';

export const getUsers = createAsyncThunk<IUser[], IQueryParams | undefined>(
  getUsersActionType,
  (queryParams) => {
    return API.get(apiName, Endpoints.users, {
      queryStringParameters: queryParams,
    });
  }
);

export const getUserRoles = createAsyncThunk<IRole[]>(getUserRolesActionType, () => {
  return API.get(apiName, Endpoints.userRoles, {});
});

export const getUserStatuses = createAsyncThunk<IRole[]>(getUserStatusesActionType, () => {
  return API.get(apiName, Endpoints.userStatuses, {});
});

export const registerNewUser = createAsyncThunk<void, TNewUserForm, { state: RootState }>(
  registerNewUserActionType,
  async (payload, { getState }) => {
    const { userStatuses } = getState().admin;
    const active = userStatuses.data?.find((status) => status.name.toLowerCase() === 'active')?.id;
    const inactive = userStatuses.data?.find((status) => status.name.toLowerCase() === 'inactive')?.id;
    const [firstName, lastName] = payload.name.split(' ');

    const body: Record<string, unknown> = {
      firstName,
      lastName,
      email: payload.email,
      roleId: payload.role,
      statusId: payload.activateUser ? active : inactive,
    };

    if (payload.bullhornId) {
      body.bullhornId = payload.bullhornId;
    }

    return API.post(apiName, Endpoints.userRegister, { body });
  }
);

export const editUser = createAsyncThunk<void, IEditUserDto, { state: RootState }>(
  editUserActionType,
  async (payload, { getState }) => {
    const { data, id } = payload;
    const isGod = payload.role === 'god';

    const { userStatuses } = getState().admin;
    const active = userStatuses.data?.find((status) => status.name.toLowerCase() === 'active')?.id;
    const inactive = userStatuses.data?.find((status) => status.name.toLowerCase() === 'inactive')?.id;
    const [firstName, lastName] = isGod
      ? [(data as TGodUserForm).firstName, (data as TGodUserForm).lastName]
      : (data as TNewUserForm).name.split(' ');

    const body = {
      firstName,
      lastName,
      email: data.email,
      roleId: data.role,
      statusId: data.activateUser ? active : inactive,
    } as Record<string, unknown>;

    if (isGod) {
      const { avatar, color, newPassword, bullhornId } = data as TGodUserForm;

      if (newPassword) {
        body.newPassword = newPassword;
      }

      if (color) body.color = color;
      if (avatar) {
        const { users } = getState().admin;
        const user = users.data?.find((user) => user.id === id);

        if (user) {
          const file = await updateAvatar(user.avatar, avatar, user.sub || '');
          if (file) {
            body.avatarFileKey = file.key;
          }
        }
      }
      if (!isNaN(bullhornId as number)) body.bullhornId = bullhornId;
    }

    return API.patch(apiName, Endpoints.users + `/${id}`, { body });
  }
);

export const deleteUser = createAsyncThunk<void, string>(deleteUserActionType, async (id) => {
  return API.del(apiName, Endpoints.users + `/${id}`, {});
});

export const getBullhornUsers = createAsyncThunk(getBullhornUsersActionType, async () => {
  return API.get(apiName, Endpoints.bullhornUsers, {});
});

export const getServiceData = createAsyncThunk<IServiceData[]>(getServiceStatusActionType, async () => {
  const data: IServiceData[] = [];

  let devEnv;
  try {
    devEnv = await fetch('https://us5xcnwypc.execute-api.us-west-1.amazonaws.com/dev/monitoring');
  } catch (e) {
    devEnv = { status: 500 };
  }
  let prodEnv;
  try {
    prodEnv = await fetch('https://api.in-board.io/monitoring');
  } catch (e) {
    prodEnv = { status: 500 };
  }

  data.push({
    name: 'Dev environment',
    value: devEnv.status === 200 ? 'OK' : 'ERROR',
  });

  data.push({
    name: 'Prod environment',
    value: prodEnv.status === 200 ? 'OK' : 'ERROR',
  });

  return data;
});

export const registerUserCognito = createAsyncThunk<
  { sub: string } & IRegisterUserCognitoDto,
  IRegisterUserCognitoDto
>(registerUserCognitoActionType, async (payload) => {
  const res = await API.post(apiName, Endpoints.userRegisterCognito, { body: payload });

  return { sub: res, ...payload };
});
