import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  selectRoot: {
    marginLeft: '5px',
    '& .MuiSelect-icon.MuiSelect-iconOutlined': {
      right: '-5px',
      fill: '#fff',
    },
    '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
      padding: '0 22px 0 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0 !important',
    },
  },
}));
