import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Routes } from '~/constants';
import { useAppDispatch } from '~/store';
import { getServiceData, serviceStatusSelector } from '~/store/admin';

import { useStyles } from './styles';

export const ServiceMonitoring = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { data, loading } = useSelector(serviceStatusSelector);

  useEffect(() => {
    const checkUser = async () => {
      let currentUser;
      try {
        currentUser = await Auth.currentAuthenticatedUser();
      } catch (e) {
        navigate(Routes.signIn);
      }

      const groups = currentUser.signInUserSession.accessToken.payload['cognito:groups'];

      if (!groups.includes('god')) {
        navigate(Routes.dashboard);
      }

      dispatch(getServiceData());
    };

    checkUser();
  }, []);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>Service Monitoring</Typography>
      {loading ? (
        <LinearProgress sx={{ width: '500px' }} />
      ) : (
        <TableContainer className={classes.tableRoot} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow className={classes.headerRow}>
                <TableCell>Metric</TableCell>
                <TableCell align="right">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length ? (
                data.map((row) => (
                  <TableRow key={row.name} className={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))
              ) : (
                <Typography className={classes.noData}>No data</Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Link
        className={classes.link}
        to={'https://us-west-1.console.aws.amazon.com/cloudwatch/home?region=us-west-1#home:dashboards/EC2'}
        target="_blank">
        Check EC2 metrics
      </Link>
      <Button variant="contained" sx={{ width: '250px' }} onClick={() => navigate(Routes.dashboard)}>
        Back to Dashboard
      </Button>
    </Box>
  );
};
