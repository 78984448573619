import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { userDataSelector } from '~/store/common';
import { Routes } from '~/constants';
import { useAppDispatch } from '~/store';
import {
  bullhornUsersSelector,
  clearUsers,
  getBullhornUsers,
  getUserRoles,
  getUsers,
  getUserStatuses,
  usersSelector,
} from '~/store/admin';
import { CommonTable, IQueryParams } from '~/components/organisms/CommonTable';

import { useStyles } from './styles';
import { columns } from './columns';
import { AdminUserDrawer } from './components/AdminUserDrawer';
import { GodUserDrawer } from './components/GodUserDrawer';
import { TUserDrawerState } from './types';

export const UserManagement = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const { data } = useSelector(userDataSelector);
  const { data: users, loading } = useSelector(usersSelector);
  const { data: bullhornUsers, loading: bullhornUsersLoading } = useSelector(bullhornUsersSelector);

  const [userDrawer, setUserDrawer] = useState<TUserDrawerState>({
    open: false,
    type: 'add',
    data: null,
  });

  useEffect(() => {
    if (!['admin', 'god'].includes(data?.role || '')) {
      navigate(Routes.dashboard);
    }

    dispatch(getUserRoles());
    dispatch(getUserStatuses());

    if (data?.role === 'god') {
      dispatch(getBullhornUsers());
    }
  }, []);

  const handleFetchData = useCallback(async (queryParams: IQueryParams) => {
    await dispatch(getUsers(queryParams));
  }, []);

  const handleCleanUp = useCallback(() => {
    dispatch(clearUsers());
  }, []);

  const handleCloseDrawer = () => {
    setUserDrawer({
      open: false,
      type: 'add',
      data: null,
    });
  };

  const isGod = data?.role === 'god';

  return (
    <Box className={classes.createUserRoot}>
      <Typography variant="h4" className={classes.title}>
        Users
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingRight: ' 8px' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            setUserDrawer({
              open: true,
              type: 'add',
              data: null,
            })
          }>
          Add user
        </Button>
      </Box>
      <CommonTable
        kind="user-management"
        columnsDef={columns}
        data={users ? users : []}
        loading={loading || users === null}
        fetchData={handleFetchData}
        cleanUp={handleCleanUp}
        columnsWithRules={[]}
        filtersData={{}}
        fetchFiltersData={() => new Promise((resolve) => resolve())}
        onRowClick={(params) => setUserDrawer({ open: true, type: 'edit', data: params.row })}
        hideFooterPagination
        hideFooter
        hideFilterToolbar
        hideViewTabs
        hideReportBtn
      />
      {isGod && userDrawer.type === 'edit' ? (
        <GodUserDrawer
          userDrawerState={userDrawer}
          onClose={handleCloseDrawer}
          bullhornUsers={bullhornUsers}
          bullhornUsersLoading={bullhornUsersLoading}
        />
      ) : (
        <AdminUserDrawer
          userDrawerState={userDrawer}
          onClose={handleCloseDrawer}
          bullhornUsers={bullhornUsers}
          bullhornUsersLoading={bullhornUsersLoading}
        />
      )}
    </Box>
  );
};
