import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import dayjs from 'dayjs';
import PersonIcon from '@mui/icons-material/Person';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { GridCellParams } from '@mui/x-data-grid';

import { useAppDispatch } from '~/store';
import {
  clear,
  getWhiteboardInsights,
  getWhiteboardInsightsFilters,
  IWhiteboardInsightsData,
  whiteboardInsightsFiltersSelector,
  whiteboardInsightsSelector,
} from '~/store/whiteboardInsights';
import { CommonTable, IQueryParams } from '~/components/organisms/CommonTable';
import { TableWidget } from '~/components/organisms/TableWidget';
import { formatWidgetValue } from '~/pages/Whiteboard';
import { Routes } from '~/constants';

import { useStyles } from './styles';
import { columns, columnsWithRules } from './columns';

export const WhiteboardInsights = () => {
  const dispatch = useAppDispatch();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { data, loading } = useSelector(whiteboardInsightsSelector);
  const { data: filtersData } = useSelector(whiteboardInsightsFiltersSelector);

  const [avgBilling, setAvgBilling] = useState<string | null>(null);
  const [avgSubmittalsPerOffer, setAvgSubmittalsPerOffer] = useState<string | null>(null);
  const [avgSubmittalsPerPlacement, setAvgSubmittalsPerPlacement] = useState<string | null>(null);
  const [avgOffersPerPlacement, setAvgOffersPerPlacement] = useState<string | null>(null);

  useEffect(() => {
    if (data) {
      const avgBilling =
        data.reduce((acc, item) => acc + item.billings, 0) /
        data.reduce((acc, item) => (item.billings ? ++acc : acc), 0);
      setAvgBilling(avgBilling.toFixed(2));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const avgSubmittalsPerOffer =
        data.reduce((acc, item) => acc + (item.submittalsPerOffer || 0), 0) /
        data.reduce((acc, item) => (item.submittalsPerOffer ? ++acc : acc), 0);
      setAvgSubmittalsPerOffer(isNaN(avgSubmittalsPerOffer) ? '-' : avgSubmittalsPerOffer.toFixed(2));
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const avgSubmittalsPerPlacement =
        data.reduce((acc, item) => acc + (item.submittalsPerPlacement || 0), 0) /
        data.reduce((acc, item) => (item.submittalsPerPlacement ? ++acc : acc), 0);
      setAvgSubmittalsPerPlacement(
        isNaN(avgSubmittalsPerPlacement) ? '-' : avgSubmittalsPerPlacement.toFixed(2)
      );
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const avgOffersPerPlacement =
        data.reduce((acc, item) => acc + (item.offersPerPlacement || 0), 0) /
        data.reduce((acc, item) => (item.offersPerPlacement ? ++acc : acc), 0);
      setAvgOffersPerPlacement(isNaN(avgOffersPerPlacement) ? '-' : avgOffersPerPlacement.toFixed(2));
    }
  }, [data]);

  const handleFetchData = async (queryParams: IQueryParams) => {
    await dispatch(getWhiteboardInsights(queryParams));
  };

  const handleFetchFilters = async () => {
    await dispatch(getWhiteboardInsightsFilters());
  };

  const handleCleanUp = () => {
    dispatch(clear());
  };

  const defaultDateRange = useMemo(() => {
    return {
      from: dayjs('2023-05-11').utc(true).format(),
      to: dayjs().utc(true).format(),
    };
  }, []);

  const handleCellClick = (params: GridCellParams<IWhiteboardInsightsData>) => {
    const allowedFields = ['submissions', 'offers', 'placements', 'jobs', 'billings'];
    if (!allowedFields.includes(params.field as string)) return;

    const ids = (params.row[(params.field + 'Ids') as keyof IWhiteboardInsightsData] as string[]) || [];

    if (!ids.length) return;

    const filterSet = JSON.stringify({
      filterSet: [
        {
          name: 'id',
          value: ids,
        },
      ],
      conjunction: 'and',
    });

    navigate(Routes.whiteboard + `?filter=${filterSet}&` + '&shared');
  };

  return (
    <Box className={classes.root}>
      <Accordion className={classes.accordion} defaultExpanded>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: '#fff' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header">
          <Typography sx={{ margin: '32px 0 8px 32px' }} variant="h4">
            Whiteboard Insights
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableWidget
            title="Average billing size"
            value={formatWidgetValue(+(avgBilling || 0), true)}
            icon={<PersonIcon fontSize="large" />}
          />
          <TableWidget
            title="Average submittals per offer"
            value={avgSubmittalsPerOffer}
            icon={<PersonIcon fontSize="large" />}
          />
          <TableWidget
            title="Average submittals per placement"
            value={avgSubmittalsPerPlacement}
            icon={<PersonIcon fontSize="large" />}
          />
          <TableWidget
            title="Average offers per placement"
            value={avgOffersPerPlacement}
            icon={<PersonIcon fontSize="large" />}
          />
        </AccordionDetails>
      </Accordion>
      <CommonTable
        kind="whiteboard-insights"
        columnsDef={columns}
        data={data}
        loading={loading}
        total={0}
        fetchData={handleFetchData}
        fetchFiltersData={handleFetchFilters}
        filtersData={filtersData}
        cleanUp={handleCleanUp}
        columnsWithRules={columnsWithRules}
        hideFooterPagination
        hideFooter
        defaultDateRange={defaultDateRange}
        hideReportBtn
        sortingMode={'client'}
        onCellClick={handleCellClick}
      />
    </Box>
  );
};
