import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { sliceName } from './actionTypes';
import { getWhiteboardInsights, getWhiteboardInsightsFilters } from './thunks';
import { IWhiteboardInsightsData, IWhiteboardInsightsFilters } from './types';

export interface IWhiteboardInsightsState {
  whiteboardInsights: {
    data: IWhiteboardInsightsData[] | null;
    loading: boolean;
  };
  filters: {
    data: IWhiteboardInsightsFilters | null;
    loading: boolean;
  };
}

const initialState: IWhiteboardInsightsState = {
  whiteboardInsights: {
    data: null,
    loading: false,
  },
  filters: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getWhiteboardInsights.pending, (state) => {
      state.whiteboardInsights.loading = true;
    });
    builder.addCase(getWhiteboardInsights.fulfilled, (state, action) => {
      state.whiteboardInsights.loading = false;
      state.whiteboardInsights.data = action.payload.data;
    });
    builder.addCase(getWhiteboardInsights.rejected, (state) => {
      state.whiteboardInsights.loading = false;
    });
    builder.addCase(getWhiteboardInsightsFilters.pending, (state) => {
      state.filters.loading = true;
    });
    builder.addCase(getWhiteboardInsightsFilters.fulfilled, (state, action) => {
      state.filters.loading = false;
      state.filters.data = action.payload;
    });
    builder.addCase(getWhiteboardInsightsFilters.rejected, (state) => {
      state.filters.loading = false;
    });
  },
});

export const whiteboardInsightsSelector = (state: RootState) => state.whiteboardInsights.whiteboardInsights;
export const whiteboardInsightsFiltersSelector = (state: RootState) => state.whiteboardInsights.filters;

export const { clear } = slice.actions;

export const whiteboardInsightsReducer = slice.reducer;
