import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  whiteboardRoot: {
    flex: 1,
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  accordion: {
    backgroundColor: 'rgba(0,0,0,0)',
    boxShadow: 'none',
    '& .MuiButtonBase-root': {
      padding: 0,
    },
    '& .MuiAccordionSummary-root': {
      justifyContent: 'flex-start',
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      flexGrow: 0,
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      margin: '20px 0 0 10px',
    },
    '& .MuiAccordionDetails-root': {
      display: 'flex',
      gap: 32,
      padding: '12px 32px 8px 32px',
      overflowX: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
}));
