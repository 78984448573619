import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

const actionBoxHeight = 92;
const drawerWidth = 460;
export const useStyles = makeStyles()((theme: Theme) => ({
  drawer: {
    zIndex: theme.zIndex.drawer + 1,
    '& .MuiDrawer-paper': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      top: 'auto',
      bottom: 0,
      width: drawerWidth + 'px',
      [theme.breakpoints.down('sm')]: {
        width: '85%',
        minWidth: 'auto',
      },
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '36px',
    margin: '24px',
  },
  errorMessage: {
    color: theme.palette.error.main,
    margin: '0 0 12px 24px',
  },
  actionBox: {
    height: actionBoxHeight + 'px',
    width: drawerWidth + 'px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid rgba(255, 255, 255, 0.2)',
    padding: '24px',
    position: 'fixed',
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
  },
  formBox: {
    height: `calc(100%)`,
    display: 'flex',
  },
  textArea: {
    backgroundColor: '#f2f4f6',
    margin: '0 24px 24px 24px',
    width: 'calc(100% - 48px)',
    flex: 1,
    resize: 'vertical',
    outline: 'none !important',
    padding: '5px',
  },
}));
