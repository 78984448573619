import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    '& a': {
      textDecoration: 'none',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 24px 12px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 16px 12px',
    },
  },
  spinner: {
    color: '#fff',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
  nodataBox: {
    width: '100%',
    height: '100%',
    flex: 1,
    display: 'grid',
    placeItems: 'center',
  },
  list: {
    borderLeft: '1px dashed #fff',
    margin: '8px 0 8px 56px',
    boxSizing: 'border-box',
  },
  moreBtn: {
    width: 'calc(100% - 48px)',
    margin: '0 24px 24px 24px',
    flex: 1,
    borderColor: theme.palette.primary.main,
    fontWeight: 400,
  },
}));
