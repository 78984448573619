export const fieldNameToFilterTitle = (fieldName: string): string => {
  switch (fieldName) {
    case 'candidateName':
      return 'Candidate Name';
    case 'companyOwner':
      return 'Company Owner';
    default:
      return fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
  }
};
