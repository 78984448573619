import { makeStyles, theme } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  mainBtn: {
    color: theme.palette.text.primary,
    height: '100%',
    whiteSpace: 'nowrap',
  },
  menuBox: {
    display: 'flex',
    padding: '8px 16px',
  },
  periodsBox: {
    borderRight: '1px solid #8D8D8D',
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '16px',
  },
  datePickersBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '16px',
      gap: '16px',
    },
  },
  datePicker: {
    '& .MuiPickersCalendarHeader-root': {
      marginTop: 0,
    },
  },
}));
