import React, { FC, useState } from 'react';
import { Box, Chip, List, ListItem, ListItemButton, ListItemText, Menu } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RuleIcon from '@mui/icons-material/Rule';
import { GridColDef } from '@mui/x-data-grid';

import { TRule } from '~/components/organisms/TableToolbar';
import { RuleItem } from '~/components/organisms/TableToolbar/components/RuleItem/RuleItem';
import { RuleMenu } from '~/components/organisms/TableToolbar/components/RuleMenu';

import { useStyles } from './styles';

interface IRuleChipProps {
  rules: TRule[];
  columns: GridColDef[];
  onRuleChange?: React.Dispatch<React.SetStateAction<TRule[]>>;
}

export const RuleChip: FC<IRuleChipProps> = (props) => {
  const { rules, onRuleChange, columns } = props;

  const { classes } = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [ruleMenuAnchor, setRuleMenuAnchor] = useState<HTMLElement | null>(null);

  const handleRuleMenuClose = () => {
    setRuleMenuAnchor(null);
    setMenuAnchor(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const label = rules.length > 1 ? `${rules.length} Rules` : rules.length === 1 ? `${rules.length} Rule` : '';

  return (
    <>
      <Chip
        className={classes.chip}
        label={label}
        onDelete={() => onRuleChange?.([])}
        icon={<RuleIcon sx={{ color: '#fff !important' }} />}
        onClick={handleOpenMenu}
        deleteIcon={<CloseIcon />}
      />
      <Menu open={!!menuAnchor} anchorEl={menuAnchor} onClose={handleCloseMenu}>
        <Box>
          <List dense>
            {rules.map((rule, index) => (
              <RuleItem key={rule.field + index} rule={rule} columns={columns} onRuleChange={onRuleChange} />
            ))}
            <ListItem key={'add button'} sx={{ padding: 0 }}>
              <ListItemButton onClick={(e) => setRuleMenuAnchor(e.currentTarget)}>
                <ListItemText primary={`+ Add Rule`} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Menu>
      <RuleMenu
        columns={columns}
        menuAnchor={ruleMenuAnchor}
        onClose={handleRuleMenuClose}
        onRuleChange={onRuleChange}
      />
    </>
  );
};
