import React, { FC, useRef } from 'react';
import { MenuProps } from '@mui/material/Menu/Menu';
import { TFilterData } from '~/components/organisms/TableToolbar';
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import clsx from 'clsx';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Dayjs } from 'dayjs';

import { CustomSelect } from '~/components/molecules/CustomSelect';
import { fieldNameToFilterTitle } from '~/utils/fieldNameToFilterTitle';
import { DatePickerMenu } from '~/components/organisms/TableToolbar/components/DatePicker';
import { TPeriodId } from '~/pages/Dashboard/types';
import { calculateTimePeriods, getTimePeriodFromDateRange } from '~/utils';
import { CustomField } from '~/components/molecules/CustomField';

import {
  isWhiteboardFilter,
  isWhiteboardFilterSet,
  IWhiteboardFilterCondition,
  IWhiteboardFilterConditionSet,
  TOperator,
} from './types';
import { useStyles } from './styles';

export const newFilterInitState = {
  filterSet: [],
  conjunction: 'and',
} as IWhiteboardFilterConditionSet;

const operators = ['=', '>', '<', '>=', '<='];

interface INewFiltersMenuProps extends MenuProps {
  filtersData: TFilterData;
  filterState: IWhiteboardFilterConditionSet;
  updateFilterState: (filterState: IWhiteboardFilterConditionSet) => void;
}

export const NewFiltersMenu: FC<INewFiltersMenuProps> = (props) => {
  const { filtersData, anchorEl, onClose, filterState, updateFilterState } = props;

  const { classes } = useStyles();

  const options = useRef(calculateTimePeriods());

  const handleUpdateCondition = (
    updatedFields: Partial<IWhiteboardFilterCondition>,
    condition: IWhiteboardFilterCondition
  ) => {
    const newConditions = { ...filterState };

    Object.assign(condition, updatedFields);

    updateFilterState(newConditions);
  };

  const handleFilterSelect = (newValue: string, condition: IWhiteboardFilterCondition) => {
    const isMultiSelectCondition = ['status', 'recruiter', 'company', 'companyOwner'].includes(newValue);

    const updatedFields: Partial<IWhiteboardFilterCondition> = {
      name: newValue as keyof TFilterData,
      value: isMultiSelectCondition ? [] : undefined,
    };

    if (newValue === 'byWhenDate') {
      updatedFields.dateRange = { from: null, to: null };
      updatedFields.periodId = 'all_time';
    }

    handleUpdateCondition(updatedFields, condition);
  };

  const handleConjunctionSelect = (newValue: string, filterSet: IWhiteboardFilterConditionSet) => {
    if (filterSet === filterState) {
      filterSet.conjunction = newValue as 'and' | 'or';
      updateFilterState({ ...filterSet });
    } else {
      const newConditions = { ...filterState };
      filterSet.conjunction = newValue as 'and' | 'or';
      updateFilterState(newConditions);
    }
  };

  const handleFromChange = (date: Dayjs | null, condition: IWhiteboardFilterCondition) => {
    const newConditions = { ...filterState };
    if (condition.dateRange) condition.dateRange.from = date ? date.utc(true).format() : null;
    assignPeriodId(condition);

    updateFilterState(newConditions);
  };

  const handleToChange = (date: Dayjs | null, condition: IWhiteboardFilterCondition) => {
    const newConditions = { ...filterState };
    if (condition.dateRange) condition.dateRange.to = date ? date.utc(true).format() : null;
    assignPeriodId(condition);

    updateFilterState(newConditions);
  };

  const assignPeriodId = (condition: IWhiteboardFilterCondition) => {
    if (condition.dateRange?.from && condition.dateRange?.to) {
      const period = getTimePeriodFromDateRange(
        condition.dateRange.from,
        condition.dateRange.to,
        options.current
      );
      condition.periodId = period.id;
    }
  };

  const handlePeriodChange = (periodId: TPeriodId, condition: IWhiteboardFilterCondition) => {
    const newConditions = { ...filterState };
    condition.periodId = periodId;
    const option = options.current.find((option) => option.id === periodId);
    if (option?.period && condition.dateRange) {
      condition.dateRange.from = option.period.from;
      condition.dateRange.to = option.period.to;
    }

    updateFilterState(newConditions);
  };

  const handleAddDateRange = (condition: IWhiteboardFilterCondition) => {
    const newConditions = { ...filterState };
    condition.dateRange = { from: null, to: null };
    condition.periodId = 'all_time';

    updateFilterState(newConditions);
  };

  const handleRemoveDateRange = (condition: IWhiteboardFilterCondition) => {
    const newConditions = { ...filterState };
    delete condition.dateRange;
    delete condition.periodId;

    updateFilterState(newConditions);
  };

  const renderConditions = (
    condition: IWhiteboardFilterCondition | IWhiteboardFilterConditionSet,
    index: number,
    filterSet: IWhiteboardFilterConditionSet
  ) => {
    if (isWhiteboardFilter(condition)) {
      const isNumberCondition = ['progress', 'potential'].includes(condition.name);

      return (
        <Box key={index} className={classes.conditionBox}>
          {index === 0 ? (
            <Typography className={classes.whereLabel}>Where</Typography>
          ) : (
            <CustomSelect
              name="conjunction"
              sx={{ width: '80px' }}
              value={filterSet.conjunction}
              onChange={(e) => handleConjunctionSelect(e.target.value, filterSet)}
              options={['and', 'or'].map((f) => (
                <MenuItem key={f} value={f}>
                  {f.toUpperCase()}
                </MenuItem>
              ))}
            />
          )}
          <CustomSelect
            name="filterName"
            className={classes.input}
            value={condition.name}
            onChange={(e) => handleFilterSelect(e.target.value, condition)}
            options={Object.keys(filtersData)
              .filter((f) => {
                if (condition.name === 'id') return true;
                return f !== 'id';
              })
              .map((f) => (
                <MenuItem key={f} value={f}>
                  {fieldNameToFilterTitle(f)}
                </MenuItem>
              ))}
          />
          {isNumberCondition && (
            <CustomSelect
              name="operator"
              value={condition.operator}
              defaultValue={'='}
              onChange={(e) => handleUpdateCondition({ operator: e.target.value as TOperator }, condition)}
              options={operators.map((o) => (
                <MenuItem key={o} value={o}>
                  {o}
                </MenuItem>
              ))}
            />
          )}
          {isNumberCondition ? (
            <CustomField
              name="value"
              value={condition.value}
              onChange={(e) => handleUpdateCondition({ value: e.target.value }, condition)}
            />
          ) : !['byWhenDate', 'id'].includes(condition.name) ? (
            <CustomSelect
              name="value"
              className={classes.input}
              multiple
              value={condition.value}
              defaultValue={[]}
              onChange={(e) => handleUpdateCondition({ value: e.target.value }, condition)}
              options={filtersData[condition.name]?.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.title}
                </MenuItem>
              ))}
            />
          ) : null}
          {(condition.name === 'status' || condition.name === 'byWhenDate') && !condition.dateRange ? (
            <Button endIcon={<CalendarMonthIcon />} onClick={() => handleAddDateRange(condition)}>
              Add
            </Button>
          ) : (condition.name === 'status' || condition.name === 'byWhenDate') && condition.dateRange ? (
            <>
              <DatePickerMenu
                sx={{ height: '44px !important', borderRadius: '4px' }}
                onFromChange={(date) => handleFromChange(date, condition)}
                onToChange={(date) => handleToChange(date, condition)}
                dateRange={condition.dateRange}
                currentPeriodId={condition.periodId}
                options={options.current}
                onPeriodChange={(periodId) => handlePeriodChange(periodId, condition)}
              />
              {condition.name === 'status' && (
                <Button endIcon={<CalendarMonthIcon />} onClick={() => handleRemoveDateRange(condition)}>
                  Remove
                </Button>
              )}
            </>
          ) : null}
          <IconButton color="primary" onClick={() => handleRemoveCondition(filterSet, condition)}>
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      );
    } else if (isWhiteboardFilterSet(condition)) {
      return (
        <Box key={index} className={classes.conditionBox}>
          {index === 0 ? (
            <Typography className={classes.whereLabel}>Where</Typography>
          ) : (
            <CustomSelect
              sx={{ width: '80px' }}
              value={filterSet.conjunction}
              onChange={(e) => handleConjunctionSelect(e.target.value, filterSet)}
              options={['and', 'or'].map((f) => (
                <MenuItem key={f} value={f}>
                  {f.toUpperCase()}
                </MenuItem>
              ))}
            />
          )}
          <Box key={index} className={clsx(classes.conditionGroupBox, classes.conditionGroupBorder)}>
            {condition.filterSet.map((c, i) => renderConditions(c, i, condition))}
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '16px' }}>
              <Button onClick={() => handleAddCondition(condition)}>Add condition</Button>
              <Button
                disabled={!condition.filterSet.length}
                onClick={() => handleAddConditionGroup(condition)}>
                Add condition group
              </Button>
              <IconButton color="primary" onClick={() => handleRemoveConditionGroup(filterSet, condition)}>
                <DeleteForeverIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const handleAddCondition = (filterSet: IWhiteboardFilterConditionSet) => {
    filterSet.filterSet.push({
      name: 'status',
      value: [],
    });
    const newConditions = { ...filterState };

    updateFilterState(newConditions);
  };

  const handleRemoveCondition = (
    filterSet: IWhiteboardFilterConditionSet,
    condition: IWhiteboardFilterCondition
  ) => {
    const newConditions = { ...filterState };
    const index = filterSet.filterSet.indexOf(condition);
    filterSet.filterSet.splice(index, 1);

    updateFilterState(newConditions);
  };

  const handleAddConditionGroup = (filterSet: IWhiteboardFilterConditionSet) => {
    filterSet.filterSet.push({
      filterSet: [],
      conjunction: 'and',
    });
    const newConditions = { ...filterState };

    updateFilterState(newConditions);
  };

  const handleRemoveConditionGroup = (
    filterSet: IWhiteboardFilterConditionSet,
    selectedFilterGroup: IWhiteboardFilterConditionSet
  ) => {
    const newConditions = { ...filterState };
    const index = filterSet.filterSet.indexOf(selectedFilterGroup);
    filterSet.filterSet.splice(index, 1);

    updateFilterState(newConditions);
  };

  const handleClear = () => {
    updateFilterState(JSON.parse(JSON.stringify(newFilterInitState)));
  };

  return (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={onClose}>
      <Box className={classes.root}>
        <Box className={classes.conditionGroupBox}>
          {filterState.filterSet.length ? (
            filterState.filterSet.map((c, i) => renderConditions(c, i, filterState))
          ) : (
            <Typography>Add first condition</Typography>
          )}
        </Box>
        <Box className={classes.actionBox}>
          <Button variant="contained" onClick={() => handleAddCondition(filterState)}>
            Add condition
          </Button>
          <Button variant="contained" onClick={() => handleAddConditionGroup(filterState)}>
            Add condition group
          </Button>
          <Button onClick={handleClear}>Clear</Button>
        </Box>
      </Box>
    </Menu>
  );
};
