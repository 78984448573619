import { FC, memo, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';

import { ITotals } from '~/store/metric';

import { useStyles } from './styles';

interface ITotalRowProps {
  columns: GridColDef[];
  values: ITotals;
}

export const TotalRow: FC<ITotalRowProps> = memo((props) => {
  const { columns, values } = props;

  const { classes } = useStyles();

  const rowRef = useRef<HTMLDivElement>(null);
  const cloneRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (rowRef.current && !cloneRef.current) {
      const totalRowDiv = rowRef.current.cloneNode(true) as HTMLDivElement;
      // reassigning of styles helps to hide the original row
      totalRowDiv.style.display = 'flex';
      totalRowDiv.style.position = 'sticky';
      totalRowDiv.style.height = '52px';
      totalRowDiv.style.minWidth = 'fit-content';
      totalRowDiv.style.width = '100%';

      cloneRef.current = totalRowDiv;

      document.querySelector('.MuiDataGrid-virtualScroller')?.appendChild(totalRowDiv);

      return () => {
        try {
          // prevents next error
          // Unexpected Application Error!
          // Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
          document.querySelector('.MuiDataGrid-virtualScroller')?.removeChild(totalRowDiv);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
        cloneRef.current = null;
      };
    }
  }, [values]);

  return (
    <Box ref={rowRef} className={classes.totals}>
      {columns.map((col) => (
        <Typography sx={{ width: `${col.width}px`, padding: '0 10px' }} align="left" key={col.field}>
          {values[col.field as keyof ITotals]}
        </Typography>
      ))}
    </Box>
  );
});

TotalRow.displayName = 'TotalRow';
