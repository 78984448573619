import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { sliceName } from './actionTypes';
import { IBullhornUser, IRole, IServiceData, IStatus, IUser } from './types';
import {
  getBullhornUsers,
  getServiceData,
  getUserRoles,
  getUsers,
  getUserStatuses,
  registerUserCognito,
} from './thunks';

export interface AdminState {
  users: {
    data: IUser[] | null;
    loading: boolean;
  };
  userRoles: {
    data: IRole[] | null;
    loading: boolean;
  };
  userStatuses: {
    data: IStatus[] | null;
    loading: boolean;
  };
  bullhornUsers: {
    data: IBullhornUser[] | null;
    loading: boolean;
  };
  serviceStatus: {
    data: IServiceData[] | null;
    loading: boolean;
  };
}

const initialState: AdminState = {
  users: { data: null, loading: false },
  userRoles: { data: null, loading: false },
  userStatuses: { data: null, loading: false },
  bullhornUsers: { data: null, loading: false },
  serviceStatus: { data: null, loading: false },
};

export const adminSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearUsers: (state) => {
      state.users.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.users.loading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users.data = action.payload;
      state.users.loading = false;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.users.loading = false;
    });
    builder.addCase(getUserRoles.pending, (state) => {
      state.userRoles.loading = true;
    });
    builder.addCase(getUserRoles.fulfilled, (state, action) => {
      state.userRoles.data = action.payload;
      state.userRoles.loading = false;
    });
    builder.addCase(getUserRoles.rejected, (state) => {
      state.userRoles.loading = false;
    });
    builder.addCase(getUserStatuses.pending, (state) => {
      state.userStatuses.loading = true;
    });
    builder.addCase(getUserStatuses.fulfilled, (state, action) => {
      state.userStatuses.data = action.payload;
      state.userStatuses.loading = false;
    });
    builder.addCase(getUserStatuses.rejected, (state) => {
      state.userStatuses.loading = false;
    });
    builder.addCase(getBullhornUsers.pending, (state) => {
      state.bullhornUsers.loading = true;
    });
    builder.addCase(getBullhornUsers.fulfilled, (state, action) => {
      state.bullhornUsers.data = action.payload?.data;
      state.bullhornUsers.loading = false;
    });
    builder.addCase(getBullhornUsers.rejected, (state) => {
      state.bullhornUsers.loading = false;
    });
    builder.addCase(getServiceData.pending, (state) => {
      state.serviceStatus.loading = true;
    });
    builder.addCase(getServiceData.fulfilled, (state, action) => {
      state.serviceStatus.data = action.payload;
      state.serviceStatus.loading = false;
    });
    builder.addCase(getServiceData.rejected, (state) => {
      state.serviceStatus.loading = false;
    });
    builder.addCase(registerUserCognito.fulfilled, (state, action) => {
      const { userId, sub, email } = action.payload;
      const userIndex = state.users.data?.findIndex((user) => user.id === userId);
      if (userIndex && state.users.data) {
        state.users.data[userIndex].sub = sub;
        state.users.data[userIndex].email = email;
        state.users.data = [...state.users.data];
      }
    });
  },
});

export const usersSelector = (state: RootState) => state.admin.users;
export const userRolesSelector = (state: RootState) => state.admin.userRoles;
export const userStatusesSelector = (state: RootState) => state.admin.userStatuses;
export const bullhornUsersSelector = (state: RootState) => state.admin.bullhornUsers;
export const serviceStatusSelector = (state: RootState) => state.admin.serviceStatus;

export const { clearUsers } = adminSlice.actions;

export const adminReducer = adminSlice.reducer;
