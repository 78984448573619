import { FC, MouseEvent } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

interface IViewTabProps {
  name?: string;
  isFavorite: boolean;
  onDelete?: () => void;
  onClick: () => void;
  onFavoriteClick: () => void;
  selected?: boolean;
}

export const ViewTab: FC<IViewTabProps> = (props) => {
  const { name, onDelete, onClick, selected, isFavorite, onFavoriteClick } = props;

  const handleStarClick = (e: MouseEvent) => {
    e.stopPropagation();
    onFavoriteClick();
  };

  return (
    <Box
      onClick={onClick}
      sx={{
        minWidth: '46px',
        padding: '0 12px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderBottom: '4px solid',
        borderColor: selected ? 'primary.main' : 'transparent',
      }}>
      {name !== 'All' ? (
        isFavorite ? (
          <IconButton disabled={!selected} onClick={handleStarClick}>
            <StarRoundedIcon
              sx={{
                width: '18px',
                height: '18px',
                color: '#FFC233',
              }}
            />
          </IconButton>
        ) : (
          <IconButton disabled={!selected} onClick={handleStarClick}>
            <StarBorderRoundedIcon
              sx={{
                width: '18px',
                height: '18px',
                color: '#8D8D8D',
                visibility: selected ? 'visible' : 'hidden',
              }}
            />
          </IconButton>
        )
      ) : null}
      <Typography noWrap sx={{ maxWidth: '150px' }}>
        {name ? name : 'All'}
      </Typography>
      {name !== 'All' && (
        <Box sx={{ width: '38px' }}>
          {selected ? (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.();
              }}>
              <CancelIcon
                sx={{
                  width: '22px',
                  height: '22px',
                  filter: 'brightness(0.63)',
                  color: 'text.disabled',
                  '&:hover': { filter: 'brightness(1)' },
                }}
              />
            </IconButton>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
