import { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Toolbar, Typography } from '@mui/material';

import { ReactComponent as LogoIcon } from '~/assets/Logo.svg';
import { Routes } from '~/constants';

import { useStyles } from './styles';

interface INonRootLayout {
  children: ReactElement;
}

export const NonRootLayout: FC<INonRootLayout> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Link className={classes.logoBox} to={Routes.comingSoon}>
          <LogoIcon />
          <Typography>[IN]-BOARD</Typography>
        </Link>
        <Link style={{ textDecoration: 'none' }} to={Routes.signIn}>
          <Button className={classes.loginBtn} variant="text">
            Log In
          </Button>
        </Link>
      </Toolbar>
      <Box className={classes.childrenBox}>{children}</Box>
      <Toolbar className={classes.footer}>
        <Typography color="text.disabled">Copyright 2023 In Board App llc. All rights reserved.</Typography>
      </Toolbar>
    </Box>
  );
};
