import { GridColDef } from '@mui/x-data-grid';

import { IUserWhiteboardMetric } from '~/store/whiteboardMetrics';
import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';

const namesColumnWidth = 250;

export const columns: GridColDef<IUserWhiteboardMetric>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: namesColumnWidth,
    renderCell: (params) => {
      return (
        <UsernameWithAvatar
          avatar={params.row.avatar || ''}
          firstName={params.row.firstName || ''}
          lastName={params.row.lastName || ''}
          color={params.row.color || ''}
        />
      );
    },
  },
  {
    field: 'interviews',
    headerName: 'Interviews',
    width: 150,
    valueGetter: (params) => {
      return params.row.whiteboardMetrics.interviews;
    },
  },
  {
    field: 'offers',
    headerName: 'Offers',
    width: 150,
    valueGetter: (params) => {
      return params.row.whiteboardMetrics.offers;
    },
  },
  {
    field: 'placements',
    headerName: 'Placements',
    width: 150,
    valueGetter: (params) => {
      return params.row.whiteboardMetrics.placements;
    },
  },
  {
    field: 'billings',
    headerName: 'Billings',
    width: 150,
    valueGetter: (params) => {
      return `$${params.row.whiteboardMetrics.billings.toLocaleString('en-US')}`;
    },
  },
];

const allowedColumns = ['billings', 'placements', 'offers', 'interviews'];

export const columnsWithRules = columns.filter((column) => allowedColumns.includes(column.field as string));
