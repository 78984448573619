import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControlLabel,
  IconButton,
  MenuItem,
  Snackbar,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { theme } from '~/theme';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import {
  deleteUser,
  editUser,
  getUsers,
  IBullhornUser,
  registerNewUser,
  registerUserCognito,
  userRolesSelector,
} from '~/store/admin';
import { CustomField } from '~/components/molecules/CustomField';
import { CustomSelect } from '~/components/molecules/CustomSelect';
import { TNewUserForm, TUserDrawerState } from '~/pages/UserManagement/types';
import { useAppDispatch } from '~/store';
import { DeleteDialog, IDeleteDialogProps, initDialogState } from '~/components/organisms/DeleteDialog';
import { userDataSelector } from '~/store/common';

import { useStyles } from './styles';

const initialValues = {
  name: '',
  role: '',
  email: '',
  activateUser: false,
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  role: Yup.string().required('Role is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
});

interface IAdminUserDrawerProps {
  userDrawerState: TUserDrawerState;
  onClose: () => void;
  bullhornUsers: null | IBullhornUser[];
  bullhornUsersLoading: boolean;
}

export const AdminUserDrawer: FC<IAdminUserDrawerProps> = (props) => {
  const { userDrawerState, onClose, bullhornUsers, bullhornUsersLoading } = props;
  const { open, type, data } = userDrawerState;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: roles } = useSelector(userRolesSelector);
  const { data: userData } = useSelector(userDataSelector);

  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteDialogState, setDeleteDialogState] = useState<IDeleteDialogProps>(initDialogState);
  const [bullhornUserId, setBullhornUserId] = useState<number | null>(null);

  const formik = useFormik<TNewUserForm>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (type === 'edit') {
        dispatch(editUser({ data: { ...values }, id: data?.id || '' })).then((r) => {
          setLoading(false);

          if (!r.type.includes('rejected')) {
            dispatch(getUsers());
            handleClose();
          } else {
            setOpenError(true);
            setErrorMsg('Error editing user');
          }
        });
      } else {
        const payload = { ...values };
        if (userData?.role === 'god') {
          payload.bullhornId = bullhornUserId;
        }

        dispatch(registerNewUser(payload)).then((r) => {
          setLoading(false);

          if (!r.type.includes('rejected')) {
            dispatch(getUsers());
            handleClose();
          } else {
            setOpenError(true);
            setErrorMsg('Error creating new user');
          }
        });
      }
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (type === 'edit' && data) {
      setFieldValue('name', data.firstName + ' ' + data.lastName);
      setFieldValue('role', data.role?.id);
      setFieldValue('email', data.email);
      setFieldValue('activateUser', data.status?.name === 'active' ? true : false);
    }
  }, [data]);

  const handleClose = () => {
    formik.resetForm();
    setBullhornUserId(null);
    onClose();
  };

  const handleSnackbarClose = () => {
    setOpenError(false);
    setErrorMsg('');
  };

  const handleUserDeletion = () => {
    setDeleteDialogState((s) => ({ ...s, loading: true }));
    dispatch(deleteUser(data?.id || '')).then((r) => {
      if (!r.type.includes('rejected')) {
        dispatch(getUsers());
        setDeleteDialogState(initDialogState);
        handleClose();
      } else {
        setDeleteDialogState((s) => ({ ...s, loading: false }));
        setOpenError(true);
        setErrorMsg('Error deleting user');
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogState({
      ...deleteDialogState,
      open: true,
      title: 'Delete User',
      message: 'Are you sure you want to delete this user?',
      onDelete: handleUserDeletion,
      onClose: () => setDeleteDialogState(initDialogState),
    });
  };

  const handleBullhornIdChange = (event: SelectChangeEvent<number | null>) => {
    setBullhornUserId(event.target.value as number);

    if (!formik.values.name) {
      const user = bullhornUsers?.find((u) => u.id === event.target.value);
      if (user) {
        formik.setFieldValue('name', user.firstName + ' ' + user.lastName);
      }
    }

    if (!formik.values.email) {
      const user = bullhornUsers?.find((u) => u.id === event.target.value);
      if (user) {
        formik.setFieldValue('email', user.email);
      }
    }
  };

  const isGodRole = userData?.role === 'god';

  const handleRegister = async () => {
    setLoading(true);
    const [firstName, lastName] = formik.values.name.split(' ');

    const res = await dispatch(
      registerUserCognito({
        email: formik.values.email,
        firstName: firstName,
        lastName: lastName,
        userId: data?.id || '',
      })
    );
    if (!res.type.includes('rejected') && data) {
      userDrawerState.data = {
        ...data,
        sub: (res.payload as { sub: string }).sub,
        email: formik.values.email,
        firstName: firstName,
        lastName: lastName,
      };
      setTimeout(() => formik.setFieldValue('activateUser', true), 50);
      await dispatch(
        editUser({
          data: { ...formik.values, activateUser: true },
          id: data.id,
          role: userData?.role,
        })
      );
    }
    setLoading(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor={'right'}
      open={open}
      onClose={handleClose}
      hideBackdrop={!isMobile}>
      <IconButton sx={{ position: 'absolute', right: 20, top: 25 }} onClick={handleClose}>
        <CloseIcon sx={{ color: '#BDBDBD' }} />
      </IconButton>
      <Typography className={classes.title}>{type === 'edit' ? 'Edit user' : 'Add user'}</Typography>
      {type === 'add' && (
        <Typography sx={{ margin: '0 0 20px 24px' }}>Complete the form to add a new user.</Typography>
      )}
      <Box className={classes.formBox} component="form" onSubmit={handleSubmit}>
        <Typography className={clsx(classes.subTitle, classes.padding)}>User Information</Typography>
        <CustomField
          required
          placeholder="Enter name"
          className={clsx(classes.input, classes.padding)}
          label="Name"
          autoComplete="off"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <CustomSelect
          className={clsx(classes.input, classes.padding)}
          sx={{ textTransform: 'capitalize' }}
          label="Role"
          displayEmpty
          required
          name="role"
          value={values.role}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.role && Boolean(errors.role)}
          helperText={touched.role && errors.role}
          options={roles
            ?.filter((r) => {
              if (!isGodRole) {
                return r.name !== 'god';
              }
              return r;
            })
            .map((r) => (
              <MenuItem key={r.id} value={r.id} sx={{ textTransform: 'capitalize' }}>
                {r.name}
              </MenuItem>
            ))}
        />
        <CustomField
          required
          placeholder="Enter email"
          className={clsx(classes.input, classes.padding)}
          label="Email"
          autoComplete="off"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
        {data?.sub ? null : (
          <ButtonWithLoading
            loading={loading}
            disabled={!values.email || (touched.email && Boolean(errors.email))}
            className={classes.registerBtn}
            onClick={handleRegister}
            variant="contained">
            Register
          </ButtonWithLoading>
        )}
        <Typography className={clsx(classes.subTitle, classes.padding)}>Status</Typography>
        <FormControlLabel
          sx={{ marginBottom: '24px' }}
          label="Activate user account"
          control={
            <Switch
              sx={{ marginLeft: '24px' }}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={values.activateUser}
              onChange={(event) => setFieldValue('activateUser', event.target.checked)}
            />
          }
        />
        {userData?.role === 'god' && (
          <CustomSelect<number | null>
            className={clsx(classes.input, classes.padding)}
            sx={{ textTransform: 'capitalize' }}
            label="Bullhorn User"
            displayEmpty
            loading={bullhornUsersLoading}
            value={bullhornUserId}
            onChange={handleBullhornIdChange}
            options={bullhornUsers?.map((r) => (
              <MenuItem key={r.id} value={r.id} sx={{ textTransform: 'capitalize' }}>
                {r.firstName + ' ' + r.lastName}
              </MenuItem>
            ))}
          />
        )}
        <Box className={classes.actionBox}>
          {type === 'edit' && (
            <Button
              color="error"
              variant="contained"
              disabled={loading}
              sx={{ marginRight: '10px' }}
              onClick={handleOpenDeleteDialog}>
              Delete
            </Button>
          )}
          <Button sx={{ marginRight: '10px' }} onClick={handleClose}>
            Cancel
          </Button>
          <ButtonWithLoading loading={loading} disabled={loading} variant="contained" type="submit">
            {type === 'edit' ? 'Save' : 'Add'}
          </ButtonWithLoading>
        </Box>
      </Box>
      <DeleteDialog
        open={deleteDialogState.open}
        loading={deleteDialogState.loading}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        onClose={deleteDialogState.onClose}
        onDelete={deleteDialogState.onDelete}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: isMobile ? '' : '50px' }}
        open={openError}
        autoHideDuration={errorMsg.length * 60 > 1500 ? errorMsg.length * 60 : 1500}
        onClose={handleSnackbarClose}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </Drawer>
  );
};
