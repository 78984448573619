import { createBrowserRouter } from 'react-router-dom';

import { Routes } from '~/constants/routes';
import { RootLayout } from '~/components/organisms/RootLayout';
import { ComingSoon } from '~/pages/ComingSoon';
import { SignIn } from '~/pages/SignIn';
import { Metrics } from '~/pages/Metrics';
import { SignUp } from '~/pages/SignUp';
import { Playground } from '~/pages/Playground';
import { Settings } from '~/pages/Settings';
import { Dashboard } from '~/pages/Dashboard';
import { UserManagement } from '~/pages/UserManagement';
import { Whiteboard } from '~/pages/Whiteboard';
import { WhiteboardMetrics } from '~/pages/WhiteboardMetrics';
import { NotFound } from '~/pages/NotFound';
import { WhiteboardInsights } from '~/pages/WhiteboardInsights';
import { ServiceMonitoring } from '~/pages/ServiceMonitoring';

export const router = createBrowserRouter([
  {
    path: Routes.root,
    element: <RootLayout />,
    children: [
      {
        path: Routes.dashboard,
        element: <Dashboard />,
      },
      {
        path: Routes.whiteboard,
        element: <Whiteboard />,
      },
      {
        path: Routes.metrics,
        element: <Metrics />,
      },
      {
        path: Routes.whiteboardMetrics,
        element: <WhiteboardMetrics />,
      },
      {
        path: Routes.whiteboardInsights,
        element: <WhiteboardInsights />,
      },
      {
        path: Routes.settings,
        element: <Settings />,
      },
      {
        path: Routes.playground,
        element: <Playground />,
      },
      {
        path: Routes.userManagement,
        element: <UserManagement />,
      },
    ],
  },
  {
    path: Routes.comingSoon,
    element: <ComingSoon />,
  },
  {
    path: Routes.signIn,
    element: <SignIn />,
  },
  {
    path: Routes.signUp,
    element: <SignUp />,
  },
  {
    path: Routes.serviceMonitoring,
    element: <ServiceMonitoring />,
  },
  {
    path: Routes.notFound,
    element: <NotFound />,
  },
]);
