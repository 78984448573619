import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  totals: {
    display: 'none',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    height: 0,
    backgroundColor: theme.palette.background.paper,
  },
}));
