import { FC, useCallback, useEffect, useRef, useState, Fragment } from 'react';
import { Box, CircularProgress, Divider, List, ListItem, SxProps, Theme, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';

import { WidgetPaper } from '~/components/atoms/WidgetPaper';
import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';
import { calculateTimePeriods } from '~/utils';
import { TPeriodId } from '~/pages/Dashboard/types';
import { TimePeriodSelector } from '~/pages/Dashboard/components/TimePeriodSelector';
import { IWidgetData, TPeriod } from '~/store/dashboard';
import { Routes } from '~/constants';
import { useWidgetSocket } from '~/hooks/useWidgetSocket';
import { filtersSelector } from '~/store/whiteboard';

import { useStyles } from './styles';

interface IWidgetWithListProps {
  title: string;
  data: IWidgetData[] | number | null;
  link?: string;
  titleLink?: string;
  getData: null | ((period?: TPeriod) => string | Promise<string>);
  loading?: boolean;
  sx?: SxProps<Theme> | undefined;
  turnOffPeriod?: boolean;
  defaultPeriod?: TPeriodId;
  name?: string;
}

export const WidgetWithList: FC<IWidgetWithListProps> = (props) => {
  const {
    title,
    data,
    loading,
    getData,
    sx,
    turnOffPeriod,
    link = Routes.metrics,
    defaultPeriod = 'last_month',
    name,
    titleLink,
  } = props;
  const { classes } = useStyles();

  const options = useRef(calculateTimePeriods().slice(1));

  const { data: whiteboardFilters } = useSelector(filtersSelector);

  const [period, setPeriod] = useState<TPeriodId>(defaultPeriod);
  const [queryParams, setQueryParams] = useState('');

  const { widgetsToUpdate } = name ? useWidgetSocket() : { widgetsToUpdate: [''] };

  useEffect(() => {
    if (!getData) return;

    turnOffPeriod ? handleWithoutPeriodChange() : handlePeriodChange(period);
  }, [Boolean(getData)]);

  useEffect(() => {
    if (name && widgetsToUpdate.includes(name)) {
      turnOffPeriod ? handleWithoutPeriodChange() : handlePeriodChange(period);
    }
  }, [widgetsToUpdate]);

  const handlePeriodChange = useCallback(
    throttle(async (value: TPeriodId) => {
      setPeriod(value);
      const newQueryParams = await (getData as (period?: TPeriod) => string)(
        options.current.find((o) => o.id === value)?.period || ({} as TPeriod)
      );
      setQueryParams(newQueryParams || '');
    }, 5000),
    [getData]
  );

  const handleWithoutPeriodChange = useCallback(
    throttle(async () => {
      const newQueryParams = await getData?.();
      setQueryParams(newQueryParams || '');
    }, 5000),
    [getData]
  );

  const userWithLink = ['candidatesOut', 'offers', 'placements'].includes(name || '');

  const { from, to } = options.current.find((o) => o.id === period)?.period || { from: null, to: null };

  const tLink = titleLink
    ? (turnOffPeriod ? titleLink : titleLink + `&from=${from}&to=${to}`) + '&shared'
    : link + '?' + queryParams + '&shared';

  const handleUserLink = (d: IWidgetData) => {
    switch (name) {
      case 'candidatesOut': {
        const filterSet = JSON.stringify({
          filterSet: [
            {
              name: 'status',
              value: whiteboardFilters?.status.filter((f) => f.title === 'Interviewing').map((f) => f.id),
            },
            {
              name: 'recruiter',
              value: [d.id],
            },
          ],
          conjunction: 'and',
        });

        return link + `filter=${filterSet}&` + '&shared';
      }
      default: {
        const filterSet = JSON.stringify({
          filterSet: [
            {
              name: 'id',
              value: d.whiteboardIds || [],
            },
          ],
          conjunction: 'and',
        });

        return link + `?filter=${filterSet}&` + '&shared';
      }
    }
  };

  return (
    <WidgetPaper className={classes.root} sx={{ ...sx }}>
      {loading ? (
        <CircularProgress className={classes.spinner} size={24} />
      ) : (
        <>
          <Box className={classes.header}>
            <Link to={tLink}>
              <Typography lineHeight="1.25" variant="h6" noWrap>
                {title}
              </Typography>
            </Link>
            {turnOffPeriod ? null : (
              <TimePeriodSelector value={period} options={options.current} onChange={handlePeriodChange} />
            )}
          </Box>
          <Divider />
          {typeof data === 'number' ? (
            <Box className={classes.numberBox}>
              <Link to={link + '?' + queryParams + '&shared'}>
                <Typography sx={{ fontSize: '32px', fontWeight: 500 }}>${data.toLocaleString()}</Typography>
              </Link>
            </Box>
          ) : data && data.length > 0 ? (
            <List>
              {data?.map((d, index) => (
                <Fragment key={index}>
                  {userWithLink ? (
                    <Link key={d.id} to={handleUserLink(d)}>
                      <ListItem
                        sx={{ padding: '8px 24px', justifyContent: 'space-between', alignItems: 'center' }}
                        key={d.id}>
                        <Box display="flex" alignItems="center">
                          <Typography sx={{ marginRight: '8px' }}>{index + 1}</Typography>
                          <UsernameWithAvatar
                            username={d.username}
                            firstName={d.firstName}
                            lastName={d.lastName}
                            avatar={d.avatar}
                            color={d.color}
                          />
                        </Box>
                        <Typography color="text.disabled">{d.metric}</Typography>
                      </ListItem>
                    </Link>
                  ) : (
                    <ListItem
                      sx={{ padding: '8px 24px', justifyContent: 'space-between', alignItems: 'center' }}
                      key={d.id}>
                      <Box display="flex" alignItems="center">
                        <Typography sx={{ marginRight: '8px' }}>{index + 1}</Typography>
                        <UsernameWithAvatar
                          username={d.username}
                          firstName={d.firstName}
                          lastName={d.lastName}
                          avatar={d.avatar}
                          color={d.color}
                        />
                      </Box>
                      <Typography color="text.disabled">{d.metric}</Typography>
                    </ListItem>
                  )}
                </Fragment>
              ))}
            </List>
          ) : (
            <Box className={classes.nodataBox}>
              <Typography color="text.disabled">No data</Typography>
            </Box>
          )}
        </>
      )}
    </WidgetPaper>
  );
};
