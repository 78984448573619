import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Box,
  Button,
  Drawer,
  FormControlLabel,
  IconButton,
  MenuItem,
  Snackbar,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import EditIcon from '@mui/icons-material/Edit';
import { SelectChangeEvent } from '@mui/material/Select/SelectInput';

import { theme } from '~/theme';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import {
  deleteUser,
  editUser,
  getUsers,
  IBullhornUser,
  registerUserCognito,
  userRolesSelector,
} from '~/store/admin';
import { CustomField } from '~/components/molecules/CustomField';
import { CustomSelect } from '~/components/molecules/CustomSelect';
import { TGodUserForm, TUserDrawerState } from '~/pages/UserManagement/types';
import { useAppDispatch } from '~/store';
import { DeleteDialog, IDeleteDialogProps, initDialogState } from '~/components/organisms/DeleteDialog';
import { AvatarWithCircle } from '~/components/molecules/AvatarWithCircle';
import { ColorPickerDialog } from '~/components/organisms/ColorPickerDialog';

import { useStyles } from './styles';

const avatarSize = 100;

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  newPassword: '',
  avatar: null,
  role: '',
  activateUser: false,
};

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  newPassword: Yup.string().min(8, 'Password must be at least 8 characters'),
  avatar: Yup.mixed()
    .notRequired()
    .test('fileSize', 'File size should be less than 1 MB', function (value) {
      if (!value) return true;

      // 4 MB
      return (value as File).size <= 4 * 1024 * 1024;
    }),
  role: Yup.string().required('Role is required'),
});

interface IGodUserDrawerProps {
  userDrawerState: TUserDrawerState;
  onClose: () => void;
  bullhornUsers: null | IBullhornUser[];
  bullhornUsersLoading: boolean;
}

export const GodUserDrawer: FC<IGodUserDrawerProps> = (props) => {
  const { userDrawerState, onClose, bullhornUsers, bullhornUsersLoading } = props;
  const { open, type, data } = userDrawerState;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: roles } = useSelector(userRolesSelector);

  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [deleteDialogState, setDeleteDialogState] = useState<IDeleteDialogProps>(initDialogState);
  const [userColor, setUserColor] = useState(data?.color || '#000');
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [bullhornUserId, setBullhornUserId] = useState<number | null>(null);

  const formik = useFormik<TGodUserForm>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      setLoading(true);
      dispatch(
        editUser({
          data: { ...values, color: userColor, bullhornId: bullhornUserId },
          id: data?.id || '',
          role: 'god',
        })
      ).then((r) => {
        if (!r.type.includes('rejected')) {
          dispatch(getUsers());
          handleClose();
        } else {
          setOpenError(true);
          setErrorMsg('Error editing user');
          setLoading(false);
        }
      });
    },
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    if (data) {
      setFieldValue('firstName', data.firstName);
      setFieldValue('lastName', data.lastName);
      setFieldValue('role', data.role?.id);
      setFieldValue('email', data.email);
      setFieldValue('activateUser', data.status?.name === 'active' ? true : false);
      setUserColor(data?.color || '#000');
      setBullhornUserId(data?.bullhornId || null);
    }
  }, [data]);

  const handleClose = () => {
    formik.resetForm();
    setLoading(false);
    onClose();
  };

  const handleSnackbarClose = () => {
    setOpenError(false);
    setErrorMsg('');
  };

  const handleUserDeletion = () => {
    setDeleteDialogState((s) => ({ ...s, loading: true }));
    dispatch(deleteUser(data?.id || '')).then((r) => {
      if (!r.type.includes('rejected')) {
        dispatch(getUsers());
        setDeleteDialogState(initDialogState);
        handleClose();
      } else {
        setDeleteDialogState((s) => ({ ...s, loading: false }));
        setOpenError(true);
        setErrorMsg('Error deleting user');
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogState({
      ...deleteDialogState,
      open: true,
      title: 'Delete User',
      message: 'Are you sure you want to delete this user?',
      onDelete: handleUserDeletion,
      onClose: () => setDeleteDialogState(initDialogState),
    });
  };

  const handleBullhornIdChange = (event: SelectChangeEvent<number | null>) => {
    setBullhornUserId(event.target.value as number);
  };

  const handleRegister = async () => {
    setLoading(true);

    const res = await dispatch(
      registerUserCognito({
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        userId: data?.id || '',
      })
    );
    if (!res.type.includes('rejected') && data) {
      userDrawerState.data = {
        ...data,
        sub: (res.payload as { sub: string }).sub,
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
      };
      setTimeout(() => formik.setFieldValue('activateUser', true), 50);
      await dispatch(
        editUser({
          data: { ...formik.values, activateUser: true },
          id: data.id,
          role: 'god',
        })
      );
    }
    setLoading(false);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor={'right'}
      open={open}
      onClose={handleClose}
      hideBackdrop={!isMobile}>
      <IconButton sx={{ position: 'absolute', right: 20, top: 25 }} onClick={handleClose}>
        <CloseIcon sx={{ color: '#BDBDBD' }} />
      </IconButton>
      <Typography className={classes.title}>{type === 'edit' ? 'Edit user' : 'Add user'}</Typography>
      {type === 'add' && (
        <Typography sx={{ margin: '0 0 20px 24px' }}>Complete the form to add a new user.</Typography>
      )}
      <Box className={classes.formBox} component="form" onSubmit={handleSubmit}>
        <Box className={classes.formHeader} sx={{ backgroundColor: userColor }}>
          <Button
            sx={{ position: 'absolute', bottom: 0, right: 24, color: '#fff' }}
            endIcon={<EditIcon />}
            onClick={() => setOpenColorPicker(true)}>
            Edit Color
          </Button>
        </Box>
        <Box
          className={classes.avatarBox}
          sx={{
            width: `${avatarSize + 14}px`,
            height: `${avatarSize + 14}px`,
          }}>
          <AvatarWithCircle color={userColor} src={data?.avatar || ''} size={avatarSize} />
        </Box>
        <Box className={clsx(classes.updateBtnBox, classes.padding)}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button component="label" sx={{ minWidth: '110px', marginLeft: '-8px' }}>
              Update image
              <input
                type="file"
                hidden
                name="avatar"
                onChange={(event) => formik.setFieldValue('avatar', event.currentTarget.files?.[0])}
              />
            </Button>
            <Typography>{formik.values.avatar?.name}</Typography>
          </Box>
          <Typography sx={{ marginLeft: '8px' }} color="error">
            {formik.errors.avatar}
          </Typography>
          {formik.values.avatar && (
            <Typography>Picture successfully uploaded please save profile to view change</Typography>
          )}
        </Box>
        <Typography className={clsx(classes.subTitle, classes.padding)}>User Information</Typography>
        <CustomField
          className={clsx(classes.input, classes.padding)}
          label="First Name"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          error={formik.touched.firstName && Boolean(formik.errors.firstName)}
          helperText={formik.touched.firstName && formik.errors.firstName}
          autoComplete={'off'}
        />
        <CustomField
          className={clsx(classes.input, classes.padding)}
          label="Last Name"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          error={formik.touched.lastName && Boolean(formik.errors.lastName)}
          helperText={formik.touched.lastName && formik.errors.lastName}
          autoComplete={'off'}
        />
        <CustomSelect
          disabled={!data?.sub}
          className={clsx(classes.input, classes.padding)}
          sx={{ textTransform: 'capitalize' }}
          label="Role"
          displayEmpty
          required
          name="role"
          value={values.role}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.role && Boolean(errors.role)}
          helperText={touched.role && errors.role}
          options={roles?.map((r) => (
            <MenuItem key={r.id} value={r.id} sx={{ textTransform: 'capitalize' }}>
              {r.name}
            </MenuItem>
          ))}
        />
        <CustomField
          required
          placeholder="Enter email"
          className={clsx(classes.input, classes.padding)}
          label="Email"
          autoComplete="off"
          name="email"
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
        {data?.sub ? (
          <>
            <CustomField
              disabled={!data?.sub}
              className={clsx(classes.input, classes.padding)}
              label="New password"
              name="newPassword"
              type="password"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
              helperText={formik.touched.newPassword && formik.errors.newPassword}
              autoComplete={'new-password'}
            />
          </>
        ) : (
          <ButtonWithLoading
            loading={loading}
            disabled={
              !values.email ||
              !values.firstName ||
              !values.lastName ||
              (touched.email && Boolean(errors.email))
            }
            className={classes.registerBtn}
            onClick={handleRegister}
            variant="contained">
            Register
          </ButtonWithLoading>
        )}
        <Typography className={clsx(classes.subTitle, classes.padding)}>Status</Typography>
        <FormControlLabel
          sx={{ marginBottom: '24px' }}
          label="Activate user account"
          control={
            <Switch
              sx={{ marginLeft: '24px' }}
              inputProps={{ 'aria-label': 'controlled' }}
              checked={values.activateUser}
              onChange={(event) => setFieldValue('activateUser', event.target.checked)}
            />
          }
        />
        <CustomSelect<number | null>
          disabled={!data?.sub}
          className={clsx(classes.input, classes.padding)}
          sx={{ textTransform: 'capitalize' }}
          label="Bullhorn User"
          displayEmpty
          loading={bullhornUsersLoading}
          value={bullhornUserId}
          onChange={handleBullhornIdChange}
          options={bullhornUsers?.map((r) => (
            <MenuItem key={r.id} value={r.id} sx={{ textTransform: 'capitalize' }}>
              {r.firstName + ' ' + r.lastName}
            </MenuItem>
          ))}
        />
        <Box className={classes.actionBox}>
          {type === 'edit' && (
            <Button
              color="error"
              variant="contained"
              disabled={loading}
              sx={{ marginRight: '10px' }}
              onClick={handleOpenDeleteDialog}>
              Delete
            </Button>
          )}
          <Button sx={{ marginRight: '10px' }} onClick={handleClose}>
            Cancel
          </Button>
          <ButtonWithLoading loading={loading} disabled={loading} variant="contained" type="submit">
            {type === 'edit' ? 'Save' : 'Add'}
          </ButtonWithLoading>
        </Box>
      </Box>
      <DeleteDialog
        open={deleteDialogState.open}
        loading={deleteDialogState.loading}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        onClose={deleteDialogState.onClose}
        onDelete={deleteDialogState.onDelete}
      />
      <ColorPickerDialog
        color={userColor}
        onColorChange={(color) => setUserColor(color)}
        open={openColorPicker}
        onClose={() => setOpenColorPicker(false)}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: isMobile ? '' : '50px' }}
        open={openError}
        autoHideDuration={errorMsg.length * 60 > 1500 ? errorMsg.length * 60 : 1500}
        onClose={handleSnackbarClose}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </Drawer>
  );
};
