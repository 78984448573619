import { FC } from 'react';
import { Box, SxProps, Theme, Typography } from '@mui/material';

import { AvatarWithCircle } from '~/components/molecules/AvatarWithCircle';

interface IUsernameWithAvatarProps {
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  name?: string | null;
  avatar: string | null;
  color?: string | null;
  sx?: SxProps<Theme>;
}

export const UsernameWithAvatar: FC<IUsernameWithAvatarProps> = (props) => {
  const { username, avatar, firstName, lastName, color, name, sx, ...rest } = props;

  const nameStr = firstName || lastName ? `${firstName || ''} ${lastName || ''}`.trim() : username;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      {...rest}>
      <Box>
        <AvatarWithCircle color={color || ''} size={24} src={avatar || ''} />
      </Box>
      <Typography noWrap sx={{ marginLeft: '16px', width: '100%', maxWidth: '200px' }}>
        {name ? name : nameStr}
      </Typography>
    </Box>
  );
};
