import { FC, useState } from 'react';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { Alert, CircularProgress, IconButton, Snackbar } from '@mui/material';
import { AnyAction } from '@reduxjs/toolkit';

interface IDownloadBtnProps {
  onClick?: () => Promise<void>;
}

export const DownloadBtn: FC<IDownloadBtnProps> = (props) => {
  const { onClick } = props;

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const handleClick = async () => {
    setLoading(true);
    const res = await onClick?.();
    setLoading(false);

    if (res && (res as AnyAction).type.includes('rejected')) {
      setOpen(true);
      setErrorMsg((res as AnyAction).error.message);
    }
  };

  return (
    <>
      <IconButton sx={{ color: 'text.primary', position: 'relative' }} onClick={handleClick}>
        {loading ? (
          <CircularProgress
            size={24}
            sx={{
              color: '#fff',
            }}
          />
        ) : (
          <SaveAltIcon />
        )}
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={errorMsg.length * 60 > 1500 ? errorMsg.length * 60 : 1500}
        onClose={() => setOpen(false)}>
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>
    </>
  );
};
