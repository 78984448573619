import React, { FC, useState } from 'react';
import { Box, Checkbox, Chip, Menu, MenuItem, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { fieldNameToFilterTitle } from '~/utils/fieldNameToFilterTitle';

import { TFilter, TFilterData } from '../../types';
import { useStyles } from './styles';

interface IChipWithMenuProps {
  filter: TFilter;
  filtersData: TFilterData;
  filters: TFilter[];
  onDelete: (filter: TFilter) => void;
  handleAddFilter: (filter: TFilter) => void;
}

export const ChipWithMenu: FC<IChipWithMenuProps> = (props) => {
  const { onDelete, filter, filtersData, handleAddFilter, filters } = props;

  const { classes } = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <Box>
      <Chip
        sx={{
          maxWidth: '250px',
          height: '36px',
          backgroundColor: 'background.paper',
          marginLeft: '10px',
          borderRadius: '6px',
        }}
        label={`${fieldNameToFilterTitle(filter.field)}: ${filter.title.replace(/,/g, ', ')}`}
        onDelete={() => onDelete(filter)}
        onClick={handleOpenMenu}
        deleteIcon={<CloseIcon />}
      />
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {filtersData[filter.field]?.map((v) => (
          <MenuItem
            key={v.title + v.id}
            sx={{ minWidth: '150px', maxWidth: '250px' }}
            onClick={() =>
              handleAddFilter({
                ...filter,
                value: v.id,
              })
            }>
            <Checkbox
              className={classes.checkbox}
              checked={!!filters.find((f) => (f.value + '').includes(v.id) && f.field === filter.field)}
            />
            <Typography>{v.title}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
