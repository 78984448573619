import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { GridCellParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';

import { CommonTable } from '~/components/organisms/CommonTable';
import { useAppDispatch } from '~/store';
import { IQueryParams } from '~/components/organisms/CommonTable';
import {
  clear,
  exportWhiteboardMetrics,
  getWhiteboardMetrics,
  getWhiteboardMetricsFilters,
  IUserWhiteboardMetric,
  IWhiteboardMetrics,
  whiteboardMetricsFiltersSelector,
  whiteboardMetricsSelector,
} from '~/store/whiteboardMetrics';
import { Routes } from '~/constants';

import { columns, columnsWithRules } from './columns';
import { useStyles } from './styles';

export const WhiteboardMetrics = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, loading, total } = useSelector(whiteboardMetricsSelector);
  const { data: filtersData } = useSelector(whiteboardMetricsFiltersSelector);

  const handleFetchData = async (queryParams: IQueryParams) => {
    await dispatch(getWhiteboardMetrics(queryParams));
  };

  const handleFetchFilters = async () => {
    await dispatch(getWhiteboardMetricsFilters());
  };

  const handleCleanUp = () => {
    dispatch(clear());
  };

  const handleDownloadCsv = (queryParams: IQueryParams) => {
    return dispatch(exportWhiteboardMetrics(queryParams)) as unknown as Promise<void>;
  };

  const defaultDateRange = useMemo(() => {
    const now = dayjs();
    return {
      from: now.startOf('month').utc(true).format(),
      to: now.endOf('month').utc(true).format(),
    };
  }, []);

  const handleCellClick = (params: GridCellParams<IUserWhiteboardMetric>) => {
    const ids =
      (params.row.whiteboardMetrics[(params.field + 'Ids') as keyof IWhiteboardMetrics] as string[]) || [];

    if (!ids.length) return;

    const filterSet = JSON.stringify({
      filterSet: [
        {
          name: 'id',
          value: ids,
        },
      ],
      conjunction: 'and',
    });

    navigate(Routes.whiteboard + `?filter=${filterSet}&` + '&shared');
  };

  return (
    <Box className={classes.whiteboardMetricsRoot}>
      <Typography sx={{ margin: '32px 0 8px 32px' }} variant="h4">
        Whiteboard Metrics
      </Typography>
      <CommonTable
        kind="whiteboard-metric"
        columnsDef={columns}
        data={data}
        loading={loading}
        total={total ? total : 0}
        fetchData={handleFetchData}
        cleanUp={handleCleanUp}
        columnsWithRules={columnsWithRules}
        filtersData={filtersData}
        fetchFiltersData={handleFetchFilters}
        downloadCsv={handleDownloadCsv}
        hideFooterPagination
        hideFooter
        defaultDateRange={defaultDateRange}
        onCellClick={handleCellClick}
      />
    </Box>
  );
};
