import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Drawer,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import { FormikErrors, useFormik } from 'formik';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { CustomField } from '~/components/molecules/CustomField';
import { CustomSelect } from '~/components/molecules/CustomSelect';
import { CustomDatePicker } from '~/components/molecules/CustomDatePicker';
import { TInterviewRecord, TRecordDrawerState, TRecordForm } from '~/components/organisms/TableToolbar';
import { useAppDispatch } from '~/store';
import {
  addWhiteboardRecord,
  deleteWhiteboardRecord,
  filtersSelector,
  IWhiteboard,
  updateWhiteboardRecord,
} from '~/store/whiteboard';
import { typeOfInterview } from '~/constants/whiteboard';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { theme } from '~/theme';
import { userDataSelector } from '~/store/common';
import { DeleteDialog, IDeleteDialogProps, initDialogState } from '~/components/organisms/DeleteDialog';
import { CloseDateDialog } from '~/components/organisms/TableToolbar/components/CloseDateDialog';

import { useStyles } from './styles';
import { initialValues, validationSchema } from './validationSchema';

interface IRecordDrawerProps {
  recordDrawerState: TRecordDrawerState;
  onClose: () => void;
  updateTable?: () => void;
}

export const RecordDrawer: FC<IRecordDrawerProps> = (props: IRecordDrawerProps) => {
  const { onClose, recordDrawerState, updateTable } = props;
  const { open, type, data } = recordDrawerState;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: filters } = useSelector(filtersSelector);
  const { data: userData } = useSelector(userDataSelector);
  const [numberOfInterviews, setNumberOfInterviews] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleteDialogState, setDeleteDialogState] = useState<IDeleteDialogProps>(initDialogState);
  const [openCloseDateDialog, setOpenCloseDateDialog] = useState(false);

  const formik = useFormik<TRecordForm>({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      if (type === 'add') {
        dispatch(addWhiteboardRecord(values)).then((r) => {
          setLoading(false);

          if (!r.type.includes('rejected')) {
            handleClose();
            updateTable?.();
          }
        });
      }

      if (type === 'edit' && data) {
        const payload = { ...values, id: data.id };
        if (!values.comments) payload.comments = null;

        dispatch(updateWhiteboardRecord(payload)).then((r) => {
          setLoading(false);

          if (!r.type.includes('rejected')) {
            handleClose();
          }
        });
      }
    },
  });

  useEffect(() => {
    if (type === 'edit' && data) {
      formik.setValues({
        candidateName: data.candidateName,
        recruiter: data.recruiter?.id || null,
        company: data.company?.name || null,
        companyOwner: data.companyOwner?.id || null,
        jobTitle: data.jobTitle,
        status: data.status?.id || null,
        interviews: createInterviewArray(data),
        potential: data.potential,
        progress: data.progress && !isNaN(+data.progress) ? data.progress : null,
        byWhenDate: dayjs(data.byWhenDate).isValid() ? dayjs(data.byWhenDate) : null,
        comments: data.comments || '',
      });

      setNumberOfInterviews(createInterviewArray(data).length);
    }
  }, [data]);

  const addInterview = () => {
    setNumberOfInterviews((n) => ++n);
    formik.setFieldValue('interviews', [...formik.values.interviews, { type: '', date: null }]);
  };

  const deleteInterview = () => {
    setNumberOfInterviews((n) => --n);
    formik.setFieldValue('interviews', formik.values.interviews.slice(0, -1));
    setDeleteDialogState(initDialogState);
  };

  const handleClose = () => {
    setNumberOfInterviews(1);
    formik.resetForm({
      values: initialValues,
    });
    onClose();
  };

  const handleDeleteRecord = () => {
    setDeleteDialogState((s) => ({ ...s, loading: true }));
    dispatch(deleteWhiteboardRecord(recordDrawerState.data?.id || '')).then((r) => {
      if (!r.type.includes('rejected')) {
        updateTable?.();
        setDeleteDialogState(initDialogState);
        handleClose();
      } else {
        setDeleteDialogState((s) => ({ ...s, loading: false }));
      }
    });
  };

  const openDeleteInterviewDialog = () => {
    setDeleteDialogState({
      open: true,
      title: 'Delete interview',
      message: 'Are you sure you want to delete this interview?',
      loading: false,
      onClose: () => setDeleteDialogState(initDialogState),
      onDelete: deleteInterview,
    });
  };

  const openDeleteRecordDialog = () => {
    setDeleteDialogState({
      open: true,
      title: 'Delete record',
      message: 'Are you sure you want to delete this record?',
      loading: false,
      onClose: () => setDeleteDialogState(initDialogState),
      onDelete: handleDeleteRecord,
    });
  };

  const handleStatueChange = (e: SelectChangeEvent<string | null>) => {
    formik.handleChange(e);
    const statusName = filters?.status.find((s) => s.id === e.target.value)?.title;

    if (statusName === 'Placed') {
      setOpenCloseDateDialog(true);
    }
  };

  const handleCloseDateUpdate = (date: dayjs.Dayjs | null, potential: string | number | null) => {
    setOpenCloseDateDialog(false);
    formik.setFieldValue('byWhenDate', date);
    formik.setFieldValue('potential', potential);
  };

  const disableInput = !!(recordDrawerState.type === 'edit' && recordDrawerState.data?.bullhornJobOrderId);

  const allowForAdmin = userData?.role === 'admin' || userData?.role === 'god';

  const interviewsRow = useMemo(() => {
    return Array(numberOfInterviews)
      .fill(1)
      .map((n, index) => (
        <Box key={index + n} className={classes.row}>
          <CustomSelect
            disabled={disableInput && !allowForAdmin}
            className={classes.input}
            label="Type of Interview"
            displayEmpty
            required
            name={`interviews[${index}].type`}
            value={formik.values.interviews?.[index]?.type || ''}
            onChange={(e) => formik.setFieldValue(`interviews[${index}].type`, e.target.value)}
            error={
              formik.touched.interviews?.[index]?.type &&
              Boolean((formik.errors.interviews?.[index] as FormikErrors<TInterviewRecord>)?.type)
            }
            helperText={
              formik.touched.interviews?.[index]?.type &&
              (formik.errors.interviews?.[index] as FormikErrors<TInterviewRecord>)?.type
            }
            options={typeOfInterview.map((u) => (
              <MenuItem key={u.id + u.title} value={u.id}>
                {u.title}
              </MenuItem>
            ))}
          />
          <CustomDatePicker
            disabled={disableInput && !allowForAdmin}
            className={classes.input}
            label="Interview Date"
            required
            name={`interviews[${index}].date`}
            value={formik.values.interviews?.[index]?.date || null}
            onChange={(e) => formik.setFieldValue(`interviews[${index}].date`, e)}
            error={
              formik.touched.interviews?.[index]?.date &&
              Boolean((formik.errors.interviews?.[index] as FormikErrors<TInterviewRecord>)?.date)
            }
            helperText={
              formik.touched.interviews?.[index]?.date &&
              (formik.errors.interviews?.[index] as FormikErrors<TInterviewRecord>)?.date
            }
          />
        </Box>
      ));
  }, [
    numberOfInterviews,
    formik.values.interviews,
    formik.touched.interviews,
    formik.errors.interviews,
    disableInput,
    allowForAdmin,
  ]);

  return (
    <Drawer
      className={classes.drawer}
      anchor={'right'}
      open={open}
      onClose={handleClose}
      hideBackdrop={!isMobile}>
      <IconButton disabled={loading} sx={{ position: 'absolute', right: 20, top: 25 }} onClick={handleClose}>
        <CloseIcon sx={{ color: '#BDBDBD' }} />
      </IconButton>
      <Typography className={classes.title}>{type === 'add' ? 'Add record' : 'Edit record'}</Typography>
      <Box className={classes.formBox} component="form" onSubmit={formik.handleSubmit}>
        <Typography className={clsx(classes.subTitle, classes.padding)}>Candidate Information</Typography>
        <CustomField
          disabled={disableInput}
          required
          placeholder="Enter name"
          className={clsx(classes.input, classes.padding)}
          label="Candidate"
          name="candidateName"
          value={formik.values.candidateName}
          onChange={formik.handleChange}
          error={formik.touched.candidateName && Boolean(formik.errors.candidateName)}
          helperText={formik.touched.candidateName && formik.errors.candidateName}
          autoComplete={'off'}
        />
        <CustomSelect
          className={clsx(classes.input, classes.padding)}
          disabled={disableInput}
          label="Candidate Owner"
          displayEmpty
          required
          name="recruiter"
          value={formik.values.recruiter}
          onChange={formik.handleChange}
          error={formik.touched.recruiter && Boolean(formik.errors.recruiter)}
          helperText={formik.touched.recruiter && formik.errors.recruiter}
          options={filters?.recruiter
            ?.filter((r) => r.status?.name === 'active' || r.bullhornId)
            .map((u) => (
              <MenuItem key={u.id + u.title} value={u.id}>
                {u.title}
              </MenuItem>
            ))}
        />
        <Typography className={clsx(classes.subTitle, classes.padding)}>Company Information</Typography>
        <CustomField
          disabled={disableInput}
          className={clsx(classes.input, classes.padding)}
          label="Company"
          required
          placeholder="Enter name"
          name="company"
          value={formik.values.company}
          onChange={formik.handleChange}
          error={formik.touched.company && Boolean(formik.errors.company)}
          helperText={formik.touched.company && formik.errors.company}
        />
        <CustomSelect
          disabled={disableInput}
          className={clsx(classes.input, classes.padding)}
          label="Company Owner"
          displayEmpty
          required
          name="companyOwner"
          value={formik.values.companyOwner}
          onChange={formik.handleChange}
          error={formik.touched.companyOwner && Boolean(formik.errors.companyOwner)}
          helperText={formik.touched.companyOwner && formik.errors.companyOwner}
          options={filters?.recruiter
            ?.filter((r) => r.status?.name === 'active' || r.bullhornId)
            .map((u) => (
              <MenuItem key={u.id + u.title} value={u.id}>
                {u.title}
              </MenuItem>
            ))}
        />
        <Typography className={clsx(classes.subTitle, classes.padding)}>Interview Information</Typography>
        <Box className={classes.row}>
          <CustomField
            disabled={disableInput}
            className={classes.input}
            label="Job"
            required
            placeholder="Enter name"
            name="jobTitle"
            value={formik.values.jobTitle}
            onChange={formik.handleChange}
            error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
            helperText={formik.touched.jobTitle && formik.errors.jobTitle}
          />
          <CustomSelect<string | null>
            disabled={disableInput && !allowForAdmin}
            className={classes.input}
            label="Status"
            displayEmpty
            required
            name="status"
            value={formik.values.status}
            onChange={handleStatueChange}
            error={formik.touched.status && Boolean(formik.errors.status)}
            helperText={formik.touched.status && formik.errors.status}
            options={filters?.status.map((u) => (
              <MenuItem key={u.id + u.title} value={u.id}>
                {u.title}
              </MenuItem>
            ))}
          />
        </Box>
        {interviewsRow}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '0 24px' }}>
          {numberOfInterviews > 1 && (
            <Button
              disabled={disableInput && !allowForAdmin}
              sx={{ marginTop: '-24px' }}
              color="error"
              onClick={openDeleteInterviewDialog}>
              Delete
            </Button>
          )}
          {numberOfInterviews < 3 && (
            <Button
              disabled={disableInput && !allowForAdmin}
              sx={{ marginTop: '-24px' }}
              startIcon={<Add />}
              onClick={addInterview}>
              Add interview
            </Button>
          )}
        </Box>
        <Box className={classes.row}>
          <CustomField
            className={classes.input}
            label="Potential"
            required
            placeholder="Enter amount"
            name="potential"
            value={formik.values.potential}
            onChange={formik.handleChange}
            error={formik.touched.potential && Boolean(formik.errors.potential)}
            helperText={formik.touched.potential && formik.errors.potential}
          />
          <CustomField
            className={classes.input}
            label="% to Close"
            required
            placeholder="Enter amount"
            name="progress"
            value={formik.values.progress}
            onChange={formik.handleChange}
            error={formik.touched.progress && Boolean(formik.errors.progress)}
            helperText={formik.touched.progress && formik.errors.progress}
          />
        </Box>
        <CustomDatePicker
          className={classes.byWhenDate}
          label="By When"
          required
          name="byWhenDate"
          value={formik.values.byWhenDate}
          onChange={(v) => formik.setFieldValue('byWhenDate', v)}
          error={formik.touched.byWhenDate && Boolean(formik.errors.byWhenDate)}
          helperText={formik.touched.byWhenDate && formik.errors.byWhenDate}
        />
        <CustomField
          className={clsx(classes.input, classes.padding)}
          label="Comments"
          required
          multiline
          rows={5}
          placeholder="Enter comments"
          name="comments"
          value={formik.values.comments}
          onChange={formik.handleChange}
          error={formik.touched.comments && Boolean(formik.errors.comments)}
          helperText={formik.touched.comments && formik.errors.comments}
        />
        {recordDrawerState.type === 'edit' && recordDrawerState.data?.bullhornCandidateId && (
          <Link
            className={clsx(classes.bullhornLink, classes.padding)}
            to={`https://cls43.bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${recordDrawerState.data?.bullhornCandidateId}`}
            target="_blank">
            Bullhorn link
          </Link>
        )}
        <Box className={classes.actionBox}>
          {['admin', 'god'].includes(userData?.role || '') && recordDrawerState.type === 'edit' && (
            <Button
              color="error"
              variant="contained"
              disabled={loading}
              sx={{ marginRight: '10px' }}
              onClick={openDeleteRecordDialog}>
              Delete
            </Button>
          )}
          <Button disabled={loading} sx={{ marginRight: '10px' }} onClick={handleClose}>
            Cancel
          </Button>
          <ButtonWithLoading disabled={loading} loading={loading} variant="contained" type="submit">
            {recordDrawerState.type === 'add' ? 'Add' : 'Save'}
          </ButtonWithLoading>
        </Box>
      </Box>
      <DeleteDialog
        open={deleteDialogState.open}
        loading={deleteDialogState.loading}
        title={deleteDialogState.title}
        message={deleteDialogState.message}
        onClose={deleteDialogState.onClose}
        onDelete={deleteDialogState.onDelete}
      />
      <CloseDateDialog
        open={openCloseDateDialog}
        onUpdate={handleCloseDateUpdate}
        onClose={() => setOpenCloseDateDialog(false)}
        currentPotential={formik.values.potential}
      />
    </Drawer>
  );
};

const createInterviewArray = (data: IWhiteboard | null) => {
  const interviews: TInterviewRecord[] = [];

  if (!data) return interviews;

  interviews.push({
    type: data.firstInterviewType,
    date: dayjs(data.firstInterviewDate),
  });
  if (data.secondInterviewType || data.secondInterviewDate)
    interviews.push({
      type: data.secondInterviewType,
      date: dayjs(data.secondInterviewDate),
    });
  if (data.thirdInterviewType || data.thirdInterviewDate)
    interviews.push({
      type: data.thirdInterviewType,
      date: dayjs(data.thirdInterviewDate),
    });

  return interviews;
};
