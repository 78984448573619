export const typeOfInterview = [
  {
    id: 'Phone',
    title: 'Phone',
  },
  {
    id: 'Webinar',
    title: 'Webinar',
  },
  {
    id: 'Inperson',
    title: 'In-person',
  },
];

export const interviews = [
  ['firstInterviewType', 'firstInterviewDate'],
  ['secondInterviewType', 'secondInterviewDate'],
  ['thirdInterviewType', 'thirdInterviewDate'],
];
