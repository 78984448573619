import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Storage } from 'aws-amplify';

import { userDataSelector } from '~/store/common';
import { Routes } from '~/constants';
import { useAppDispatch } from '~/store';

export const Playground = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data } = useSelector(userDataSelector);

  const [src, setSrc] = useState('');

  useEffect(() => {
    if (!['admin', 'god'].includes(data?.role || '')) {
      navigate(Routes.root);
    }
  }, []);

  useEffect(() => {
    const key = 'avatar-default';

    Storage.get(key).then((result) => {
      setSrc(result);
    });
  }, []);

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    try {
      file &&
        (await Storage.put(file.name, file, {
          level: 'public',
        }));
    } catch (error) {
      console.log('Error uploading file: ', error);
    }
  }

  const handleUpdate = () => {
    // dispatch(updateAvatar('test2'));
  };

  return (
    <div>
      <h1>Playground</h1>
      <input type="file" onChange={onChange} />;
      <img src={src} alt="" />
      <Button onClick={handleUpdate}>Update avatar</Button>
    </div>
  );
};
