import { FC, ReactNode } from 'react';
import { Paper, SxProps, Theme } from '@mui/material';

export interface IWidgetPaperProps {
  children: ReactNode;
  className?: string;
  sx?: SxProps<Theme> | undefined;
}

export const WidgetPaper: FC<IWidgetPaperProps> = (props) => {
  const { children, sx, ...rest } = props;

  return (
    <Paper
      sx={{
        position: 'relative',
        ...sx,
        borderRadius: '8px',
      }}
      {...rest}>
      {children}
    </Paper>
  );
};
