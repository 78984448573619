import { FC, memo } from 'react';
import { FormControl, FormHelperText, InputLabel, OutlinedInput, TextFieldProps } from '@mui/material';

import { useStyles } from './styles';

export const CustomField: FC<TextFieldProps> = memo((props) => {
  const {
    label,
    helperText,
    title,
    defaultValue,
    fullWidth,
    required,
    className,
    error,
    onChange,
    onFocus,
    onBlur,
    type,
    autoComplete,
    name,
    value,
    placeholder,
    sx,
    multiline,
    disabled,
    rows,
  } = props;

  const { classes } = useStyles();

  return (
    <FormControl sx={sx} className={className} fullWidth={fullWidth} error={error}>
      <InputLabel required={required} htmlFor={title} className={classes.label} shrink variant={'standard'}>
        {label}
      </InputLabel>
      <OutlinedInput
        disabled={disabled}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        fullWidth={fullWidth}
        className={classes.input}
        notched={false}
        id={title}
        defaultValue={defaultValue}
        title={title}
        error={error}
        type={type}
        autoComplete={autoComplete}
        value={value}
        placeholder={placeholder}
        multiline={multiline}
        rows={rows}
      />
      {error ? (
        <FormHelperText id="my-helper-text" className={classes.helperText} error={error}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
});

CustomField.displayName = 'CustomField';
