import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  candidateName: Yup.string().required('Required'),
  recruiter: Yup.string().required('Required'),
  company: Yup.string().required('Required'),
  companyOwner: Yup.string().required('Required'),
  jobTitle: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  interviews: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required('Required'),
        date: Yup.date().required('Required'),
      })
    )
    .min(1, 'At least one interview is required'),
  potential: Yup.number().typeError('Potential must be a number').required('Required'),
  progress: Yup.number()
    .integer('Progress must be a number')
    .min(0, 'Progress must be between 0 and 100')
    .max(100, 'Progress must be between 0 and 100')
    .typeError('Progress must be a number')
    .required('Required'),
  byWhenDate: Yup.date().required('Required'),
  comments: Yup.string().max(1000, 'Comments must be less than 1000 characters'),
});

export const initialValues = {
  candidateName: '',
  recruiter: '',
  company: '',
  companyOwner: '',
  jobTitle: '',
  status: '',
  interviews: [{ type: '', date: null }],
  potential: '',
  progress: '',
  byWhenDate: null,
  comments: '',
};
