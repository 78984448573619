import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '8px 16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  actionBox: {
    display: 'flex',
    gap: '20px',
  },
  conditionBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '16px',
    width: '100%',
  },
  conditionGroupBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '16px',
    borderRadius: '4px',
    flexDirection: 'column',
  },
  conditionGroupBorder: {
    border: '1px solid rgba(224,224,224,0.62)',
  },
  whereLabel: {
    width: '80px',
    textAlign: 'right',
  },
  input: {
    width: '150px',
  },
}));
