import React, { FC, useState } from 'react';
import { Box, Button, Menu } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';

import { TKind } from '~/components/organisms/CommonTable';

import { TFilter, TFilterData } from '../../types';
import { useStyles } from './styles';
import { FilterMenuItem } from '../FilterMenuItem';
import { NewFiltersMenu } from '../NewFiltersMenu';
import { IWhiteboardFilterConditionSet } from '../NewFiltersMenu/types';

interface IFiltersProps {
  filters: TFilter[];
  filtersData: TFilterData;
  addFilter: (filter: TFilter) => void;
  kind?: TKind;
  newFilters?: IWhiteboardFilterConditionSet;
  updateNewFilters?: (filterState: IWhiteboardFilterConditionSet) => void;
}

export const Filters: FC<IFiltersProps> = (props) => {
  const { filters, addFilter, filtersData, kind, newFilters, updateNewFilters } = props;

  const { classes } = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseFirstLevelMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <Box className={classes.root}>
      <Button
        className={classes.addFilterBtn}
        startIcon={<FilterListIcon />}
        variant="outlined"
        onClick={handleOpenMenu}>
        Filter
      </Button>
      {kind === 'whiteboard' && newFilters && updateNewFilters ? (
        <NewFiltersMenu
          anchorEl={menuAnchor}
          open={!!menuAnchor}
          onClose={handleCloseFirstLevelMenu}
          filtersData={filtersData}
          filterState={newFilters}
          updateFilterState={updateNewFilters}
        />
      ) : (
        <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseFirstLevelMenu}>
          {Object.entries(filtersData).map(([field, data]) =>
            data ? (
              <FilterMenuItem
                key={field}
                field={field}
                data={data}
                addFilter={addFilter}
                filters={filters}
                onClose={handleCloseFirstLevelMenu}
              />
            ) : null
          )}
        </Menu>
      )}
    </Box>
  );
};
