import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { INote } from './types';
import { sliceName } from './actionTypes';
import { getNotes } from './thunks';

export interface NoteState {
  notes: {
    data: INote[] | null;
    loading: boolean;
  };
}

const initialState: NoteState = {
  notes: {
    data: null,
    loading: false,
  },
};

export const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state) => {
      state.notes.loading = true;
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.notes.data = action.payload;
      state.notes.loading = false;
    });
    builder.addCase(getNotes.rejected, (state) => {
      state.notes.loading = false;
    });
  },
});

export const notesSelector = (state: RootState) => state.note.notes;

export const noteReducer = slice.reducer;
