import { FC, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  Box,
  Button,
  Dialog,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { CustomSelect } from '~/components/molecules/CustomSelect';
import { CustomField } from '~/components/molecules/CustomField';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { CustomDatePicker } from '~/components/molecules/CustomDatePicker';
import { useAppDispatch } from '~/store';
import { addToMailingList, TFrequency, TMailingListItemBody, TTimeframe } from '~/store/metric';

import { useStyles } from './styles';

const validationSchema = Yup.object({
  reportTimeframe: Yup.string().required('Timeframe is required'),
  recipient: Yup.string().email('Invalid email address').required('Recipient is required'),
  recurring: Yup.boolean(),
  frequency: Yup.string().when('recurring', {
    is: true,
    then: (schema) => schema.required('Frequency is required'),
    otherwise: (schema) => schema,
  }),
  day: Yup.string().when('recurring', {
    is: true,
    then: (schema) => schema.required('Day is required'),
    otherwise: (schema) => schema,
  }),
  startDate: Yup.date()
    .nullable()
    .typeError('Start Date must be a date')
    .when('recurring', {
      is: true,
      then: (schema) => schema.required('Start date is required'),
      otherwise: (schema) => schema,
    }),
  endDate: Yup.date()
    .nullable()
    .typeError('End Date must be a date')
    .when('recurring', {
      is: true,
      then: (schema) => schema.required('End date is required'),
      otherwise: (schema) => schema,
    }),
});

const initialValues = {
  reportTimeframe: '' as TTimeframe,
  recipient: '',
  body: '',
  recurring: false,
  frequency: '' as TFrequency,
  day: '',
  startDate: null,
  endDate: null,
};

export const EmailForm: FC = () => {
  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const formik = useFormik<TMailingListItemBody>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setSending(true);
      dispatch(addToMailingList(values)).then((r) => {
        if (!r.type.includes('rejected')) {
          setSending(false);
          handleClose();
        }
      });
    },
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setSending(false);
  };

  return (
    <>
      <IconButton sx={{ color: 'text.primary' }} onClick={() => setOpen(true)}>
        <MailOutlineIcon />
      </IconButton>
      <Dialog className={classes.dialog} open={open} onClose={handleClose}>
        <Box className={classes.root} component="form" onSubmit={formik.handleSubmit}>
          <Typography className={classes.title}>Email</Typography>
          <IconButton sx={{ position: 'absolute', right: 16, top: 22 }} onClick={() => setOpen(false)}>
            <CloseIcon sx={{ color: '#BDBDBD' }} />
          </IconButton>
          <Typography>Send the report data in an email to share your team performance.</Typography>
          <CustomSelect
            className={classes.input}
            sx={{ width: '175px' }}
            label="Timeframe of Report"
            displayEmpty
            required
            name="reportTimeframe"
            value={formik.values.reportTimeframe}
            onChange={formik.handleChange}
            error={formik.touched.reportTimeframe && Boolean(formik.errors.reportTimeframe)}
            helperText={formik.touched.reportTimeframe && formik.errors.reportTimeframe}
            options={listOfTimeframes.map((t) => (
              <MenuItem key={t.id + t.title} value={t.title}>
                {t.title}
              </MenuItem>
            ))}
          />
          <CustomField
            className={classes.input}
            label="Recipient"
            required
            fullWidth
            name="recipient"
            value={formik.values.recipient}
            onChange={formik.handleChange}
            error={formik.touched.recipient && Boolean(formik.errors.recipient)}
            helperText={formik.touched.recipient && formik.errors.recipient}
          />
          <CustomField
            className={classes.input}
            label="Body"
            fullWidth
            multiline
            name="body"
            value={formik.values.body}
            onChange={formik.handleChange}
            error={formik.touched.body && Boolean(formik.errors.body)}
            helperText={formik.touched.body && formik.errors.body}
          />
          <FormControlLabel
            className={classes.input}
            control={
              <Switch name="recurring" value={formik.values.recurring} onChange={formik.handleChange} />
            }
            label="Set recurring emails"
          />
          {formik.values.recurring && (
            <>
              <Box className={classes.row}>
                <CustomSelect
                  sx={{ width: '212px' }}
                  label="Email frequency"
                  displayEmpty
                  required
                  name="frequency"
                  value={formik.values.frequency}
                  onChange={formik.handleChange}
                  error={formik.touched.frequency && Boolean(formik.errors.frequency)}
                  helperText={formik.touched.frequency && formik.errors.frequency}
                  options={mockFrequency.map((f) => (
                    <MenuItem key={f.id + f.title} value={f.title}>
                      {f.title}
                    </MenuItem>
                  ))}
                />
                <CustomSelect
                  sx={{ width: '212px' }}
                  label="Day"
                  displayEmpty
                  required
                  name="day"
                  value={formik.values.day}
                  onChange={formik.handleChange}
                  error={formik.touched.day && Boolean(formik.errors.day)}
                  helperText={formik.touched.day && formik.errors.day}
                  options={
                    formik.values.frequency === 'Monthly'
                      ? monthlyDays.map((d) => (
                          <MenuItem key={d.id + d.title} value={d.title}>
                            {d.title}
                          </MenuItem>
                        ))
                      : days.map((d) => (
                          <MenuItem key={d.id + d.title} value={d.title}>
                            {d.title}
                          </MenuItem>
                        ))
                  }
                />
              </Box>
              <Box className={classes.row}>
                <CustomDatePicker
                  sx={{ width: '212px' }}
                  label="Start Date"
                  name="startDate"
                  value={formik.values.startDate}
                  onChange={(e) => formik.setFieldValue('startDate', e)}
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                />
                <CustomDatePicker
                  sx={{ width: '212px' }}
                  name="endDate"
                  label="End Date"
                  value={formik.values.endDate}
                  onChange={(e) => formik.setFieldValue('endDate', e)}
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                />
              </Box>
            </>
          )}
          <Box className={classes.row} sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <ButtonWithLoading loading={sending} variant="contained" type="submit">
              Send
            </ButtonWithLoading>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const listOfTimeframes = [
  {
    id: 'dfg5',
    title: 'Today',
  },
  {
    id: 'dfg6',
    title: 'Yesterday',
  },
  {
    id: 'dfg7',
    title: 'Current Week',
  },
  {
    id: 'dfg8',
    title: 'Last Week',
  },
  {
    id: 'dfg9',
    title: 'Current Month',
  },
  {
    id: 'dfg10',
    title: 'All Time',
  },
];

const mockFrequency = [
  {
    id: 'dfg4',
    title: 'Weekly',
  },
  {
    id: 'dfg5',
    title: 'Bi-Weekly',
  },
  {
    id: 'dfg6',
    title: 'Monthly',
  },
];

const days = [
  {
    id: 'fgefe1',
    title: 'Monday',
  },
  {
    id: 'fgefe2',
    title: 'Tuesday',
  },
  {
    id: 'fgefe3',
    title: 'Wednesday',
  },
  {
    id: 'fgefe4',
    title: 'Thursday',
  },
  {
    id: 'fgefe5',
    title: 'Friday',
  },
];

const monthlyDays = [
  {
    id: 'fgefe1',
    title: '1st of each month',
  },
  {
    id: 'fgefe2',
    title: '15th of each month',
  },
  {
    id: 'fgefe3',
    title: 'Last day of each month',
  },
];
