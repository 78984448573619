import { IMetric, ITotals } from './types';

export const formatTotals = (data: IMetric[] | null): ITotals | null => {
  if (!data) return null;

  const acc = {
    username: 'Totals',
    id: 'Totals',
    avatar: '',
    firstName: '',
    lastName: '',
    name: 'Totals',
    color: '',
    touches: 0,
    phoneScreens: 0,
    followUps: 0,
    sentCandidateToClientOwner: 0,
    subToClient: 0,
    firstInterview: 0,
    offerOut: 0,
    placement: 0,
    sentFreeAgreement: 0,
    newClient: 0,
    jobOrder: 0,
    clientOwnerSubToClient: 0,
    clientOwnerInterview: 0,
    clientOwnerOfferOut: 0,
    clientOwnerPlacement: 0,
    totalActivity: 0,
  } as ITotals;

  return data.reduce((acc, curr) => {
    (Object.keys(curr) as Array<keyof IMetric>).forEach((key) => {
      if (
        key !== 'id' &&
        key !== 'username' &&
        key !== 'avatar' &&
        key !== 'firstName' &&
        key !== 'lastName' &&
        key !== 'name' &&
        key !== 'color'
      ) {
        acc[key] += curr[key];
      }
    });
    return acc;
  }, acc) as ITotals;
};
