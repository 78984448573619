import { FC } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { IHistoryData } from '~/store/dashboard';
import BullhornLogo from '~/assets/bullhorn-logo.png';

import { AvatarWithCircle } from '../AvatarWithCircle';

interface IHistoryItemProps {
  data: IHistoryData;
  link?: string;
}

export const HistoryItem: FC<IHistoryItemProps> = (props) => {
  const { data, link } = props;

  const theme = useTheme();

  const { source, field, newValue, candidateName, user } = data;
  const { firstName, lastName, color } = user || {};

  const isExternal = source === 'external';

  const avatar = isExternal ? BullhornLogo : user?.avatar;

  const name = isExternal
    ? user
      ? `${firstName} ${lastName || ''}`
      : 'Bullhorn'
    : `${firstName} ${lastName || ''}`;

  return (
    <Link to={link || ''} style={{ textDecoration: 'none' }}>
      <Box sx={{ display: 'flex', padding: '0 0 8px 24px' }}>
        <Box>
          <AvatarWithCircle src={avatar} color={isExternal ? '' : color} size={24} />
        </Box>
        <Box sx={{ marginLeft: '20px' }}>
          <Typography sx={{ lineHeight: '17px', wordBreak: 'break-all' }}>
            {name + ' edited "' + getFieldTitle(field) + '" to ' + valueFormat(field, newValue)}
          </Typography>
          <Typography
            sx={{ lineHeight: '17px', color: theme.palette.text.disabled, textTransform: 'capitalize' }}>
            {candidateName}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
};

const getFieldTitle = (field: string) => {
  switch (field) {
    case 'candidateName':
      return 'Candidate Name';
    case 'byWhenDate':
      return 'By When Date';
    case 'firstInterviewDate':
      return 'First Interview Date';
    case 'secondInterviewDate':
      return 'Second Interview Date';
    case 'thirdInterviewDate':
      return 'Third Interview Date';
    case 'firstInterviewType':
      return 'First Interview Type';
    case 'secondInterviewType':
      return 'Second Interview Type';
    case 'thirdInterviewType':
      return 'Third Interview Type';
    case 'jobTitle':
      return 'Job Title';
    default:
      return field.charAt(0).toUpperCase() + field.slice(1);
  }
};

const valueFormat = (field: string, value: string) => {
  switch (field) {
    case 'byWhenDate':
    case 'firstInterviewDate':
    case 'secondInterviewDate':
    case 'thirdInterviewDate':
      return dayjs(value).format('MM/DD/YYYY');
    case 'progress':
      return (+value * 100).toFixed() + '%';
    case 'comments':
      return value.length > 120 ? value.slice(0, 120) + '...' : value;
    default:
      return value;
  }
};
