import { FC } from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

import { useStyles } from './styles';

export const progressSize = 24;

export const ButtonWithLoading: FC<ButtonProps & { loading?: boolean }> = (props) => {
  const { children, loading, disabled, ...rest } = props;

  const { classes } = useStyles();

  return (
    <Button
      disabled={disabled || loading}
      sx={loading ? { color: 'rgba(0,0,0,0) !important' } : {}}
      {...rest}>
      {children}
      {loading ? <CircularProgress size={progressSize} className={classes.buttonProgress} /> : null}
    </Button>
  );
};
