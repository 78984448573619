import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { initLoadingSelector, userDataSelector } from '~/store/common';

// This component is used to load the Pendo script and initialize the Pendo SDK within Redux context.
export const PendoIntegration = () => {
  const initLoading = useSelector(initLoadingSelector);
  const { data } = useSelector(userDataSelector);

  useEffect(() => {
    const allowedDomain = 'www.in-board.io';

    if (window.location.hostname !== allowedDomain) return;

    if (!initLoading) {
      initPendo(data?.sub);
      console.log('Pendo has been initialized');
    }
  }, [data, initLoading]);

  return null;
};

const initPendo = (sub?: string) => {
  window.pendo?.initialize({
    visitor: {
      id: sub || 'VISITOR-UNIQUE-ID', // Required if user is logged in, default creates anonymous ID
      // email:        // Recommended if using Pendo Feedback, or NPS Email
      // full_name:    // Recommended if using Pendo Feedback
      // role:         // Optional

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      // name:         // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional
      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  });
};
