import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  chip: {
    maxWidth: '250px',
    height: '36px',
    backgroundColor: theme.palette.background.paper,
    marginLeft: '10px',
    borderRadius: '6px',
  },
}));
