export enum Routes {
  root = '/',
  dashboard = '/dashboard',
  comingSoon = '/coming-soon',
  signIn = '/sign-in',
  whiteboard = '/whiteboard',
  whiteboardMetrics = '/whiteboard-metrics',
  metrics = '/metrics',
  settings = '/settings',
  signUp = '/sign-up',
  playground = '/playground',
  userManagement = '/user-management',
  notFound = '*',
  whiteboardInsights = '/whiteboard-insights',
  serviceMonitoring = '/service-monitoring',
}
