import { Theme } from '@mui/material';
import { CSSObject } from 'tss-react';

import { makeStyles } from '~/theme';

export const visibilityDrawerWidth = 395;
export const drawerWidthCollapsed = 0;

export const useStyles = makeStyles<{ open: boolean }>()((theme, { open }) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      top: 'auto',
      bottom: 0,
      minWidth: visibilityDrawerWidth + 'px',
      [theme.breakpoints.down('sm')]: {
        width: '75%',
        minWidth: 'auto',
      },
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  },
  menuBox: {
    height: '100%',
    position: 'relative',
  },
  menuTitle: {
    fontSize: '16px',
    fontWeight: 600,
    padding: '20px 0 18px 24px',
  },
  menuSubtitle: {
    padding: '20px 0 18px 24px',
  },
  closeBtn: {
    position: 'absolute',
    top: '8px',
    right: '4px',
  },
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: visibilityDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  marginTop: '64px',
  height: 'calc(100% - 64px)',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `${drawerWidthCollapsed}px`,
  height: '0',
});
