import { TFilter, TFilterData } from '~/components/organisms/TableToolbar';

export const transformFilterArrToStr = (filterArr: TFilter[]): string => {
  return filterArr
    .map((f) => {
      const { field, value } = f;
      return `${field}:${value}`;
    })
    .join(';');
};

export const transformFilterStrToArr = (filterStr: string, filtersData: TFilterData | null): TFilter[] => {
  return filtersData
    ? filterStr.split(';').map((f) => {
        const [field, value] = f.split(':');
        const result = { field: field as keyof TFilterData, value, title: '' };

        if (filtersData[field as keyof TFilterData]) {
          result.title = filtersData[field as keyof TFilterData]!.filter((fd) =>
            value.includes(fd.id)
          ).reduce((acc, fd) => {
            if (acc.length > 0) return acc + ', ' + fd.title;
            return acc + fd.title;
          }, '');
        }

        return result;
      })
    : [];
};
