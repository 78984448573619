import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';
import { IMetric, IMetricQueries } from '~/store/metric';
import { IWhiteboardQueries } from '~/store/whiteboard';
import { RootState } from '~/store';

import {
  getPipelineValueActionType,
  getRevenueActionType,
  getTopActivityActionType,
  getTopCandidatesOutActionType,
  getTopNewClientsActionType,
  getTopOffersActionType,
  getTopPhoneScreensActionType,
  getTopPlacementsActionType,
  getWhiteboardHistoryActionType,
} from './actionTypes';
import { IRevenueWidgetData, IWidgetData, TPeriod } from './types';

export const getTopPhoneScreens = createAsyncThunk(getTopPhoneScreensActionType, async (period: TPeriod) => {
  const queries: IMetricQueries = {
    sort: 'phoneScreens',
    order: 'DESC',
    limit: '3',
  };
  if (period.from && period.to) {
    queries.from = period.from;
    queries.to = period.to;
  }
  const res = await API.get(apiName, Endpoints.metric, {
    queryStringParameters: { ...queries, widget: true },
  });
  return (res.data as IMetric[]).map((d) => ({
    id: d.id,
    username: d.username,
    metric: d.phoneScreens,
    avatar: d.avatar,
    firstName: d.firstName,
    lastName: d.lastName,
    color: d.color,
  }));
});

export const getTopPlacements = createAsyncThunk(getTopPlacementsActionType, async (period: TPeriod) => {
  const queries: IMetricQueries = {};

  if (period.from && period.to) {
    queries.from = period.from;
    queries.to = period.to;
  }

  const res = await API.get(apiName, Endpoints.whiteboardWidget.replace(':kind', 'placements'), {
    queryStringParameters: queries,
  });
  return {
    data: (res.data as IWidgetData[]).map((d) => ({
      id: d.id,
      username: d.username,
      metric: d.score || 0,
      whiteboardIds: d.whiteboardIds,
      avatar: d.avatar,
      firstName: d.firstName,
      lastName: d.lastName,
      color: d.color,
    })),
  };
});

export const getTopNewClients = createAsyncThunk(getTopNewClientsActionType, async (period: TPeriod) => {
  const queries: IMetricQueries = {
    sort: 'newClient',
    order: 'DESC',
    limit: '3',
  };
  if (period.from && period.to) {
    queries.from = period.from;
    queries.to = period.to;
  }

  const res = await API.get(apiName, Endpoints.metric, {
    queryStringParameters: { ...queries, widget: true },
  });
  return (res.data as IMetric[]).map((d) => ({
    id: d.id,
    username: d.username,
    metric: d.newClient,
    avatar: d.avatar,
    firstName: d.firstName,
    lastName: d.lastName,
    color: d.color,
  }));
});

export const getTopActivity = createAsyncThunk(getTopActivityActionType, async (period: TPeriod) => {
  const queries: IMetricQueries = {
    sort: 'touches',
    order: 'DESC',
    limit: '3',
  };
  if (period.from && period.to) {
    queries.from = period.from;
    queries.to = period.to;
  }

  const res = await API.get(apiName, Endpoints.metric, {
    queryStringParameters: { ...queries, widget: true },
  });
  return (res.data as IMetric[]).map((d) => ({
    id: d.id,
    username: d.username,
    metric: d.touches,
    avatar: d.avatar,
    firstName: d.firstName,
    lastName: d.lastName,
    color: d.color,
  }));
});

export const getTopOffers = createAsyncThunk(getTopOffersActionType, async (period: TPeriod) => {
  const queries: IMetricQueries = {};

  if (period.from && period.to) {
    queries.from = period.from;
    queries.to = period.to;
  }

  const res = await API.get(apiName, Endpoints.whiteboardWidget.replace(':kind', 'offers'), {
    queryStringParameters: queries,
  });
  return {
    data: (res.data as IWidgetData[]).map((d) => ({
      id: d.id,
      username: d.username,
      metric: d.score || 0,
      whiteboardIds: d.whiteboardIds,
      avatar: d.avatar,
      firstName: d.firstName,
      lastName: d.lastName,
      color: d.color,
    })),
  };
});

export const getTopCandidatesOut = createAsyncThunk<
  { data: IWidgetData[]; querySting: string },
  void,
  { state: RootState }
>(getTopCandidatesOutActionType, async (_, { getState }) => {
  const { whiteboard } = getState();

  const queries = {
    status: whiteboard.filters.data?.status
      .filter((s) => ['Interviewing'].includes(s.title))
      .map((s) => s.id)
      .join(','),
  };

  const result = await API.get(apiName, Endpoints.whiteboardWidget.replace(':kind', 'candidates-out'), {
    queryStringParameters: queries,
  });
  result.querySting = 'status:' + queries.status;

  return result;
});

export const getRevenue = createAsyncThunk<IRevenueWidgetData, TPeriod, { state: RootState }>(
  getRevenueActionType,
  async (period, { getState }) => {
    const { whiteboard } = getState();

    const queries: IWhiteboardQueries = {
      filter: 'status:' + whiteboard.filters.data?.status.find((f) => f.title === 'Placed')?.id || '',
    };

    if (period.from && period.to) {
      queries.from = period.from;
      queries.to = period.to;
    }

    const res = await API.get(apiName, Endpoints.whiteboardWidget.replace(':kind', 'revenue'), {
      queryStringParameters: queries,
    });
    return res.data;
  }
);

export const getPipelineValue = createAsyncThunk<number, TPeriod, { state: RootState }>(
  getPipelineValueActionType,
  async (period, { getState }) => {
    const { whiteboard } = getState();

    const queries: IWhiteboardQueries = {
      filter:
        'status:' +
          whiteboard.filters.data?.status
            .filter((f) => f.title === 'Interviewing' || f.title === 'Offer')
            .map((s) => s.id)
            .join(',') || '',
    };

    if (period.from && period.to) {
      queries.from = period.from;
      queries.to = period.to;
    }

    const res = await API.get(apiName, Endpoints.whiteboard, { queryStringParameters: queries });
    return res.data.reduce((acc: number, cur: { potential: number }) => acc + cur.potential, 0);
  }
);

export const getWhiteboardHistory = createAsyncThunk(
  getWhiteboardHistoryActionType,
  async (queries: { limit: number }) => {
    return await API.get(apiName, Endpoints.whiteboardHistory, { queryStringParameters: queries });
  }
);
