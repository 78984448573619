import { GridColDef } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { UsernameWithAvatar } from '~/components/molecules/UsernameWithAvatar';
import { IUser } from '~/store/admin';

export const columns: GridColDef<IUser>[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 250,
    renderCell: ({ row }) => {
      return (
        <UsernameWithAvatar
          username={row.username}
          avatar={row.avatar}
          firstName={row.firstName}
          lastName={row.lastName}
          color={row.color}
        />
      );
    },
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
    renderCell: ({ row }) => <Typography sx={{ textTransform: 'capitalize' }}>{row.role?.name}</Typography>,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 250,
  },
  {
    field: 'createdAt',
    headerName: 'Date Added',
    width: 150,
    renderCell: ({ row }) => <Typography>{dayjs(row.createdAt).format('MM/DD/YYYY')}</Typography>,
  },
  {
    field: 'lastLogin',
    headerName: 'Last Logged In',
    width: 150,
    renderCell: ({ row }) => (
      <Typography>{row.lastLogin ? dayjs(row.lastLogin).format('MM/DD/YYYY') : '-'}</Typography>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    renderCell: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Typography sx={{ color: row.status?.name === 'active' ? '#00C851' : '#FF4444' }}>●</Typography>
        <Typography sx={{ textTransform: 'capitalize' }}>{row.status?.name}</Typography>
      </Box>
    ),
  },
  {
    field: 'bullhornId',
    headerName: 'Bullhorn ID',
    width: 150,
  },
];
