import { IMetricQueries } from '~/store/metric';

export const listOfQueryParams = [
  'from',
  'to',
  'sort',
  'order',
  'filter',
  'page',
  'limit',
  'rules',
  'shared',
  'search',
] as Array<keyof IMetricQueries>;
