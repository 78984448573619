import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

import { Routes } from '~/constants';

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography
        variant="h4"
        sx={{
          marginBottom: '20px',
        }}>
        The page you requested could not be found
      </Typography>
      <Button variant="contained" onClick={() => navigate(Routes.dashboard)}>
        Go to Dashboard
      </Button>
    </Box>
  );
};
