import { Box, MenuItem, Select, SxProps, Typography, useMediaQuery } from '@mui/material';

import { theme } from '~/theme';

import { ITimePeriod, TPeriodId } from '../../types';
import { useStyles } from './styles';

interface ITimePeriodSelectorProps {
  value?: TPeriodId;
  options: ITimePeriod[];
  onChange: (value: TPeriodId) => void;
  label?: string;
  labelStyle?: SxProps;
  sx?: SxProps;
}

export const TimePeriodSelector = (props: ITimePeriodSelectorProps) => {
  const { value, options, onChange, label, labelStyle, sx } = props;
  const { classes } = useStyles();
  const hideLabel = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      {hideLabel ? null : (
        <Typography color="text.disabled" sx={labelStyle}>
          {label ? label : 'Show:'}
        </Typography>
      )}
      <Select
        className={classes.selectRoot}
        value={value}
        onChange={(e) => onChange(e.target.value as TPeriodId)}
        inputProps={{ 'aria-label': 'Without label' }}>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
