import { DateRange, TFilterData } from '~/components/organisms/TableToolbar';
import { TPeriodId } from '~/pages/Dashboard/types';

export interface IWhiteboardFilterConditionSet {
  filterSet: Array<IWhiteboardFilterCondition | IWhiteboardFilterConditionSet>;
  conjunction: 'and' | 'or';
}

export interface IWhiteboardFilterCondition {
  name: keyof TFilterData;
  value?: string[] | string | number;
  operator?: TOperator;
  dateRange?: DateRange;
  periodId?: TPeriodId;
}

export type TOperator = '=' | '<' | '>' | '<=' | '>=';

export const isWhiteboardFilter = (
  test: IWhiteboardFilterConditionSet | IWhiteboardFilterCondition
): test is IWhiteboardFilterCondition => {
  return (test as IWhiteboardFilterCondition).name !== undefined;
};

export const isWhiteboardFilterSet = (
  test: IWhiteboardFilterConditionSet | IWhiteboardFilterCondition
): test is IWhiteboardFilterConditionSet => {
  return (test as IWhiteboardFilterConditionSet).filterSet !== undefined;
};
