import { makeStyles } from '~/theme';

import { progressSize } from './ButtonWithLoading';

export const useStyles = makeStyles()(() => ({
  buttonProgress: {
    position: 'absolute',
    top: `calc(50% - ${progressSize / 2}px)`,
    left: `calc(50% - ${progressSize / 2}px)`,
    color: '#fff',
  },
}));
