import { Auth } from 'aws-amplify';

import awsmobile from '~/aws-exports';

export const apiName = 'api';
export const publicApiName = 'apiPublic';

export const amplifyConfig = {
  Auth: {
    identityPoolId: awsmobile.aws_cognito_identity_pool_id,
    region: awsmobile.aws_project_region,
    userPoolId: awsmobile.aws_user_pools_id,
    userPoolWebClientId: awsmobile.aws_user_pools_web_client_id,
  },
  API: {
    endpoints: [
      {
        name: apiName,
        endpoint: import.meta.env.V_API_ENDPOINT || 'localhost:3000',
        custom_header: async () => {
          return { Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}` };
        },
      },
      {
        name: publicApiName,
        endpoint: import.meta.env.V_API_ENDPOINT || 'localhost:3000',
      },
    ],
  },
};
