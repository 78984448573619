import { createTheme } from '@mui/material/styles';
import { createMakeAndWithStyles } from 'tss-react';
import { useTheme } from '@mui/material';
import createCache from '@emotion/cache';

export let theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6A7CE1',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#212633',
      paper: '#353a4a',
    },
    text: {
      primary: '#ffffff',
      secondary: '#ffffff',
      disabled: '#8D8D8D',
    },
    action: {
      disabled: 'rgba(255,255,255,0.7)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      monitor: 1920,
    },
  },
});

theme = createTheme(theme, {
  typography: {
    h4: {
      color: theme.palette.text.primary,
      fontSize: '32px',
      fontWeight: 700,
    },
    h6: {
      color: theme.palette.text.primary,
      fontSize: '16px',
      fontWeight: 600,
    },
    body1: {
      fontSize: '14px',
      color: theme.palette.text.primary,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: '44px',
          textTransform: 'capitalize',
        },
        outlined: {
          borderRadius: '6px',
          borderColor: '#8D8D8D',
          '&:hover': {
            borderColor: '#8D8D8D',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: theme.palette.text.primary,
          },
        },
        asterisk: {
          color: theme.palette.error.main,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#757575',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#7575758c',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          marginRight: '10px',
        },
      },
    },
  },
});

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

export const { makeStyles } = createMakeAndWithStyles({ cache: muiCache, useTheme: useTheme });
