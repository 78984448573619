import ReactDOM from 'react-dom';
import { FC, useEffect } from 'react';
import { IconButton, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Box from '@mui/material/Box';

interface INotepadBtnProps {
  onClick: () => void;
  nawDrawerOpen: boolean;
}

export const NotepadBtn: FC<INotepadBtnProps> = (props) => {
  const { onClick, nawDrawerOpen } = props;

  useEffect(() => {
    // Create a new div element to hold the portal content
    const portalContainer = document.createElement('div');

    // Append the portal container to the body element
    document.body.appendChild(portalContainer);

    // Render the JSX code into the portal container
    ReactDOM.render(
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          bottom: 20,
          left: 16,
          cursor: 'pointer',
          zIndex: 1200,
        }}
        onClick={onClick}>
        <IconButton sx={{ color: '#fff', marginRight: '16px' }}>
          <DescriptionIcon sx={{}} />
        </IconButton>
        <Typography sx={{ visibility: nawDrawerOpen ? 'visible' : 'hidden', color: '#fff' }}>
          Notepad
        </Typography>
      </Box>,
      portalContainer
    );

    // Cleanup function to remove the portal container when the component is unmounted
    return () => {
      ReactDOM.unmountComponentAtNode(portalContainer);
      document.body.removeChild(portalContainer);
    };
  }, [nawDrawerOpen]);

  return null;
};
