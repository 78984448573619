import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, CircularProgress, Drawer, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'lodash';
import SaveIcon from '@mui/icons-material/Save';

import { useAppDispatch } from '~/store';
import { addNote, editNote, getNotes, notesSelector } from '~/store/note';
import { progressSize } from '~/components/molecules/ButtonWithLoading';

import { useStyles } from './styles';

interface INotepadDrawerProps {
  open: boolean;
  handleClose: () => void;
}

export const NotepadDrawer: FC<INotepadDrawerProps> = (props) => {
  const { open, handleClose } = props;

  const { classes } = useStyles();
  const dispatch = useAppDispatch();

  const { data } = useSelector(notesSelector);

  const [note, setNote] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) dispatch(getNotes());
  }, [open]);

  useEffect(() => {
    if (data) setNote(data[0]?.content || '');
  }, [data]);

  const saveNote = useCallback(
    debounce(
      (note: string) => {
        if (note.length > 5000) return;

        setLoading(true);
        const start = new Date().getTime();
        const endLoading = () => {
          const end = new Date().getTime();
          const time = end - start;
          if (time < 350) {
            setTimeout(() => setLoading(false), 350 - time);
          } else {
            setLoading(false);
          }
        };
        if (data?.length) {
          dispatch(
            editNote({
              id: data[0].id,
              content: note,
            })
          ).finally(endLoading);
        } else {
          dispatch(
            addNote({
              content: note,
            })
          ).finally(endLoading);
        }
      },
      350,
      { leading: false, trailing: true }
    ),
    []
  );

  return (
    <>
      <Drawer className={classes.drawer} anchor={'left'} open={open} onClose={handleClose}>
        <IconButton sx={{ position: 'absolute', right: 20, top: 25 }} onClick={handleClose}>
          <CloseIcon sx={{ color: '#BDBDBD' }} />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography className={classes.title}>Notes</Typography>
          {loading ? <CircularProgress size={progressSize} sx={{ color: '#fff' }} /> : <SaveIcon />}
        </Box>
        {note && note.length > 5000 && (
          <Typography className={classes.errorMessage}>Note is too long</Typography>
        )}
        <Box className={classes.formBox}>
          <textarea
            className={classes.textArea}
            name="note"
            onChange={(e) => {
              setNote(e.target.value);
              saveNote(e.target.value);
            }}
            value={note || ''}
            placeholder="Enter your note here"
          />
        </Box>
      </Drawer>
    </>
  );
};
