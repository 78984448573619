import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  settingsRoot: {
    flex: 1,
    width: '100%',
    height: '100%',
    padding: '30px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  rootWrapper: {
    maxWidth: '640px',
    position: 'relative',
  },
  title: {
    fontSize: '32px',
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginTop: '25px',
    },
  },
  updateBtnBox: {
    marginBottom: '50px',
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '25px',
    },
  },
  fieldsBox: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '12px 12px 0px 0px',
    overflow: 'hidden',
    padding: '24px',
    position: 'relative',
  },
  formHeader: {
    width: '100%',
    height: '80px',
    position: 'absolute',
    top: '0',
    left: '0',
  },
  input: {
    maxWidth: '510px',
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      marginBottom: '25px',
    },
  },
  saveBtn: {
    width: '130px',
    position: 'absolute',
    top: '0',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
      marginTop: '16px',
      width: '100%',
    },
  },
  avatarBox: {
    position: 'relative',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
