import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '100px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '70px',
    },
  },
  contentBox: {
    width: '510px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0 24px',
    },
  },
  logo: {
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      height: 'auto',
      marginBottom: '20px',
    },
  },
  emailInput: {
    margin: '20px 0',
  },
}));
