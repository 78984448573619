import { FC, useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Theme,
  useMediaQuery,
} from '@mui/material';

import { deleteFromMailingList, TMailingListItem } from '~/store/metric';
import { ButtonWithLoading } from '~/components/molecules/ButtonWithLoading';
import { useAppDispatch } from '~/store';

import { useStyles } from './styles';

interface IRecurringEmailBtnProps {
  mailingListItem: TMailingListItem;
}

export const RecurringEmailBtn: FC<IRecurringEmailBtnProps> = (props) => {
  const { mailingListItem } = props;

  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    dispatch(deleteFromMailingList(mailingListItem.id)).then((r) => {
      if (r.meta.requestStatus === 'fulfilled') {
        setLoading(false);
        setOpen(false);
      }
    });
  };

  return (
    <>
      <Button
        className={classes.btn}
        startIcon={<MailOutlineIcon />}
        variant="outlined"
        onClick={() => setOpen(true)}>
        Recurring email
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{ '& .MuiDialog-paper': { borderRadius: '12px' } }}>
        <DialogTitle>Delete recurring email</DialogTitle>
        <DialogContent sx={{ width: isMobile ? '70vw' : '350px' }}>
          <DialogContentText sx={{ marginBottom: '25px' }}>
            Are you sure you want to delete recurring email?
          </DialogContentText>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: '24px',
            }}>
            <Button sx={{ marginRight: '4px' }} onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <ButtonWithLoading color="error" loading={loading} onClick={handleDelete} variant="contained">
              Delete
            </ButtonWithLoading>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
