import { FC, useEffect, useRef, useState, useTransition } from 'react';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './styles';

interface ISearchProps {
  searchQuery?: string;
  onSearchChange: (value: string) => void;
  updateTable: (searchQuery?: string) => void;
}

export const Search: FC<ISearchProps> = (props) => {
  const { onSearchChange, updateTable, searchQuery } = props;

  const { classes } = useStyles();

  const inputRef = useRef<HTMLInputElement>(null);

  const [isPending, startTransition] = useTransition();

  const [showInput, setShowInput] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    if (showInput) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      };
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [showInput, searchValue]);

  // sets correct state when page is opened via share link
  useEffect(() => {
    setSearchValue(searchQuery || '');
    if (searchQuery) {
      setShowInput(true);
    }
  }, [searchQuery]);

  const handleSearch = () => {
    onSearchChange(searchValue);
    updateTable(searchValue);
  };

  const handleOpen = () => {
    setShowInput(true);
    inputRef.current?.focus();
  };

  const handleClose = () => {
    startTransition(() => {
      setShowInput(false);
      setSearchValue('');
    });

    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get('search');

    if (searchQuery) {
      onSearchChange('');
      updateTable('');
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', minWidth: '40px' }}>
      <TextField
        inputRef={inputRef}
        autoComplete={'off'}
        className={showInput ? classes.showInput : ''}
        sx={{
          width: 0,
          opacity: 0,
          transition: 'all 0.5s ease-in-out',
          '& .MuiInputBase-input': {
            padding: '6px',
          },
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
          '& .MuiInputAdornment-root': {
            marginRight: 0,
          },
        }}
        placeholder={'Search'}
        onChange={(e) => setSearchValue(e.target.value)}
        value={searchValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton sx={{ color: 'text.primary' }} onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton sx={{ color: 'text.primary' }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {!showInput && (
        <IconButton sx={{ color: 'text.primary' }} onClick={handleOpen}>
          <SearchIcon />
        </IconButton>
      )}
    </Box>
  );
};
