export const sliceName = 'dashboard';

export const getTopPhoneScreensActionType = `${sliceName}/getTopPhoneScreens`;
export const getTopPlacementsActionType = `${sliceName}/getTopPlacements`;
export const getTopNewClientsActionType = `${sliceName}/getTopNewClients`;
export const getTopActivityActionType = `${sliceName}/getTopActivity`;
export const getTopOffersActionType = `${sliceName}/getTopOffers`;
export const getTopCandidatesOutActionType = `${sliceName}/getTopCandidatesOut`;
export const getRevenueActionType = `${sliceName}/getRevenue`;
export const getPipelineValueActionType = `${sliceName}/getPipelineValue`;
export const getWhiteboardHistoryActionType = `${sliceName}/getWhiteboardHistory`;
