import { FC } from 'react';
import { Avatar, AvatarProps, Box } from '@mui/material';

export interface IAvatarWithCircleProps extends AvatarProps {
  size: number; // number of pixels
  color?: string;
}

export const AvatarWithCircle: FC<IAvatarWithCircleProps> = (props) => {
  const { color, size, sx, ...rest } = props;
  return (
    <Box
      sx={{
        position: 'relative',
        zIndex: 1,
        backgroundColor: color || 'rgba(0,0,0,0)',
        width: `${size + 6}px`,
        height: `${size + 6}px`,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Avatar sx={{ ...sx, width: size + 'px', height: size + 'px', backgroundColor: '#fff' }} {...rest} />
    </Box>
  );
};
