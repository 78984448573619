import { IMetricQueries } from '~/store/metric';
import { listOfQueryParams } from '~/constants';
import { TPeriod } from '~/store/dashboard';
import { IWhiteboardQueries } from '~/store/whiteboard';
import { TRule } from '~/components/organisms/TableToolbar';
import { ruleColors, ruleOperators } from '~/constants/table';

export const addQueryParamsToURL = (queryParams: IMetricQueries | IWhiteboardQueries) => {
  const newQueryParams = { ...queryParams };
  if (queryParams.filter === '') {
    delete newQueryParams.filter;
  }

  const queryString = new URLSearchParams(newQueryParams as Record<string, string>).toString();

  let newUrl = '';
  if (queryString) {
    newUrl = `${window.location.pathname}?${queryString}`;
  } else {
    newUrl = `${window.location.pathname}`;
  }

  window.history.replaceState({}, '', newUrl);
};

export const getQueryParamsFromURL = (): IMetricQueries => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryParams: IMetricQueries = {};

  for (const [key, value] of urlParams) {
    if (listOfQueryParams.some((q) => q === key)) {
      queryParams[key] = convertStringToNull(value);
    }
  }

  return queryParams;
};

const convertStringToNull = (str: string) => {
  if (str === 'null' || str === 'undefined') {
    return null;
  } else {
    return str;
  }
};

export const getQueryStrForWidgetRedirect = (field: string, period: TPeriod, filter?: string) => {
  const queryParams: IMetricQueries = {
    sort: field,
    order: 'DESC',
    from: period.from,
    to: period.to,
    filter: filter || '',
  };

  return new URLSearchParams(queryParams as Record<string, string>).toString();
};

export const rulesToQueryParam = (rules: TRule[]) => {
  return rules.reduce((acc, rule) => {
    return acc + `${rule.field},${rule.operator?.value},${rule.value},${rule.color?.label};`;
  }, '');
};

export const queryParamToRules = (rulesStr: string | number): TRule[] => {
  return (rulesStr + '').split(';').reduce((acc, rule) => {
    const [field, operator, value, color] = rule.split(',');
    if (field && operator && value && color) {
      return [
        ...acc,
        {
          field,
          operator: ruleOperators.find((o) => o.value === operator),
          value,
          color: ruleColors.find((c) => c.label === color),
        },
      ];
    } else {
      return acc;
    }
  }, [] as TRule[]);
};
