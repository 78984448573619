import { createAsyncThunk } from '@reduxjs/toolkit';
import { API } from 'aws-amplify';

import { apiName, Endpoints } from '~/constants';

import { addNoteActionType, editNoteActionType, getNotesActionType } from './actionTypes';

export const getNotes = createAsyncThunk(getNotesActionType, async () => {
  return API.get(apiName, Endpoints.notes, {});
});

export const addNote = createAsyncThunk(addNoteActionType, async (note: { content: string }) => {
  return await API.post(apiName, Endpoints.notes, { body: note });
});

export const editNote = createAsyncThunk(
  editNoteActionType,
  async (note: { id: string; content: string }) => {
    return await API.patch(apiName, Endpoints.notes + `/${note.id}`, { body: note });
  }
);
