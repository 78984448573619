import { useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { AppBar, Button, Drawer, IconButton, Menu, Toolbar, Typography, useMediaQuery } from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import BarChartIcon from '@mui/icons-material/BarChart';
import InsightsIcon from '@mui/icons-material/Insights';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';

import { Routes } from '~/constants/routes';
import { ReactComponent as MenuIcon } from '~/assets/menu.svg';
import { ReactComponent as LogoIcon } from '~/assets/Logo.svg';
import { ReactComponent as DashboardLogo } from '~/assets/dashboard-outline.svg';
import { ReactComponent as WhiteboardLogo } from '~/assets/whiteboard.svg';
import { ReactComponent as MetricsLogo } from '~/assets/metrics.svg';
import { ReactComponent as SettingsLogo } from '~/assets/settings-gear.svg';
import { ReactComponent as UserMenuIcon } from '~/assets/user-menu.svg';
import { theme } from '~/theme';
import { clearUserData, userDataSelector } from '~/store/common';
import { useAppDispatch } from '~/store';
import { AvatarWithCircle } from '~/components/molecules/AvatarWithCircle';
import { NotepadBtn } from '~/components/molecules/NotepadBtn';
import { NotepadDrawer } from '~/components/organisms/NotepadDrawer';

import { useStyles } from './styles';

export const RootLayout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isRoot = useMatch(Routes.root);
  const isDashboard = useMatch(Routes.dashboard);
  const isWhiteboard = useMatch(Routes.whiteboard);
  const isWhiteboardMetrics = useMatch(Routes.whiteboardMetrics);
  const isMetrics = useMatch(Routes.metrics);
  const isSettings = useMatch(Routes.settings);
  const isCreateUser = useMatch(Routes.userManagement);
  const isWhiteboardInsights = useMatch(Routes.whiteboardInsights);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data } = useSelector(userDataSelector);

  const anchorEl = useRef(null);

  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openNotepad, setOpenNotepad] = useState(false);

  const { classes, cx } = useStyles({ open, isMobile });

  useEffect(() => {
    if (!data && isRoot) {
      navigate(Routes.comingSoon);
      return;
    }

    if (isRoot && data) {
      navigate(Routes.dashboard);
      return;
    }

    if (!data) {
      navigate(Routes.signIn);
      return;
    }
  }, []);

  const handleLogout = () => {
    Auth.signOut().then(() => {
      navigate(Routes.signIn);
      dispatch(clearUserData());
    });
  };

  const isAdminRole = ['admin', 'god'].includes(data?.role || '');
  const isGodRole = data?.role === 'god';

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpen(!open)}>
            <MenuIcon />
          </IconButton>
          <Box className={classes.headerActionBox}>
            <AvatarWithCircle src={data?.avatarImg} size={24} color={data?.color} />
            <Typography sx={{ textTransform: 'capitalize' }}>
              {data?.name + ' ' + data?.secondName}
            </Typography>
            <IconButton
              ref={anchorEl}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => setOpenMenu(!openMenu)}>
              <UserMenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl.current}
              open={openMenu}
              onClose={() => setOpenMenu(!openMenu)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}>
              <Button onClick={handleLogout}>Log out</Button>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        onClose={() => setOpen(false)}
        className={classes.drawer}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={open}>
        <Link className={classes.logoBox} to={Routes.dashboard}>
          <LogoIcon className={classes.logoIcon} />
          <Typography sx={{ visibility: open ? 'visible' : 'hidden', fontWeight: 500 }}>
            [IN]-BOARD
          </Typography>
        </Link>
        <Box className={classes.navMenu}>
          <Link
            className={cx(classes.navItemBox, isDashboard && classes.navItemBoxActiveWithOutFill)}
            to={Routes.dashboard}>
            <DashboardLogo className={classes.navItemIcon} />
            <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Dashboard</Typography>
          </Link>
          <Link
            className={cx(classes.navItemBox, isWhiteboard && classes.navItemBoxActive)}
            to={Routes.whiteboard}>
            <WhiteboardLogo className={classes.navItemIcon} />
            <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Whiteboard</Typography>
          </Link>
          {isAdminRole && (
            <Link
              className={cx(classes.navItemBox, isWhiteboardMetrics && classes.navItemBoxActive)}
              to={Routes.whiteboardMetrics}>
              <BarChartIcon className={classes.navItemIcon} />
              <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Whiteboard Metrics</Typography>
            </Link>
          )}
          <Link
            className={cx(classes.navItemBox, isWhiteboardInsights && classes.navItemBoxActive)}
            to={Routes.whiteboardInsights}>
            <InsightsIcon className={classes.navItemIcon} />
            <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Whiteboard Insights</Typography>
          </Link>
          {isAdminRole && (
            <Link
              className={cx(classes.navItemBox, isMetrics && classes.navItemBoxActive)}
              to={Routes.metrics}>
              <MetricsLogo className={classes.navItemIcon} />
              <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Metrics</Typography>
            </Link>
          )}
          <Link
            className={cx(classes.navItemBox, isSettings && classes.navItemBoxActive)}
            to={Routes.settings}>
            <SettingsLogo className={classes.navItemIcon} />
            <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Settings</Typography>
          </Link>
          {isAdminRole ? (
            <Link
              className={cx(classes.navItemBox, isCreateUser && classes.navItemBoxActive)}
              to={Routes.userManagement}>
              <PersonAddAlt1Icon className={classes.navItemIcon} />
              <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>User Management</Typography>
            </Link>
          ) : null}
          {isGodRole ? (
            <Link className={classes.navItemBox} to={Routes.serviceMonitoring}>
              <MonitorHeartIcon className={classes.navItemIcon} />
              <Typography sx={{ visibility: open ? 'visible' : 'hidden' }}>Service Monitoring</Typography>
            </Link>
          ) : null}
        </Box>
        <NotepadBtn
          onClick={() => {
            setOpenNotepad(true);
          }}
          nawDrawerOpen={open}
        />
      </Drawer>
      <Box className={classes.main}>
        <NotepadDrawer open={openNotepad} handleClose={() => setOpenNotepad(false)} />
        <Outlet />
      </Box>
    </Box>
  );
};
