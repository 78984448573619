export const sliceName = 'whiteboard';

export const getWhiteboardActionType = `${sliceName}/getWhiteboard`;

export const getWhiteboardFiltersActionType = `${sliceName}/getWhiteboardFilters`;

export const addWhiteboardRecordActionType = `${sliceName}/addWhiteboardRecord`;

export const updateWhiteboardRecordActionType = `${sliceName}/updateWhiteboardRecord`;

export const deleteWhiteboardRecordActionType = `${sliceName}/deleteWhiteboardRecord`;

export const exportWhiteboardActionType = `${sliceName}/exportWhiteboard`;
