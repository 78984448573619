import { createSelector, createSlice } from '@reduxjs/toolkit';

import { RootState } from '~/store';
import { addToMailingList, deleteFromMailingList } from '~/store/metric';

import { sliceName } from './actionTypes';
import {
  getUserInfo,
  getUserViews,
  login,
  newPasswordRequired,
  updateUserAttributes,
  verifyEmail,
} from './thunks';
import { IUserData, IView } from './types';
import { initThunk } from './initThunk';

export interface CommonState {
  userData: {
    data: IUserData | null;
    loading: boolean;
  };
  views: {
    data: IView[] | null;
    loading: boolean;
  };
  initLoading: boolean;
}

const initialState: CommonState = {
  userData: { data: null, loading: false },
  views: { data: null, loading: false },
  initLoading: true,
};

export const commonSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearUserData: (state) => {
      state.userData.data = null;
    },
    clearViews: (state) => {
      state.views.data = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.userData.loading = true;
    });
    builder.addCase(login.rejected, (state) => {
      state.userData.loading = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if (action.payload?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        state.userData.loading = false;
      }
    });
    builder.addCase(newPasswordRequired.pending, (state) => {
      state.userData.loading = true;
    });
    builder.addCase(newPasswordRequired.rejected, (state) => {
      state.userData.loading = false;
    });
    builder.addCase(getUserInfo.pending, (state) => {
      state.userData.loading = true;
    });
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userData.data = action.payload;
      state.userData.loading = false;
    });
    builder.addCase(getUserInfo.rejected, (state) => {
      state.userData.loading = false;
    });
    builder.addCase(initThunk.fulfilled, (state, action) => {
      state.initLoading = false;
      state.userData.data = action.payload;
    });
    builder.addCase(initThunk.rejected, (state) => {
      state.initLoading = false;
    });
    builder.addCase(updateUserAttributes.fulfilled, (state, action) => {
      state.userData.data = {
        ...state.userData.data,
        ...action.payload,
      };
    });
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      if (state.userData.data) state.userData.data.email = action.payload;
    });
    builder.addCase(getUserViews.pending, (state) => {
      state.views.loading = true;
    });
    builder.addCase(getUserViews.fulfilled, (state, action) => {
      state.views.data = action.payload;
      state.views.loading = false;
    });
    builder.addCase(getUserViews.rejected, (state) => {
      state.views.loading = false;
    });
    builder.addCase(deleteFromMailingList.fulfilled, (state, action) => {
      if (state.userData.data) {
        state.userData.data.mailingLists = state.userData.data.mailingLists?.filter(
          (list) => list.id !== action.payload
        );
      }
    });
    builder.addCase(addToMailingList.fulfilled, (state, action) => {
      if (state.userData.data) {
        state.userData.data.mailingLists = [...state.userData.data.mailingLists!, action.payload];
      }
    });
  },
});

export const commonSelector = (state: RootState) => state.common;
export const userDataSelector = (state: RootState) => state.common.userData;
export const initLoadingSelector = (state: RootState) => state.common.initLoading;
export const metricsViewsSelector = createSelector(commonSelector, (state: CommonState) => ({
  data: state.views.data?.filter((view) => view.kind === 'metric') || null,
  loading: state.views?.loading,
}));

export const whiteboardViewsSelector = createSelector(commonSelector, (state: CommonState) => ({
  data: state.views.data?.filter((view) => view.kind === 'whiteboard') || null,
  loading: state.views?.loading,
}));
export const whiteboardMetricsViewsSelector = createSelector(commonSelector, (state: CommonState) => ({
  data: state.views.data?.filter((view) => view.kind === 'whiteboard-metric') || null,
  loading: state.views?.loading,
}));
export const userManagementViewsSelector = createSelector(commonSelector, (state: CommonState) => ({
  data: state.views.data?.filter((view) => view.kind === 'user-management') || null,
  loading: state.views?.loading,
}));
export const whiteboardInsightsViewsSelector = createSelector(commonSelector, (state: CommonState) => ({
  data: state.views.data?.filter((view) => view.kind === 'whiteboard-insights') || null,
}));

export const { clearUserData, clearViews } = commonSlice.actions;

export const commonReducer = commonSlice.reducer;
