import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { Box, Button, Menu, Typography, useMediaQuery } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateCalendar, DatePicker } from '@mui/x-date-pickers';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

import { DateRange } from '~/components/organisms/TableToolbar';
import { ITimePeriod, TPeriodId } from '~/pages/Dashboard/types';
import { theme } from '~/theme';

import { useStyles } from './styles';

interface DatePickerMenuProps {
  dateRange: DateRange;
  currentPeriodId?: TPeriodId;
  options: ITimePeriod[];
  onFromChange: (date: dayjs.Dayjs | null) => void;
  onToChange: (date: dayjs.Dayjs | null) => void;
  onPeriodChange: (value: TPeriodId) => void;
  sx?: SxProps<Theme>;
}

export const DatePickerMenu: FC<DatePickerMenuProps> = (props) => {
  const { dateRange, currentPeriodId, options, onFromChange, onToChange, onPeriodChange, sx } = props;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { classes } = useStyles();

  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseFirstLevelMenu = () => {
    setMenuAnchor(null);
  };

  const period = options.find((option) => option.id === currentPeriodId);

  const title =
    period?.label === 'All Time' || isMobile
      ? period?.label
      : `${period?.label}: ${dateRange.from ? dayjs(dateRange.from).format('MM/DD/YYYY') : 'no date'} - ${
          dateRange.to ? dayjs(dateRange.to).format('MM/DD/YYYY') : 'no date'
        }`;

  return (
    <>
      <Button
        sx={sx}
        className={classes.mainBtn}
        startIcon={<CalendarTodayIcon />}
        variant="outlined"
        onClick={handleOpenMenu}>
        {title}
      </Button>
      <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={handleCloseFirstLevelMenu}>
        <Box className={classes.menuBox}>
          <Box className={classes.periodsBox}>
            {options.map((o) => (
              <Button
                key={o.id}
                sx={{
                  color: 'text.primary',
                  justifyContent: 'flex-start',
                  backgroundColor: o.id === currentPeriodId ? 'background.default' : 'transparent',
                }}
                onClick={() => onPeriodChange(o.id)}>
                {o.label}
              </Button>
            ))}
          </Box>
          <Box className={classes.datePickersBox}>
            {isMobile ? (
              <>
                <Typography sx={{ fontWeight: 500, paddingTop: '12px' }}>From: </Typography>
                <DatePicker onChange={onFromChange} value={dateRange.from ? dayjs(dateRange.from) : null} />
              </>
            ) : (
              <DateCalendar
                className={classes.datePicker}
                onChange={onFromChange}
                value={dateRange.from ? dayjs(dateRange.from) : null}
              />
            )}
            {isMobile ? (
              <>
                <Typography sx={{ fontWeight: 500 }}>To: </Typography>
                <DatePicker onChange={onToChange} value={dateRange.to ? dayjs(dateRange.to) : null} />
              </>
            ) : (
              <DateCalendar
                className={classes.datePicker}
                onChange={onToChange}
                value={dateRange.to ? dayjs(dateRange.to) : null}
              />
            )}
          </Box>
        </Box>
      </Menu>
    </>
  );
};
