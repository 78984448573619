import { Theme } from '@mui/material';

import { makeStyles } from '~/theme';

export const useStyles = makeStyles()((theme: Theme) => ({
  menuBox: {
    position: 'relative',
    padding: '24px',
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '24px',
    '& > p': {
      marginTop: '12px',
    },
  },
}));
